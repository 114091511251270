@charset "UTF-8";
a.anchor {
  display: block;
  position: relative;
  visibility: hidden; }
  @media only screen and (min-width: 992px) {
    a.anchor {
      top: -120px; } }

/* Cognireset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  /* COG: this is known to cause problems in CQ4 and SharePoint */ }

/* remember to define focus styles! */
:focus {
  outline: 0; }

body {
  line-height: 1;
  color: black;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  position: relative;
  overflow: auto; }

ol, ul {
  list-style: none; }

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: ""; }

blockquote, q {
  quotes: "" ""; }

/* COG: make sup and sup working again */
sub {
  vertical-align: sub;
  font-size: 0.9em; }

sup {
  vertical-align: super;
  font-size: 0.9em; }

.scEnabledChrome {
  opacity: 1 !important; }

/* stylelint-disable selector-list-comma-newline-after */
::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

@keyframes in {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

@keyframes out {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

body {
  font-size: 12px;
  font-family: "Muli", sans-serif;
  font-weight: normal;
  line-height: 1.4;
  color: #24405b; }
  @media only screen and (min-width: 768px) {
    body {
      font-size: 15px; } }

strong {
  font-weight: bold; }

h1,
.like-h1 {
  font-weight: bold;
  font-size: 20px;
  line-height: 1.33;
  letter-spacing: -0.6px; }
  @media only screen and (min-width: 768px) {
    h1,
    .like-h1 {
      font-size: 30px; } }

.like-h1-light {
  font-weight: bold;
  font-size: 20px;
  line-height: 1.33;
  letter-spacing: -0.6px;
  font-weight: 300;
  letter-spacing: -1px; }
  @media only screen and (min-width: 768px) {
    .like-h1-light {
      font-size: 30px; } }

.like-h1-xl {
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: -0.6px;
  font-size: 30px; }
  @media only screen and (min-width: 768px) {
    .like-h1-xl {
      font-size: 60px; } }

.like-h1-xl-light {
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: -0.6px;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: -1px; }
  @media only screen and (min-width: 768px) {
    .like-h1-xl-light {
      font-size: 60px; } }

h2,
.like-h2 {
  font-weight: bold;
  font-size: 15px;
  line-height: 1.35; }
  @media only screen and (min-width: 768px) {
    h2,
    .like-h2 {
      font-size: 17px; } }

.like-h2-light {
  font-weight: bold;
  font-size: 15px;
  line-height: 1.35;
  font-weight: 300;
  letter-spacing: -0.75px; }
  @media only screen and (min-width: 768px) {
    .like-h2-light {
      font-size: 17px; } }

h3,
.like-h3 {
  font-weight: bold;
  font-size: 15px;
  line-height: 1.35; }
  @media only screen and (min-width: 768px) {
    h3,
    .like-h3 {
      font-size: 17px; } }

h4,
.like-h4 {
  font-weight: bold;
  font-size: 15px;
  line-height: 1.35; }
  @media only screen and (min-width: 768px) {
    h4,
    .like-h4 {
      font-size: 17px; } }

h5,
.like-h5 {
  font-weight: bold;
  font-size: 22px;
  line-height: 1.27; }
  @media only screen and (min-width: 768px) {
    h5,
    .like-h5 {
      font-size: 22px; } }

a,
.like.a {
  color: #3344f6;
  font-weight: bold; }

.like-button, .content .component.image-spot.profile.button .image-spot-element-link a {
  animation: out 0.7s -0.1s ease-in-out forwards;
  background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
  background-size: 420% 100%;
  background-position: right bottom;
  background-color: #3344f6;
  color: #ffffff;
  transition: all 0.4s ease-in-out;
  font-size: 12px;
  color: #ffffff;
  background-color: #3344f6;
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: bold;
  border-radius: 19.5px;
  padding: 11px 26px 12px; }
  .like-button:hover, .content .component.image-spot.profile.button .image-spot-element-link a:hover {
    animation: in 0.7s -0.1s ease-in-out forwards;
    background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
    background-size: 420% 100%;
    background-position: right bottom;
    background-color: #3344f6;
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
    color: #3344f6;
    transition: all 0.8s ease-in-out; }
    .like-button:hover a, .content .component.image-spot.profile.button .image-spot-element-link a:hover a {
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
  @media only screen and (min-width: 768px) {
    .like-button, .content .component.image-spot.profile.button .image-spot-element-link a {
      font-size: 15px; } }
  .like-button a, .content .component.image-spot.profile.button .image-spot-element-link a a {
    color: #ffffff;
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .like-button, .content .component.image-spot.profile.button .image-spot-element-link a {
      border-radius: 25px;
      padding: 14px 35px 15px; } }

.like-button-large {
  animation: out 0.7s -0.1s ease-in-out forwards;
  background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
  background-size: 420% 100%;
  background-position: right bottom;
  background-color: #3344f6;
  color: #ffffff;
  transition: all 0.4s ease-in-out;
  font-size: 15px;
  color: #ffffff;
  background-color: #3344f6;
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: bold;
  border-radius: 25px;
  padding: 11px 26px 12px; }
  .like-button-large:hover {
    animation: in 0.7s -0.1s ease-in-out forwards;
    background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
    background-size: 420% 100%;
    background-position: right bottom;
    background-color: #3344f6;
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
    color: #3344f6;
    transition: all 0.8s ease-in-out; }
    .like-button-large:hover a {
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
  @media only screen and (min-width: 768px) {
    .like-button-large {
      font-size: 17px; } }
  .like-button-large a {
    color: #ffffff;
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .like-button-large {
      border-radius: 23px;
      padding: 12.5px 35px 14.5px; } }

.like-button-small, .content .component.image-spot.element-as-background .image-spot-element-link a {
  animation: out 0.7s -0.1s ease-in-out forwards;
  background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
  background-size: 420% 100%;
  background-position: right bottom;
  background-color: #3344f6;
  color: #ffffff;
  transition: all 0.4s ease-in-out;
  font-size: 11px;
  color: #ffffff;
  background-color: #3344f6;
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: bold;
  border-radius: 16.5px;
  padding: 7px 21px 8px; }
  .like-button-small:hover, .content .component.image-spot.element-as-background .image-spot-element-link a:hover {
    animation: in 0.7s -0.1s ease-in-out forwards;
    background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
    background-size: 420% 100%;
    background-position: right bottom;
    background-color: #3344f6;
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
    color: #3344f6;
    transition: all 0.8s ease-in-out; }
    .like-button-small:hover a, .content .component.image-spot.element-as-background .image-spot-element-link a:hover a {
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
  @media only screen and (min-width: 768px) {
    .like-button-small, .content .component.image-spot.element-as-background .image-spot-element-link a {
      font-size: 13px; } }
  .like-button-small a, .content .component.image-spot.element-as-background .image-spot-element-link a a {
    color: #ffffff;
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .like-button-small, .content .component.image-spot.element-as-background .image-spot-element-link a {
      border-radius: 18.5px;
      padding: 9px 25px 10px; } }

.like-button-minimal {
  font-size: 12px;
  color: #ffffff;
  background-color: #3344f6;
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: bold; }
  @media only screen and (min-width: 768px) {
    .like-button-minimal {
      font-size: 15px; } }
  .like-button-minimal a {
    color: #ffffff;
    text-decoration: none; }

.white-buttons .like-button, .white-buttons .content .component.image-spot.profile.button .image-spot-element-link a, .content .component.image-spot.profile.button .image-spot-element-link .white-buttons a {
  animation: out 0.7s -0.1s ease-in-out forwards;
  background: radial-gradient(50% 300% at right, #3344f6 50%, transparent 50%), radial-gradient(circle at right, #ffffff 100%, transparent 100%);
  background-size: 420% 100%;
  background-position: right bottom;
  background-color: #ffffff;
  color: #3344f6;
  transition: all 0.4s ease-in-out;
  font-size: 12px;
  color: #ffffff;
  background-color: #3344f6;
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: bold;
  border-radius: 19.5px;
  padding: 11px 26px 12px;
  color: #3344f6;
  background-color: #ffffff; }
  .white-buttons .like-button:hover, .white-buttons .content .component.image-spot.profile.button .image-spot-element-link a:hover, .content .component.image-spot.profile.button .image-spot-element-link .white-buttons a:hover {
    animation: in 0.7s -0.1s ease-in-out forwards;
    background: radial-gradient(50% 300% at left, #3344f6 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(51, 68, 246, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #ffffff 100%, transparent 100%);
    background-size: 420% 100%;
    background-position: right bottom;
    background-color: #ffffff;
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
    color: #ffffff;
    transition: all 0.8s ease-in-out; }
    .white-buttons .like-button:hover a, .white-buttons .content .component.image-spot.profile.button .image-spot-element-link a:hover a, .content .component.image-spot.profile.button .image-spot-element-link .white-buttons a:hover a {
      color: #ffffff;
      transition: all 0.8s ease-in-out; }
  @media only screen and (min-width: 768px) {
    .white-buttons .like-button, .white-buttons .content .component.image-spot.profile.button .image-spot-element-link a, .content .component.image-spot.profile.button .image-spot-element-link .white-buttons a {
      font-size: 15px; } }
  .white-buttons .like-button a, .white-buttons .content .component.image-spot.profile.button .image-spot-element-link a a, .content .component.image-spot.profile.button .image-spot-element-link .white-buttons a a {
    color: #ffffff;
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .white-buttons .like-button, .white-buttons .content .component.image-spot.profile.button .image-spot-element-link a, .content .component.image-spot.profile.button .image-spot-element-link .white-buttons a {
      border-radius: 25px;
      padding: 14px 35px 15px; } }
  .white-buttons .like-button a, .white-buttons .content .component.image-spot.profile.button .image-spot-element-link a a, .content .component.image-spot.profile.button .image-spot-element-link .white-buttons a a {
    color: #3344f6; }

.white-buttons .like-button-large {
  animation: out 0.7s -0.1s ease-in-out forwards;
  background: radial-gradient(50% 300% at right, #3344f6 50%, transparent 50%), radial-gradient(circle at right, #ffffff 100%, transparent 100%);
  background-size: 420% 100%;
  background-position: right bottom;
  background-color: #ffffff;
  color: #3344f6;
  transition: all 0.4s ease-in-out;
  font-size: 12px;
  color: #ffffff;
  background-color: #3344f6;
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: bold;
  border-radius: 25px;
  padding: 11px 26px 12px;
  color: #3344f6;
  background-color: #ffffff; }
  .white-buttons .like-button-large:hover {
    animation: in 0.7s -0.1s ease-in-out forwards;
    background: radial-gradient(50% 300% at left, #3344f6 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(51, 68, 246, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #ffffff 100%, transparent 100%);
    background-size: 420% 100%;
    background-position: right bottom;
    background-color: #ffffff;
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
    color: #ffffff;
    transition: all 0.8s ease-in-out; }
    .white-buttons .like-button-large:hover a {
      color: #ffffff;
      transition: all 0.8s ease-in-out; }
  @media only screen and (min-width: 768px) {
    .white-buttons .like-button-large {
      font-size: 15px; } }
  .white-buttons .like-button-large a {
    color: #ffffff;
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .white-buttons .like-button-large {
      border-radius: 23px;
      padding: 12.5px 35px 14.5px; } }
  .white-buttons .like-button-large a {
    color: #3344f6; }

.white-buttons .like-button-small, .white-buttons .content .component.image-spot.element-as-background .image-spot-element-link a, .content .component.image-spot.element-as-background .image-spot-element-link .white-buttons a {
  animation: out 0.7s -0.1s ease-in-out forwards;
  background: radial-gradient(50% 300% at right, #3344f6 50%, transparent 50%), radial-gradient(circle at right, #ffffff 100%, transparent 100%);
  background-size: 420% 100%;
  background-position: right bottom;
  background-color: #ffffff;
  color: #3344f6;
  transition: all 0.4s ease-in-out;
  font-size: 12px;
  color: #ffffff;
  background-color: #3344f6;
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: bold;
  border-radius: 16.5px;
  padding: 7px 21px 8px;
  color: #3344f6;
  background-color: #ffffff; }
  .white-buttons .like-button-small:hover, .white-buttons .content .component.image-spot.element-as-background .image-spot-element-link a:hover, .content .component.image-spot.element-as-background .image-spot-element-link .white-buttons a:hover {
    animation: in 0.7s -0.1s ease-in-out forwards;
    background: radial-gradient(50% 300% at left, #3344f6 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(51, 68, 246, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #ffffff 100%, transparent 100%);
    background-size: 420% 100%;
    background-position: right bottom;
    background-color: #ffffff;
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
    color: #ffffff;
    transition: all 0.8s ease-in-out; }
    .white-buttons .like-button-small:hover a, .white-buttons .content .component.image-spot.element-as-background .image-spot-element-link a:hover a, .content .component.image-spot.element-as-background .image-spot-element-link .white-buttons a:hover a {
      color: #ffffff;
      transition: all 0.8s ease-in-out; }
  @media only screen and (min-width: 768px) {
    .white-buttons .like-button-small, .white-buttons .content .component.image-spot.element-as-background .image-spot-element-link a, .content .component.image-spot.element-as-background .image-spot-element-link .white-buttons a {
      font-size: 15px; } }
  .white-buttons .like-button-small a, .white-buttons .content .component.image-spot.element-as-background .image-spot-element-link a a, .content .component.image-spot.element-as-background .image-spot-element-link .white-buttons a a {
    color: #ffffff;
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .white-buttons .like-button-small, .white-buttons .content .component.image-spot.element-as-background .image-spot-element-link a, .content .component.image-spot.element-as-background .image-spot-element-link .white-buttons a {
      border-radius: 18.5px;
      padding: 9px 25px 10px; } }
  .white-buttons .like-button-small a, .white-buttons .content .component.image-spot.element-as-background .image-spot-element-link a a, .content .component.image-spot.element-as-background .image-spot-element-link .white-buttons a a {
    color: #3344f6; }

.white-buttons .like-button-white-minimal {
  font-size: 12px;
  color: #ffffff;
  background-color: #3344f6;
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: bold;
  color: #3344f6;
  background-color: #ffffff; }
  @media only screen and (min-width: 768px) {
    .white-buttons .like-button-white-minimal {
      font-size: 15px; } }
  .white-buttons .like-button-white-minimal a {
    color: #ffffff;
    text-decoration: none; }
  .white-buttons .like-button-white-minimal a {
    color: #3344f6; }

.green-blue-gradient-bg {
  background-image: linear-gradient(to right, #74cbca, #3344f6);
  margin-top: 0; }
  .green-blue-gradient-bg.first-repeating-background, .green-blue-gradient-bg .repeating-background {
    margin-bottom: 0; }
  .green-blue-gradient-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .green-blue-gradient-bg.space-top {
        padding-top: 60px; } }

.charcoal-blue-bg {
  background-color: #24405b;
  margin-top: 0; }
  .charcoal-blue-bg.first-repeating-background, .charcoal-blue-bg .repeating-background {
    margin-bottom: 0; }
  .charcoal-blue-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .charcoal-blue-bg.space-top {
        padding-top: 60px; } }

.warm-gray-bg {
  background-color: #dcdcd6;
  margin-top: 0; }
  .warm-gray-bg.first-repeating-background, .warm-gray-bg .repeating-background {
    margin-bottom: 0; }
  .warm-gray-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .warm-gray-bg.space-top {
        padding-top: 60px; } }

.seafoam-green-50-bg {
  background-color: #badcdf;
  margin-top: 0; }
  .seafoam-green-50-bg.first-repeating-background, .seafoam-green-50-bg .repeating-background {
    margin-bottom: 0; }
  .seafoam-green-50-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .seafoam-green-50-bg.space-top {
        padding-top: 60px; } }

.sky-blue-75-bg {
  background-color: #bcdef7;
  margin-top: 0; }
  .sky-blue-75-bg.first-repeating-background, .sky-blue-75-bg .repeating-background {
    margin-bottom: 0; }
  .sky-blue-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .sky-blue-75-bg.space-top {
        padding-top: 60px; } }

.warm-grey-75-bg {
  background-color: #cacac1;
  margin-top: 0; }
  .warm-grey-75-bg.first-repeating-background, .warm-grey-75-bg .repeating-background {
    margin-bottom: 0; }
  .warm-grey-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .warm-grey-75-bg.space-top {
        padding-top: 60px; } }

.pine-green-75-bg {
  background-color: #609c9c;
  margin-top: 0; }
  .pine-green-75-bg.first-repeating-background, .pine-green-75-bg .repeating-background {
    margin-bottom: 0; }
  .pine-green-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .pine-green-75-bg.space-top {
        padding-top: 60px; } }

.burst-yellow-10-bg {
  background-color: #fcf7ea;
  margin-top: 0; }
  .burst-yellow-10-bg.first-repeating-background, .burst-yellow-10-bg .repeating-background {
    margin-bottom: 0; }
  .burst-yellow-10-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .burst-yellow-10-bg.space-top {
        padding-top: 60px; } }

.walnut-brown-bg {
  background-color: #6c4b42;
  margin-top: 0; }
  .walnut-brown-bg.first-repeating-background, .walnut-brown-bg .repeating-background {
    margin-bottom: 0; }
  .walnut-brown-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .walnut-brown-bg.space-top {
        padding-top: 60px; } }

h1,
.like-h1 {
  color: #24405b; }

.white-txt {
  color: #ffffff; }
  .white-txt h1,
  .white-txt .like-h1,
  .white-txt a,
  .white-txt .like-a {
    color: #ffffff; }

p + p {
  margin-top: 1em; }

p + ol {
  margin-top: 1em; }

p + ul {
  margin-top: 1em; }

p + h1 {
  margin-top: 1em; }

p + h2 {
  margin-top: 1em; }

p + h3 {
  margin-top: 1em; }

p + h4 {
  margin-top: 1em; }

p + h5 {
  margin-top: 1em; }

ol + p {
  margin-top: 1em; }

ol + ol {
  margin-top: 1em; }

ol + ul {
  margin-top: 1em; }

ol + h1 {
  margin-top: 1em; }

ol + h2 {
  margin-top: 1em; }

ol + h3 {
  margin-top: 1em; }

ol + h4 {
  margin-top: 1em; }

ol + h5 {
  margin-top: 1em; }

ul + p {
  margin-top: 1em; }

ul + ol {
  margin-top: 1em; }

ul + ul {
  margin-top: 1em; }

ul + h1 {
  margin-top: 1em; }

ul + h2 {
  margin-top: 1em; }

ul + h3 {
  margin-top: 1em; }

ul + h4 {
  margin-top: 1em; }

ul + h5 {
  margin-top: 1em; }

h1 + p {
  margin-top: 1em; }

h1 + ol {
  margin-top: 1em; }

h1 + ul {
  margin-top: 1em; }

h1 + h1 {
  margin-top: 1em; }

h1 + h2 {
  margin-top: 1em; }

h1 + h3 {
  margin-top: 1em; }

h1 + h4 {
  margin-top: 1em; }

h1 + h5 {
  margin-top: 1em; }

h2 + p {
  margin-top: 1em; }

h2 + ol {
  margin-top: 1em; }

h2 + ul {
  margin-top: 1em; }

h2 + h1 {
  margin-top: 1em; }

h2 + h2 {
  margin-top: 1em; }

h2 + h3 {
  margin-top: 1em; }

h2 + h4 {
  margin-top: 1em; }

h2 + h5 {
  margin-top: 1em; }

h3 + p {
  margin-top: 1em; }

h3 + ol {
  margin-top: 1em; }

h3 + ul {
  margin-top: 1em; }

h3 + h1 {
  margin-top: 1em; }

h3 + h2 {
  margin-top: 1em; }

h3 + h3 {
  margin-top: 1em; }

h3 + h4 {
  margin-top: 1em; }

h3 + h5 {
  margin-top: 1em; }

h4 + p {
  margin-top: 1em; }

h4 + ol {
  margin-top: 1em; }

h4 + ul {
  margin-top: 1em; }

h4 + h1 {
  margin-top: 1em; }

h4 + h2 {
  margin-top: 1em; }

h4 + h3 {
  margin-top: 1em; }

h4 + h4 {
  margin-top: 1em; }

h4 + h5 {
  margin-top: 1em; }

h5 + p {
  margin-top: 1em; }

h5 + ol {
  margin-top: 1em; }

h5 + ul {
  margin-top: 1em; }

h5 + h1 {
  margin-top: 1em; }

h5 + h2 {
  margin-top: 1em; }

h5 + h3 {
  margin-top: 1em; }

h5 + h4 {
  margin-top: 1em; }

h5 + h5 {
  margin-top: 1em; }

.content .component {
  margin-bottom: 40px; }
  .content .component:first-child {
    margin-top: 55px; }
  @media only screen and (min-width: 992px) {
    .content .component {
      margin-bottom: 60px; }
      .content .component:first-child {
        margin-top: 0; } }
  .content .component.empty {
    margin: 0; }
    .content .component.empty:first-child {
      margin-top: 0; }
  .content .component.space-top:first-child {
    margin-top: calc(55px + 40px); }
  .content .component.space-top:not(:first-child) {
    margin-top: 40px; }
  @media only screen and (min-width: 992px) {
    .content .component.space-top, .content .component.space-top:first-child {
      margin-top: 60px; } }
  @media only screen and (min-width: 992px) {
    .content .component.fixed-submenu {
      z-index: 1;
      position: fixed;
      top: 120px;
      width: 100%;
      background-color: #ffffff;
      padding: 20px 0;
      transition: all 0.5s ease-out;
      margin-bottom: 0; }
      .content .component.fixed-submenu.adjustedTopPosition {
        top: 70px; } }
  .content .component.bottom-margin-0 {
    margin-bottom: 0; }

.content .component.two-section-grid > .component-content,
.content .component.three-section-grid > .component-content,
.content .component.four-section-grid > .component-content {
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  flex-direction: column; }
  @media only screen and (min-width: 768px) {
    .content .component.two-section-grid > .component-content,
    .content .component.three-section-grid > .component-content,
    .content .component.four-section-grid > .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      .content .component.two-section-grid > .component-content.full-width,
      .content .component.three-section-grid > .component-content.full-width,
      .content .component.four-section-grid > .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .content .component.two-section-grid > .component-content.regular,
      .content .component.three-section-grid > .component-content.regular,
      .content .component.four-section-grid > .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .content .component.two-section-grid > .component-content.slim,
      .content .component.three-section-grid > .component-content.slim,
      .content .component.four-section-grid > .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }
  @media only screen and (min-width: 992px) {
    .content .component.two-section-grid > .component-content,
    .content .component.three-section-grid > .component-content,
    .content .component.four-section-grid > .component-content {
      flex-direction: row; } }
  .content .component.two-section-grid > .component-content [class^="section-"] .component,
  .content .component.three-section-grid > .component-content [class^="section-"] .component,
  .content .component.four-section-grid > .component-content [class^="section-"] .component {
    width: 100%;
    margin-bottom: 0;
    box-sizing: border-box;
    min-height: 100%;
    padding: 20px; }
    @media only screen and (min-width: 992px) {
      .content .component.two-section-grid > .component-content [class^="section-"] .component,
      .content .component.three-section-grid > .component-content [class^="section-"] .component,
      .content .component.four-section-grid > .component-content [class^="section-"] .component {
        padding: 30px; } }
    .content .component.two-section-grid > .component-content [class^="section-"] .component:first-child,
    .content .component.three-section-grid > .component-content [class^="section-"] .component:first-child,
    .content .component.four-section-grid > .component-content [class^="section-"] .component:first-child {
      margin-top: 0; }
  .content .component.two-section-grid > .component-content [class^="section-"] .component-content,
  .content .component.three-section-grid > .component-content [class^="section-"] .component-content,
  .content .component.four-section-grid > .component-content [class^="section-"] .component-content {
    width: 100%;
    max-width: none;
    margin-left: 0;
    margin-right: 0; }
    .content .component.two-section-grid > .component-content [class^="section-"] .component-content img,
    .content .component.three-section-grid > .component-content [class^="section-"] .component-content img,
    .content .component.four-section-grid > .component-content [class^="section-"] .component-content img {
      max-width: 100%;
      height: auto; }

.content .component.two-section-grid.middle-align-content [class^="section-"],
.content .component.three-section-grid.middle-align-content [class^="section-"],
.content .component.four-section-grid.middle-align-content [class^="section-"] {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .content .component.two-section-grid.middle-align-content [class^="section-"] .component,
  .content .component.three-section-grid.middle-align-content [class^="section-"] .component,
  .content .component.four-section-grid.middle-align-content [class^="section-"] .component {
    min-height: 0; }

.content .component.two-section-grid.with-element-padding > .component-content > [class^="section-"] > .component,
.content .component.three-section-grid.with-element-padding > .component-content > [class^="section-"] > .component,
.content .component.four-section-grid.with-element-padding > .component-content > [class^="section-"] > .component {
  padding: 1em; }

.content .component.two-section-grid.green-blue-gradient-bg,
.content .component.three-section-grid.green-blue-gradient-bg,
.content .component.four-section-grid.green-blue-gradient-bg {
  background-image: linear-gradient(to right, #74cbca, #3344f6);
  margin-top: 0;
  padding: 20px 0; }
  .content .component.two-section-grid.green-blue-gradient-bg.first-repeating-background, .content .component.two-section-grid.green-blue-gradient-bg .repeating-background,
  .content .component.three-section-grid.green-blue-gradient-bg.first-repeating-background,
  .content .component.three-section-grid.green-blue-gradient-bg .repeating-background,
  .content .component.four-section-grid.green-blue-gradient-bg.first-repeating-background,
  .content .component.four-section-grid.green-blue-gradient-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.two-section-grid.green-blue-gradient-bg.space-top,
  .content .component.three-section-grid.green-blue-gradient-bg.space-top,
  .content .component.four-section-grid.green-blue-gradient-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.two-section-grid.green-blue-gradient-bg.space-top,
      .content .component.three-section-grid.green-blue-gradient-bg.space-top,
      .content .component.four-section-grid.green-blue-gradient-bg.space-top {
        padding-top: 60px; } }
  @media only screen and (min-width: 992px) {
    .content .component.two-section-grid.green-blue-gradient-bg,
    .content .component.three-section-grid.green-blue-gradient-bg,
    .content .component.four-section-grid.green-blue-gradient-bg {
      padding: 30px 0; } }

.content .component.two-section-grid.charcoal-blue-bg,
.content .component.three-section-grid.charcoal-blue-bg,
.content .component.four-section-grid.charcoal-blue-bg {
  background-color: #24405b;
  margin-top: 0;
  padding: 20px 0; }
  .content .component.two-section-grid.charcoal-blue-bg.first-repeating-background, .content .component.two-section-grid.charcoal-blue-bg .repeating-background,
  .content .component.three-section-grid.charcoal-blue-bg.first-repeating-background,
  .content .component.three-section-grid.charcoal-blue-bg .repeating-background,
  .content .component.four-section-grid.charcoal-blue-bg.first-repeating-background,
  .content .component.four-section-grid.charcoal-blue-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.two-section-grid.charcoal-blue-bg.space-top,
  .content .component.three-section-grid.charcoal-blue-bg.space-top,
  .content .component.four-section-grid.charcoal-blue-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.two-section-grid.charcoal-blue-bg.space-top,
      .content .component.three-section-grid.charcoal-blue-bg.space-top,
      .content .component.four-section-grid.charcoal-blue-bg.space-top {
        padding-top: 60px; } }
  @media only screen and (min-width: 992px) {
    .content .component.two-section-grid.charcoal-blue-bg,
    .content .component.three-section-grid.charcoal-blue-bg,
    .content .component.four-section-grid.charcoal-blue-bg {
      padding: 30px 0; } }

.content .component.two-section-grid.warm-gray-bg,
.content .component.three-section-grid.warm-gray-bg,
.content .component.four-section-grid.warm-gray-bg {
  background-color: #dcdcd6;
  margin-top: 0;
  padding: 20px 0; }
  .content .component.two-section-grid.warm-gray-bg.first-repeating-background, .content .component.two-section-grid.warm-gray-bg .repeating-background,
  .content .component.three-section-grid.warm-gray-bg.first-repeating-background,
  .content .component.three-section-grid.warm-gray-bg .repeating-background,
  .content .component.four-section-grid.warm-gray-bg.first-repeating-background,
  .content .component.four-section-grid.warm-gray-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.two-section-grid.warm-gray-bg.space-top,
  .content .component.three-section-grid.warm-gray-bg.space-top,
  .content .component.four-section-grid.warm-gray-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.two-section-grid.warm-gray-bg.space-top,
      .content .component.three-section-grid.warm-gray-bg.space-top,
      .content .component.four-section-grid.warm-gray-bg.space-top {
        padding-top: 60px; } }
  @media only screen and (min-width: 992px) {
    .content .component.two-section-grid.warm-gray-bg,
    .content .component.three-section-grid.warm-gray-bg,
    .content .component.four-section-grid.warm-gray-bg {
      padding: 30px 0; } }

.content .component.two-section-grid.seafoam-green-50-bg,
.content .component.three-section-grid.seafoam-green-50-bg,
.content .component.four-section-grid.seafoam-green-50-bg {
  background-color: #badcdf;
  margin-top: 0;
  padding: 20px 0; }
  .content .component.two-section-grid.seafoam-green-50-bg.first-repeating-background, .content .component.two-section-grid.seafoam-green-50-bg .repeating-background,
  .content .component.three-section-grid.seafoam-green-50-bg.first-repeating-background,
  .content .component.three-section-grid.seafoam-green-50-bg .repeating-background,
  .content .component.four-section-grid.seafoam-green-50-bg.first-repeating-background,
  .content .component.four-section-grid.seafoam-green-50-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.two-section-grid.seafoam-green-50-bg.space-top,
  .content .component.three-section-grid.seafoam-green-50-bg.space-top,
  .content .component.four-section-grid.seafoam-green-50-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.two-section-grid.seafoam-green-50-bg.space-top,
      .content .component.three-section-grid.seafoam-green-50-bg.space-top,
      .content .component.four-section-grid.seafoam-green-50-bg.space-top {
        padding-top: 60px; } }
  @media only screen and (min-width: 992px) {
    .content .component.two-section-grid.seafoam-green-50-bg,
    .content .component.three-section-grid.seafoam-green-50-bg,
    .content .component.four-section-grid.seafoam-green-50-bg {
      padding: 30px 0; } }

.content .component.two-section-grid.sky-blue-75-bg,
.content .component.three-section-grid.sky-blue-75-bg,
.content .component.four-section-grid.sky-blue-75-bg {
  background-color: #bcdef7;
  margin-top: 0;
  padding: 20px 0; }
  .content .component.two-section-grid.sky-blue-75-bg.first-repeating-background, .content .component.two-section-grid.sky-blue-75-bg .repeating-background,
  .content .component.three-section-grid.sky-blue-75-bg.first-repeating-background,
  .content .component.three-section-grid.sky-blue-75-bg .repeating-background,
  .content .component.four-section-grid.sky-blue-75-bg.first-repeating-background,
  .content .component.four-section-grid.sky-blue-75-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.two-section-grid.sky-blue-75-bg.space-top,
  .content .component.three-section-grid.sky-blue-75-bg.space-top,
  .content .component.four-section-grid.sky-blue-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.two-section-grid.sky-blue-75-bg.space-top,
      .content .component.three-section-grid.sky-blue-75-bg.space-top,
      .content .component.four-section-grid.sky-blue-75-bg.space-top {
        padding-top: 60px; } }
  @media only screen and (min-width: 992px) {
    .content .component.two-section-grid.sky-blue-75-bg,
    .content .component.three-section-grid.sky-blue-75-bg,
    .content .component.four-section-grid.sky-blue-75-bg {
      padding: 30px 0; } }

.content .component.two-section-grid.warm-grey-75-bg,
.content .component.three-section-grid.warm-grey-75-bg,
.content .component.four-section-grid.warm-grey-75-bg {
  background-color: #cacac1;
  margin-top: 0;
  padding: 20px 0; }
  .content .component.two-section-grid.warm-grey-75-bg.first-repeating-background, .content .component.two-section-grid.warm-grey-75-bg .repeating-background,
  .content .component.three-section-grid.warm-grey-75-bg.first-repeating-background,
  .content .component.three-section-grid.warm-grey-75-bg .repeating-background,
  .content .component.four-section-grid.warm-grey-75-bg.first-repeating-background,
  .content .component.four-section-grid.warm-grey-75-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.two-section-grid.warm-grey-75-bg.space-top,
  .content .component.three-section-grid.warm-grey-75-bg.space-top,
  .content .component.four-section-grid.warm-grey-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.two-section-grid.warm-grey-75-bg.space-top,
      .content .component.three-section-grid.warm-grey-75-bg.space-top,
      .content .component.four-section-grid.warm-grey-75-bg.space-top {
        padding-top: 60px; } }
  @media only screen and (min-width: 992px) {
    .content .component.two-section-grid.warm-grey-75-bg,
    .content .component.three-section-grid.warm-grey-75-bg,
    .content .component.four-section-grid.warm-grey-75-bg {
      padding: 30px 0; } }

.content .component.two-section-grid.pine-green-75-bg,
.content .component.three-section-grid.pine-green-75-bg,
.content .component.four-section-grid.pine-green-75-bg {
  background-color: #609c9c;
  margin-top: 0;
  padding: 20px 0; }
  .content .component.two-section-grid.pine-green-75-bg.first-repeating-background, .content .component.two-section-grid.pine-green-75-bg .repeating-background,
  .content .component.three-section-grid.pine-green-75-bg.first-repeating-background,
  .content .component.three-section-grid.pine-green-75-bg .repeating-background,
  .content .component.four-section-grid.pine-green-75-bg.first-repeating-background,
  .content .component.four-section-grid.pine-green-75-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.two-section-grid.pine-green-75-bg.space-top,
  .content .component.three-section-grid.pine-green-75-bg.space-top,
  .content .component.four-section-grid.pine-green-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.two-section-grid.pine-green-75-bg.space-top,
      .content .component.three-section-grid.pine-green-75-bg.space-top,
      .content .component.four-section-grid.pine-green-75-bg.space-top {
        padding-top: 60px; } }
  @media only screen and (min-width: 992px) {
    .content .component.two-section-grid.pine-green-75-bg,
    .content .component.three-section-grid.pine-green-75-bg,
    .content .component.four-section-grid.pine-green-75-bg {
      padding: 30px 0; } }

.content .component.two-section-grid.burst-yellow-10-bg,
.content .component.three-section-grid.burst-yellow-10-bg,
.content .component.four-section-grid.burst-yellow-10-bg {
  background-color: #fcf7ea;
  margin-top: 0;
  padding: 20px 0; }
  .content .component.two-section-grid.burst-yellow-10-bg.first-repeating-background, .content .component.two-section-grid.burst-yellow-10-bg .repeating-background,
  .content .component.three-section-grid.burst-yellow-10-bg.first-repeating-background,
  .content .component.three-section-grid.burst-yellow-10-bg .repeating-background,
  .content .component.four-section-grid.burst-yellow-10-bg.first-repeating-background,
  .content .component.four-section-grid.burst-yellow-10-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.two-section-grid.burst-yellow-10-bg.space-top,
  .content .component.three-section-grid.burst-yellow-10-bg.space-top,
  .content .component.four-section-grid.burst-yellow-10-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.two-section-grid.burst-yellow-10-bg.space-top,
      .content .component.three-section-grid.burst-yellow-10-bg.space-top,
      .content .component.four-section-grid.burst-yellow-10-bg.space-top {
        padding-top: 60px; } }
  @media only screen and (min-width: 992px) {
    .content .component.two-section-grid.burst-yellow-10-bg,
    .content .component.three-section-grid.burst-yellow-10-bg,
    .content .component.four-section-grid.burst-yellow-10-bg {
      padding: 30px 0; } }

.content .component.two-section-grid.walnut-brown-bg,
.content .component.three-section-grid.walnut-brown-bg,
.content .component.four-section-grid.walnut-brown-bg {
  background-color: #6c4b42;
  margin-top: 0;
  padding: 20px 0; }
  .content .component.two-section-grid.walnut-brown-bg.first-repeating-background, .content .component.two-section-grid.walnut-brown-bg .repeating-background,
  .content .component.three-section-grid.walnut-brown-bg.first-repeating-background,
  .content .component.three-section-grid.walnut-brown-bg .repeating-background,
  .content .component.four-section-grid.walnut-brown-bg.first-repeating-background,
  .content .component.four-section-grid.walnut-brown-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.two-section-grid.walnut-brown-bg.space-top,
  .content .component.three-section-grid.walnut-brown-bg.space-top,
  .content .component.four-section-grid.walnut-brown-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.two-section-grid.walnut-brown-bg.space-top,
      .content .component.three-section-grid.walnut-brown-bg.space-top,
      .content .component.four-section-grid.walnut-brown-bg.space-top {
        padding-top: 60px; } }
  @media only screen and (min-width: 992px) {
    .content .component.two-section-grid.walnut-brown-bg,
    .content .component.three-section-grid.walnut-brown-bg,
    .content .component.four-section-grid.walnut-brown-bg {
      padding: 30px 0; } }

@media only screen and (min-width: 992px) {
  .content .component.two-section-grid [class^="section-"] {
    width: calc(100% / 2); }
  .content .component.two-section-grid.forty-sixty .section-one {
    width: 40%; }
  .content .component.two-section-grid.forty-sixty .section-two {
    width: 60%; }
  .content .component.two-section-grid.sixty-forty .section-one {
    width: 60%; }
  .content .component.two-section-grid.sixty-forty .section-two {
    width: 40%; } }

@media only screen and (min-width: 992px) {
  .content .component.three-section-grid [class^="section-"] {
    width: calc(100% / 3); } }

@media only screen and (min-width: 992px) {
  .content .component.four-section-grid [class^="section-"] {
    width: calc(100% / 4); } }

.icon {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: "liga";
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased; }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
.footer {
  background-color: #74cecd;
  color: #24405b;
  padding-top: 40px;
  padding-bottom: 30px; }
  @media only screen and (min-width: 768px) {
    .footer {
      padding-top: 53px; } }
  @media only screen and (min-width: 768px) {
    .footer {
      padding-bottom: 30px; } }
  .footer .two-section-grid {
    margin-left: 30px;
    margin-right: 30px; }
    @media only screen and (min-width: 768px) {
      .footer .two-section-grid {
        margin-left: auto;
        margin-right: auto;
        width: 86%;
        max-width: 1200px; }
        .footer .two-section-grid.full-width {
          width: 100%;
          max-width: 1600px; }
        .footer .two-section-grid.regular {
          width: 86%;
          max-width: 1200px; }
        .footer .two-section-grid.slim {
          width: 47.2%;
          max-width: 660px; } }
  .footer .three-section-grid > .component-content {
    display: flex;
    flex-direction: column; }
    @media only screen and (min-width: 992px) {
      .footer .three-section-grid > .component-content {
        flex-direction: row; } }
  @media only screen and (min-width: 992px) {
    .footer .three-section-grid .section-one,
    .footer .three-section-grid .section-two {
      width: 17.5%; } }
  .footer .three-section-grid .section-three {
    margin-top: 40px; }
    @media only screen and (min-width: 992px) {
      .footer .three-section-grid .section-three {
        margin-top: 0;
        width: 65%; } }
  @media only screen and (min-width: 992px) {
    .footer .three-section-grid .section-one .link-list {
      padding-top: 31px; } }
  @media only screen and (min-width: 992px) and (min-width: 768px) {
    .footer .three-section-grid .section-one .link-list {
      padding-top: 31px; } }
  .footer .three-section-grid .section-one .link-list ul {
    margin-top: 0; }
  .footer .three-section-grid .section-one .link-list li + li {
    padding-top: 16px; }
    @media only screen and (min-width: 768px) {
      .footer .three-section-grid .section-one .link-list li + li {
        padding-top: 16px; } }
  .footer .three-section-grid .section-one .link-list a {
    font-weight: normal;
    font-size: 15px;
    line-height: 1.59;
    text-decoration: none;
    color: #24405b; }
    @media only screen and (min-width: 768px) {
      .footer .three-section-grid .section-one .link-list a {
        font-size: 17px; } }
  .footer .three-section-grid .section-three .rich-text .component-content {
    position: static;
    width: auto;
    max-width: none;
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.4; }
    @media only screen and (min-width: 768px) {
      .footer .three-section-grid .section-three .rich-text .component-content {
        font-size: 15px; } }
    .footer .three-section-grid .section-three .rich-text .component-content h2 {
      font-size: 15px;
      font-weight: 600;
      line-height: 1.29;
      letter-spacing: -0.23px;
      color: #24405b; }
      @media only screen and (min-width: 768px) {
        .footer .three-section-grid .section-three .rich-text .component-content h2 {
          font-size: 17px; } }
  .footer .three-section-grid .section-three .link-list {
    margin-top: 23px; }
    .footer .three-section-grid .section-three .link-list ul {
      margin-top: 0;
      display: flex; }
    .footer .three-section-grid .section-three .link-list li + li {
      margin-left: 10px; }
    .footer .three-section-grid .section-three .link-list a {
      padding: 23px 23px 0 0;
      display: inline-block;
      font-size: 0;
      line-height: 27px;
      text-decoration: none; }
      .footer .three-section-grid .section-three .link-list a::before {
        content: "";
        background-size: contain;
        width: 27px;
        height: 27px;
        display: inline-block;
        vertical-align: middle; }
      .footer .three-section-grid .section-three .link-list a.ico-facebook::before {
        background: url("/design/build/demant2019/assets/social-facebook-circle-charcoal-white.svg") no-repeat;
        background-size: contain; }
      .footer .three-section-grid .section-three .link-list a.ico-twitter::before {
        background: url("/design/build/demant2019/assets/social-x-circle-charcoal-white.svg") no-repeat; }
      .footer .three-section-grid .section-three .link-list a.ico-linkedin::before {
        background: url("/design/build/demant2019/assets/social-linkedin-circle-charcoal-white.svg") no-repeat; }
  .footer .two-section-grid > .component-content > .section-two {
    position: relative;
    margin-top: 30px; }
    .footer .two-section-grid > .component-content > .section-two::before {
      content: "";
      display: inline-block;
      background: url(/design/build/demant2019/assets/demant-logo.svg) no-repeat;
      background-size: contain;
      position: absolute;
      width: 115px;
      height: 30px; }
    .footer .two-section-grid > .component-content > .section-two .rich-text .component-content {
      position: static;
      width: auto;
      max-width: none;
      margin: 0;
      display: flex;
      flex-direction: column;
      font-size: 11px;
      letter-spacing: 0.07px;
      padding-top: 40px; }
      @media only screen and (min-width: 992px) {
        .footer .two-section-grid > .component-content > .section-two .rich-text .component-content {
          flex-direction: row; } }
      @media only screen and (min-width: 768px) {
        .footer .two-section-grid > .component-content > .section-two .rich-text .component-content {
          font-size: 13px; } }
      @media only screen and (min-width: 992px) {
        .footer .two-section-grid > .component-content > .section-two .rich-text .component-content {
          padding-top: 0;
          margin-left: 35%; }
          .footer .two-section-grid > .component-content > .section-two .rich-text .component-content p + p {
            margin-top: 0;
            margin-left: 65px; } }
      .footer .two-section-grid > .component-content > .section-two .rich-text .component-content a {
        font-weight: normal;
        color: #24405b;
        text-decoration: none; }

body.invert .footer {
  background-color: #dcdcd6;
  color: #24405b;
  padding-top: 40px;
  padding-bottom: 30px; }
  @media only screen and (min-width: 768px) {
    body.invert .footer {
      padding-top: 53px; } }
  @media only screen and (min-width: 768px) {
    body.invert .footer {
      padding-bottom: 30px; } }
  body.invert .footer .two-section-grid {
    margin-left: 30px;
    margin-right: 30px; }
    @media only screen and (min-width: 768px) {
      body.invert .footer .two-section-grid {
        margin-left: auto;
        margin-right: auto;
        width: 86%;
        max-width: 1200px; }
        body.invert .footer .two-section-grid.full-width {
          width: 100%;
          max-width: 1600px; }
        body.invert .footer .two-section-grid.regular {
          width: 86%;
          max-width: 1200px; }
        body.invert .footer .two-section-grid.slim {
          width: 47.2%;
          max-width: 660px; } }
  body.invert .footer .three-section-grid > .component-content {
    display: flex;
    flex-direction: column; }
    @media only screen and (min-width: 992px) {
      body.invert .footer .three-section-grid > .component-content {
        flex-direction: row; } }
  @media only screen and (min-width: 992px) {
    body.invert .footer .three-section-grid .section-one,
    body.invert .footer .three-section-grid .section-two {
      width: 17.5%; } }
  body.invert .footer .three-section-grid .section-three {
    margin-top: 40px; }
    @media only screen and (min-width: 992px) {
      body.invert .footer .three-section-grid .section-three {
        margin-top: 0;
        width: 65%; } }
  @media only screen and (min-width: 992px) {
    body.invert .footer .three-section-grid .section-one .link-list {
      padding-top: 31px; } }
  @media only screen and (min-width: 992px) and (min-width: 768px) {
    body.invert .footer .three-section-grid .section-one .link-list {
      padding-top: 31px; } }
  body.invert .footer .three-section-grid .section-one .link-list ul {
    margin-top: 0; }
  body.invert .footer .three-section-grid .section-one .link-list li + li {
    padding-top: 16px; }
    @media only screen and (min-width: 768px) {
      body.invert .footer .three-section-grid .section-one .link-list li + li {
        padding-top: 16px; } }
  body.invert .footer .three-section-grid .section-one .link-list a {
    font-weight: normal;
    font-size: 15px;
    line-height: 1.59;
    text-decoration: none;
    color: #24405b; }
    @media only screen and (min-width: 768px) {
      body.invert .footer .three-section-grid .section-one .link-list a {
        font-size: 17px; } }
  body.invert .footer .three-section-grid .section-three .rich-text .component-content {
    position: static;
    width: auto;
    max-width: none;
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.4; }
    @media only screen and (min-width: 768px) {
      body.invert .footer .three-section-grid .section-three .rich-text .component-content {
        font-size: 15px; } }
    body.invert .footer .three-section-grid .section-three .rich-text .component-content h2 {
      font-size: 15px;
      font-weight: 600;
      line-height: 1.29;
      letter-spacing: -0.23px;
      color: #24405b; }
      @media only screen and (min-width: 768px) {
        body.invert .footer .three-section-grid .section-three .rich-text .component-content h2 {
          font-size: 17px; } }
  body.invert .footer .three-section-grid .section-three .link-list {
    margin-top: 23px; }
    body.invert .footer .three-section-grid .section-three .link-list ul {
      margin-top: 0;
      display: flex; }
    body.invert .footer .three-section-grid .section-three .link-list li + li {
      margin-left: 10px; }
    body.invert .footer .three-section-grid .section-three .link-list a {
      padding: 23px 23px 0 0;
      display: inline-block;
      font-size: 0;
      line-height: 27px;
      text-decoration: none; }
      body.invert .footer .three-section-grid .section-three .link-list a::before {
        content: "";
        background-size: contain;
        width: 27px;
        height: 27px;
        display: inline-block;
        vertical-align: middle; }
      body.invert .footer .three-section-grid .section-three .link-list a.ico-facebook::before {
        background: url("/design/build/demant2019/assets/social-facebook-circle-charcoal-white.svg") no-repeat;
        background-size: contain; }
      body.invert .footer .three-section-grid .section-three .link-list a.ico-twitter::before {
        background: url("/design/build/demant2019/assets/social-x-circle-charcoal-white.svg") no-repeat; }
      body.invert .footer .three-section-grid .section-three .link-list a.ico-linkedin::before {
        background: url("/design/build/demant2019/assets/social-linkedin-circle-charcoal-white.svg") no-repeat; }
  body.invert .footer .two-section-grid > .component-content > .section-two {
    position: relative;
    margin-top: 30px; }
    body.invert .footer .two-section-grid > .component-content > .section-two::before {
      content: "";
      display: inline-block;
      background: url(/design/build/demant2019/assets/demant-logo.svg) no-repeat;
      background-size: contain;
      position: absolute;
      width: 115px;
      height: 30px; }
    body.invert .footer .two-section-grid > .component-content > .section-two .rich-text .component-content {
      position: static;
      width: auto;
      max-width: none;
      margin: 0;
      display: flex;
      flex-direction: column;
      font-size: 11px;
      letter-spacing: 0.07px;
      padding-top: 40px; }
      @media only screen and (min-width: 992px) {
        body.invert .footer .two-section-grid > .component-content > .section-two .rich-text .component-content {
          flex-direction: row; } }
      @media only screen and (min-width: 768px) {
        body.invert .footer .two-section-grid > .component-content > .section-two .rich-text .component-content {
          font-size: 13px; } }
      @media only screen and (min-width: 992px) {
        body.invert .footer .two-section-grid > .component-content > .section-two .rich-text .component-content {
          padding-top: 0;
          margin-left: 35%; }
          body.invert .footer .two-section-grid > .component-content > .section-two .rich-text .component-content p + p {
            margin-top: 0;
            margin-left: 65px; } }
      body.invert .footer .two-section-grid > .component-content > .section-two .rich-text .component-content a {
        font-weight: normal;
        color: #24405b;
        text-decoration: none; }

body#graduate-universe .footer {
  background-color: #74cecd;
  color: #24405b;
  padding-top: 40px;
  padding-bottom: 30px; }
  @media only screen and (min-width: 768px) {
    body#graduate-universe .footer {
      padding-top: 53px; } }
  @media only screen and (min-width: 768px) {
    body#graduate-universe .footer {
      padding-bottom: 30px; } }
  body#graduate-universe .footer .two-section-grid {
    margin-left: 30px;
    margin-right: 30px; }
    @media only screen and (min-width: 768px) {
      body#graduate-universe .footer .two-section-grid {
        margin-left: auto;
        margin-right: auto;
        width: 86%;
        max-width: 1200px; }
        body#graduate-universe .footer .two-section-grid.full-width {
          width: 100%;
          max-width: 1600px; }
        body#graduate-universe .footer .two-section-grid.regular {
          width: 86%;
          max-width: 1200px; }
        body#graduate-universe .footer .two-section-grid.slim {
          width: 47.2%;
          max-width: 660px; } }
  body#graduate-universe .footer .three-section-grid > .component-content {
    display: flex;
    flex-direction: column; }
    @media only screen and (min-width: 992px) {
      body#graduate-universe .footer .three-section-grid > .component-content {
        flex-direction: row; } }
  @media only screen and (min-width: 992px) {
    body#graduate-universe .footer .three-section-grid .section-one,
    body#graduate-universe .footer .three-section-grid .section-two {
      width: 17.5%; } }
  body#graduate-universe .footer .three-section-grid .section-three {
    margin-top: 40px; }
    @media only screen and (min-width: 992px) {
      body#graduate-universe .footer .three-section-grid .section-three {
        margin-top: 0;
        width: 65%; } }
  @media only screen and (min-width: 992px) {
    body#graduate-universe .footer .three-section-grid .section-one .link-list {
      padding-top: 31px; } }
  @media only screen and (min-width: 992px) and (min-width: 768px) {
    body#graduate-universe .footer .three-section-grid .section-one .link-list {
      padding-top: 31px; } }
  body#graduate-universe .footer .three-section-grid .section-one .link-list ul {
    margin-top: 0; }
  body#graduate-universe .footer .three-section-grid .section-one .link-list li + li {
    padding-top: 16px; }
    @media only screen and (min-width: 768px) {
      body#graduate-universe .footer .three-section-grid .section-one .link-list li + li {
        padding-top: 16px; } }
  body#graduate-universe .footer .three-section-grid .section-one .link-list a {
    font-weight: normal;
    font-size: 15px;
    line-height: 1.59;
    text-decoration: none;
    color: #24405b; }
    @media only screen and (min-width: 768px) {
      body#graduate-universe .footer .three-section-grid .section-one .link-list a {
        font-size: 17px; } }
  body#graduate-universe .footer .three-section-grid .section-three .rich-text .component-content {
    position: static;
    width: auto;
    max-width: none;
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.4; }
    @media only screen and (min-width: 768px) {
      body#graduate-universe .footer .three-section-grid .section-three .rich-text .component-content {
        font-size: 15px; } }
    body#graduate-universe .footer .three-section-grid .section-three .rich-text .component-content h2 {
      font-size: 15px;
      font-weight: 600;
      line-height: 1.29;
      letter-spacing: -0.23px;
      color: #24405b; }
      @media only screen and (min-width: 768px) {
        body#graduate-universe .footer .three-section-grid .section-three .rich-text .component-content h2 {
          font-size: 17px; } }
  body#graduate-universe .footer .three-section-grid .section-three .link-list {
    margin-top: 23px; }
    body#graduate-universe .footer .three-section-grid .section-three .link-list ul {
      margin-top: 0;
      display: flex; }
    body#graduate-universe .footer .three-section-grid .section-three .link-list li + li {
      margin-left: 10px; }
    body#graduate-universe .footer .three-section-grid .section-three .link-list a {
      padding: 23px 23px 0 0;
      display: inline-block;
      font-size: 0;
      line-height: 27px;
      text-decoration: none; }
      body#graduate-universe .footer .three-section-grid .section-three .link-list a::before {
        content: "";
        background-size: contain;
        width: 27px;
        height: 27px;
        display: inline-block;
        vertical-align: middle; }
      body#graduate-universe .footer .three-section-grid .section-three .link-list a.ico-facebook::before {
        background: url("/design/build/demant2019/assets/social-facebook-circle-charcoal-white.svg") no-repeat;
        background-size: contain; }
      body#graduate-universe .footer .three-section-grid .section-three .link-list a.ico-twitter::before {
        background: url("/design/build/demant2019/assets/social-x-circle-charcoal-white.svg") no-repeat; }
      body#graduate-universe .footer .three-section-grid .section-three .link-list a.ico-linkedin::before {
        background: url("/design/build/demant2019/assets/social-linkedin-circle-charcoal-white.svg") no-repeat; }
  body#graduate-universe .footer .two-section-grid > .component-content > .section-two {
    position: relative;
    margin-top: 30px; }
    body#graduate-universe .footer .two-section-grid > .component-content > .section-two::before {
      content: "";
      display: inline-block;
      background: url(/design/build/demant2019/assets/demant-logo.svg) no-repeat;
      background-size: contain;
      position: absolute;
      width: 115px;
      height: 30px; }
    body#graduate-universe .footer .two-section-grid > .component-content > .section-two .rich-text .component-content {
      position: static;
      width: auto;
      max-width: none;
      margin: 0;
      display: flex;
      flex-direction: column;
      font-size: 11px;
      letter-spacing: 0.07px;
      padding-top: 40px; }
      @media only screen and (min-width: 992px) {
        body#graduate-universe .footer .two-section-grid > .component-content > .section-two .rich-text .component-content {
          flex-direction: row; } }
      @media only screen and (min-width: 768px) {
        body#graduate-universe .footer .two-section-grid > .component-content > .section-two .rich-text .component-content {
          font-size: 13px; } }
      @media only screen and (min-width: 992px) {
        body#graduate-universe .footer .two-section-grid > .component-content > .section-two .rich-text .component-content {
          padding-top: 0;
          margin-left: 35%; }
          body#graduate-universe .footer .two-section-grid > .component-content > .section-two .rich-text .component-content p + p {
            margin-top: 0;
            margin-left: 65px; } }
      body#graduate-universe .footer .two-section-grid > .component-content > .section-two .rich-text .component-content a {
        font-weight: normal;
        color: #24405b;
        text-decoration: none; }

body#graduate-universe .footer .three-section-grid .section-three .link-list {
  margin-top: 33px; }
  body#graduate-universe .footer .three-section-grid .section-three .link-list h3 {
    font-weight: normal;
    font-size: 12px; }
  body#graduate-universe .footer .three-section-grid .section-three .link-list ul {
    margin-top: 10px; }
  body#graduate-universe .footer .three-section-grid .section-three .link-list li + li {
    margin-left: 10px; }

body#graduate-universe .footer .three-section-grid .section-three .link-list a {
  width: 27px;
  height: 27px;
  overflow: hidden;
  display: inline-block;
  margin: 0; }
  body#graduate-universe .footer .three-section-grid .section-three .link-list a::before {
    margin-top: 0; }
  body#graduate-universe .footer .three-section-grid .section-three .link-list a.ico-twitter::before {
    background: url("/design/build/demant2019/assets/social-x-circle-charcoal-white.svg") no-repeat; }
  body#graduate-universe .footer .three-section-grid .section-three .link-list a.ico-linkedin::before {
    background: url("/design/build/demant2019/assets/social-linkedin-circle-charcoal-white.svg") no-repeat; }
  body#graduate-universe .footer .three-section-grid .section-three .link-list a.ico-facebook::before {
    background: url("/design/build/demant2019/assets/social-facebook-circle-charcoal-white.svg") no-repeat;
    background-size: contain; }
  body#graduate-universe .footer .three-section-grid .section-three .link-list a.ico-youtube::before {
    background: url("/design/build/demant2019/assets/social-youtube-circle-charcoal-white.svg") no-repeat;
    background-size: contain; }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
@media only screen and (min-width: 992px) {
  .header {
    min-height: 87px; } }

@media only screen and (min-width: 1400px) {
  .header {
    min-height: 120px; } }

.header .component {
  display: none; }

.header .mobile {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  height: 55px;
  transition: all 0.5s ease-out;
  width: 100vw; }
  .header .mobile .static-logo img {
    padding: 15px 25px;
    height: 24px;
    width: auto; }
    @media only screen and (min-width: 768px) {
      .header .mobile .static-logo img {
        padding: 15px 25px; } }
  .header .mobile button {
    background: transparent;
    border: 0;
    padding: 0; }
  .header .mobile .open {
    position: absolute;
    top: 8px;
    right: 12px;
    width: 40px;
    height: 40px;
    background-color: #3344f6;
    border-radius: 50%;
    transition: all 0.3s ease; }
    .header .mobile .open span {
      position: absolute;
      left: 11px;
      width: 18px;
      height: 3px;
      background-color: #ffffff;
      transition: all 0.3s ease; }
      .header .mobile .open span:nth-child(1) {
        top: 14px; }
      .header .mobile .open span:nth-child(2) {
        top: 19px; }
      .header .mobile .open span:nth-child(3) {
        top: 24px; }
    .header .mobile .open.close {
      top: 15px;
      right: 20px;
      width: 51px;
      height: 51px; }
      .header .mobile .open.close span {
        left: 13px;
        width: 24px; }
        .header .mobile .open.close span:nth-child(1) {
          transform: rotate(-45deg) translate(-3px, 4px);
          top: 19px; }
        .header .mobile .open.close span:nth-child(2) {
          opacity: 0; }
        .header .mobile .open.close span:nth-child(3) {
          transform: rotate(45deg) translate(-3px, -4px);
          top: 29px; }
    .header .mobile .open.upper {
      background-color: rgba(255, 255, 255, 0.75); }
  .header .mobile .mobile-container {
    background-color: #24405b;
    padding: 25px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(100%);
    transition: transform 0.3s; }
    @media only screen and (min-width: 768px) {
      .header .mobile .mobile-container {
        padding: 25px; } }
    .header .mobile .mobile-container.active {
      transform: none; }
    .header .mobile .mobile-container .logo {
      width: 40%; }
    .header .mobile .mobile-container .search-field-spot {
      background-color: #24405b;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 10%;
      z-index: 1; }
      .header .mobile .mobile-container .search-field-spot .container {
        padding: 25px; }
        .header .mobile .mobile-container .search-field-spot .container .search-field {
          border-bottom: 1px solid #ffffff;
          display: flex;
          flex-direction: row;
          padding: 8px 0;
          margin-bottom: 8px; }
          .header .mobile .mobile-container .search-field-spot .container .search-field input[type="text"] {
            background: none;
            border: none;
            color: #ffffff;
            font-size: 24px;
            flex-grow: 2;
            width: 100%; }
          .header .mobile .mobile-container .search-field-spot .container .search-field input[type="text"]::placeholder {
            color: #f4f4f4; }
          .header .mobile .mobile-container .search-field-spot .container .search-field input[type="text"]:focus {
            outline: none; }
          .header .mobile .mobile-container .search-field-spot .container .search-field .search-icon {
            font-family: "Material Icons";
            font-weight: normal;
            font-style: normal;
            font-size: 24px;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            font-feature-settings: "liga";
            -webkit-font-feature-settings: "liga";
            -webkit-font-smoothing: antialiased;
            cursor: pointer; }
        .header .mobile .mobile-container .search-field-spot .container .search-suggestion-container {
          overflow-y: auto; }
          .header .mobile .mobile-container .search-field-spot .container .search-suggestion-container .search-suggestion {
            cursor: pointer;
            color: #ffffff;
            font-size: 20px;
            padding: 5px 0; }
    .header .mobile .mobile-container .navigation-container {
      overflow: auto;
      scrollbar-width: thin;
      height: calc(100% - 200px);
      margin-top: 46px;
      transition: all 0.3s; }
      .header .mobile .mobile-container .navigation-container.animate-out {
        transform: translateX(-110%); }
    .header .mobile .mobile-container .action-menu {
      margin-top: 46px; }
    .header .mobile .mobile-container .sub-menu {
      background-color: #24405b;
      position: absolute;
      overflow: auto;
      scrollbar-width: thin;
      bottom: 120px;
      animation: animateMobileSubMenuIn 0.3s ease-out 0s 1 forwards; }
      .header .mobile .mobile-container .sub-menu.animate-out {
        animation: animateMobileSubMenuOut 0.3s ease-out 0s 1 forwards; }
    .header .mobile .mobile-container .sub-menu-heading {
      font-weight: bold;
      font-size: 20px;
      line-height: 1.33;
      letter-spacing: -0.6px;
      color: #74cecd;
      margin-bottom: 46px; }
      @media only screen and (min-width: 768px) {
        .header .mobile .mobile-container .sub-menu-heading {
          font-size: 30px; } }
    .header .mobile .mobile-container li + li {
      margin-top: 46px; }
    .header .mobile .mobile-container li ul {
      display: none; }
    .header .mobile .mobile-container li a {
      font-weight: bold;
      font-size: 20px;
      line-height: 1.33;
      letter-spacing: -0.6px;
      color: #ffffff;
      text-decoration: none; }
      @media only screen and (min-width: 768px) {
        .header .mobile .mobile-container li a {
          font-size: 30px; } }
      .header .mobile .mobile-container li a::before {
        content: url("/design/build/demant2019/assets/arrow-clean.svg");
        display: inline-block;
        padding-right: 28px; }
        @media only screen and (min-width: 768px) {
          .header .mobile .mobile-container li a::before {
            padding-right: 28px; } }
    .header .mobile .mobile-container .actions {
      position: absolute;
      bottom: 30px;
      left: 30px;
      right: 30px;
      display: flex;
      align-items: center; }
    .header .mobile .mobile-container .search {
      flex-grow: 1;
      padding-left: 14px;
      line-height: 0;
      font-size: 0;
      color: transparent; }
      @media only screen and (min-width: 768px) {
        .header .mobile .mobile-container .search {
          padding-left: 14px; } }
      .header .mobile .mobile-container .search:before {
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        font-feature-settings: "liga";
        -webkit-font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
        content: "search";
        color: #ffffff;
        font-size: 33px; }
    .header .mobile .mobile-container .back {
      position: relative;
      display: flex;
      align-items: center;
      opacity: 0;
      z-index: 3;
      margin-right: -80px;
      transition: all 0.3s ease-out; }
      .header .mobile .mobile-container .back.animate-in {
        opacity: 1;
        margin-right: 0; }
    .header .mobile .mobile-container .close {
      position: relative;
      z-index: 4;
      display: flex;
      margin-left: 17px; }

.header .scrolling-down .mobile {
  top: -60px; }

@keyframes animateMobileSubMenuIn {
  from {
    left: 100%;
    right: -100%; }
  to {
    left: 30px;
    right: 30px; } }

@keyframes animateMobileSubMenuOut {
  from {
    left: 30px;
    right: 30px; }
  to {
    left: 100%;
    right: -100%; } }

::selection {
  background: #edb833; }

.header .desktop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  min-height: 87px;
  transition: all 0.5s ease-out;
  z-index: 1000; }
  @media only screen and (min-width: 1400px) {
    .header .desktop {
      min-height: 120px; } }
  .header .desktop ul {
    display: flex; }
  .header .desktop a {
    color: #ffffff;
    text-decoration: none; }
    .header .desktop a:hover, .header .desktop a.active {
      color: #74cecd; }
  .header .desktop li + li {
    margin-left: 35px; }
    @media only screen and (min-width: 1400px) {
      .header .desktop li + li {
        margin-left: 50px; } }
  .header .desktop .sub-menu ul {
    padding: 0 20px; }
    .header .desktop .sub-menu ul li {
      text-align: center; }
      .header .desktop .sub-menu ul li + li {
        margin-left: 20px; }
        @media only screen and (min-width: 1200px) {
          .header .desktop .sub-menu ul li + li {
            margin-left: 50px; } }
  .header .desktop .main-menu-container {
    background-color: #24405b;
    position: relative;
    min-height: 87px;
    box-shadow: 0 -9px 20px 9px #000000;
    z-index: 2;
    transition: all 0.5s ease-out; }
    @media only screen and (min-width: 1400px) {
      .header .desktop .main-menu-container {
        min-height: 120px; } }
    .header .desktop .main-menu-container .search-field-spot {
      background-color: rgba(0, 0, 0, 0.7);
      height: 100%;
      position: fixed;
      width: 100%;
      z-index: 1; }
      .header .desktop .main-menu-container .search-field-spot .container {
        margin: 20% auto;
        max-width: 700px;
        width: 50%; }
        .header .desktop .main-menu-container .search-field-spot .container .search-field {
          background-color: transparent;
          display: flex;
          flex-direction: row;
          padding: 8px;
          width: 100%;
          border-bottom: 1px solid #ffffff; }
          .header .desktop .main-menu-container .search-field-spot .container .search-field:before {
            font-family: "Material Icons";
            font-weight: normal;
            font-style: normal;
            font-size: 24px;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            font-feature-settings: "liga";
            -webkit-font-feature-settings: "liga";
            -webkit-font-smoothing: antialiased;
            content: "search";
            color: #ffffff;
            font-size: 38px;
            margin-right: 8px; }
          .header .desktop .main-menu-container .search-field-spot .container .search-field input[type="text"] {
            border: 0;
            font-size: 30px;
            flex-grow: 2;
            width: 100%;
            background-color: transparent;
            color: #ffffff; }
          .header .desktop .main-menu-container .search-field-spot .container .search-field input[type="text"]:focus {
            outline: none; }
          .header .desktop .main-menu-container .search-field-spot .container .search-field input[type="text"]::placeholder {
            color: #f4f4f4; }
          .header .desktop .main-menu-container .search-field-spot .container .search-field .search-icon {
            font-family: "Material Icons";
            font-weight: normal;
            font-style: normal;
            font-size: 24px;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            font-feature-settings: "liga";
            -webkit-font-feature-settings: "liga";
            -webkit-font-smoothing: antialiased;
            cursor: pointer; }
        .header .desktop .main-menu-container .search-field-spot .container .search-suggestion-container {
          background-color: transparent;
          padding: 19px 8px 8px 8px;
          width: 100%; }
          .header .desktop .main-menu-container .search-field-spot .container .search-suggestion-container .search-suggestion {
            cursor: pointer;
            color: #f4f4f4;
            font-size: 20px;
            font-weight: bold;
            padding: 5px 0; }
            .header .desktop .main-menu-container .search-field-spot .container .search-suggestion-container .search-suggestion:hover {
              text-decoration: underline; }
            .header .desktop .main-menu-container .search-field-spot .container .search-suggestion-container .search-suggestion:hover,
            .header .desktop .main-menu-container .search-field-spot .container .search-suggestion-container .search-suggestion:hover p:before {
              color: #74cecd; }
            .header .desktop .main-menu-container .search-field-spot .container .search-suggestion-container .search-suggestion p:before {
              font-family: "Material Icons";
              font-weight: normal;
              font-style: normal;
              font-size: 24px;
              line-height: 1;
              letter-spacing: normal;
              text-transform: none;
              display: inline-block;
              white-space: nowrap;
              word-wrap: normal;
              direction: ltr;
              font-feature-settings: "liga";
              -webkit-font-feature-settings: "liga";
              -webkit-font-smoothing: antialiased;
              content: "keyboard_arrow_right";
              color: #ffffff;
              font-size: 32px;
              margin-right: 8px;
              vertical-align: middle; }
    .header .desktop .main-menu-container .logo-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 87px; }
      @media only screen and (min-width: 1400px) {
        .header .desktop .main-menu-container .logo-container {
          height: 120px; } }
      .header .desktop .main-menu-container .logo-container .logo {
        position: relative;
        margin-left: 30px;
        margin-right: 30px;
        height: 87px;
        transition: all 0.5s ease-out; }
        @media only screen and (min-width: 768px) {
          .header .desktop .main-menu-container .logo-container .logo {
            margin-left: auto;
            margin-right: auto;
            width: 86%;
            max-width: 1200px; }
            .header .desktop .main-menu-container .logo-container .logo.full-width {
              width: 100%;
              max-width: 1600px; }
            .header .desktop .main-menu-container .logo-container .logo.regular {
              width: 86%;
              max-width: 1200px; }
            .header .desktop .main-menu-container .logo-container .logo.slim {
              width: 47.2%;
              max-width: 660px; } }
        @media only screen and (min-width: 1400px) {
          .header .desktop .main-menu-container .logo-container .logo {
            height: 120px; } }
        .header .desktop .main-menu-container .logo-container .logo a {
          display: inline-block;
          position: absolute;
          width: 160px;
          height: 87px;
          left: 0;
          bottom: 0;
          z-index: 1; }
          @media only screen and (min-width: 1400px) {
            .header .desktop .main-menu-container .logo-container .logo a {
              width: 160px;
              height: 120px; } }
        .header .desktop .main-menu-container .logo-container .logo img {
          position: absolute;
          width: 100px;
          left: 0;
          top: 14px;
          transition: all 0.5s ease-out; }
          @media only screen and (min-width: 1200px) {
            .header .desktop .main-menu-container .logo-container .logo img {
              top: auto;
              width: 115px;
              bottom: 19px; } }
          @media only screen and (min-width: 1400px) {
            .header .desktop .main-menu-container .logo-container .logo img {
              width: 160px;
              bottom: 23px; } }
    .header .desktop .main-menu-container .search-container {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #dcdcd6;
      width: 87px;
      height: 87px;
      transition: all 0.5s ease-out; }
      @media only screen and (min-width: 1400px) {
        .header .desktop .main-menu-container .search-container {
          width: 120px;
          height: 120px; } }
      .header .desktop .main-menu-container .search-container a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        line-height: 0;
        font-size: 0;
        color: transparent;
        text-align: center; }
        .header .desktop .main-menu-container .search-container a:before {
          font-family: "Material Icons";
          font-weight: normal;
          font-style: normal;
          font-size: 24px;
          line-height: 1;
          letter-spacing: normal;
          text-transform: none;
          display: inline-block;
          white-space: nowrap;
          word-wrap: normal;
          direction: ltr;
          font-feature-settings: "liga";
          -webkit-font-feature-settings: "liga";
          -webkit-font-smoothing: antialiased;
          content: "search";
          color: #24405b;
          font-size: 45px;
          margin: 0 auto; }
    .header .desktop .main-menu-container .main-menu,
    .header .desktop .main-menu-container .action-menu {
      position: absolute;
      top: 0;
      left: 0;
      right: 117px;
      display: inline-block;
      transition: all 0.5s ease-out; }
      @media only screen and (min-width: 1400px) {
        .header .desktop .main-menu-container .main-menu,
        .header .desktop .main-menu-container .action-menu {
          right: 150px; } }
      .header .desktop .main-menu-container .main-menu ul,
      .header .desktop .main-menu-container .action-menu ul {
        position: absolute;
        right: 0; }
      .header .desktop .main-menu-container .main-menu a,
      .header .desktop .main-menu-container .action-menu a {
        font-weight: 600;
        font-size: 12px;
        line-height: 1.4;
        line-height: 1;
        display: inline-block; }
        @media only screen and (min-width: 768px) {
          .header .desktop .main-menu-container .main-menu a,
          .header .desktop .main-menu-container .action-menu a {
            font-size: 15px; } }
    .header .desktop .main-menu-container .action-menu ul {
      top: 14px; }
      @media only screen and (min-width: 1400px) {
        .header .desktop .main-menu-container .action-menu ul {
          top: 30px; } }
      .header .desktop .main-menu-container .action-menu ul a {
        font-size: 18px; }
    .header .desktop .main-menu-container .main-menu {
      height: 87px; }
      @media only screen and (min-width: 1400px) {
        .header .desktop .main-menu-container .main-menu {
          height: 120px; } }
      .header .desktop .main-menu-container .main-menu ul {
        bottom: 14px; }
        @media only screen and (min-width: 1400px) {
          .header .desktop .main-menu-container .main-menu ul {
            bottom: 18px; } }
      .header .desktop .main-menu-container .main-menu li.level1 ul {
        display: none; }
      .header .desktop .main-menu-container .main-menu a {
        font-size: 20px; }
        .header .desktop .main-menu-container .main-menu a::after {
          content: "";
          display: inline-block;
          box-sizing: border-box;
          border-style: none solid solid none;
          width: 12px;
          height: 12px;
          margin-left: 0.5em;
          margin-bottom: 0.2em;
          transform: rotate(45deg); }
  .header .desktop .sub-menu {
    background-color: #24405b;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    min-height: 80px;
    display: flex;
    align-items: center;
    animation: animateSubMenuInXsDesktop 0.3s ease-out 0s 1 forwards;
    box-shadow: 0 -9px 20px 9px #000000; }
    @media only screen and (min-width: 1400px) {
      .header .desktop .sub-menu {
        animation: animateSubMenuIn 0.3s ease-out 0s 1 forwards; } }
    .header .desktop .sub-menu ul {
      margin: auto; }
      @media all and (-ms-high-contrast: none) {
        .header .desktop .sub-menu ul {
          margin-top: 2em; } }
    .header .desktop .sub-menu a {
      font-weight: 600;
      font-size: 12px;
      line-height: 1.4;
      font-size: 20px;
      line-height: 1;
      display: inline-block; }
      @media only screen and (min-width: 768px) {
        .header .desktop .sub-menu a {
          font-size: 15px; } }
    .header .desktop .sub-menu.animate-out {
      animation: animateSubMenuOutXsDesktop 0.3s ease-out 0s 1 forwards; }
      @media only screen and (min-width: 1400px) {
        .header .desktop .sub-menu.animate-out {
          animation: animateSubMenuOut 0.3s ease-out 0s 1 forwards; } }

.header .scrolling-down .desktop {
  min-height: 70px; }
  .header .scrolling-down .desktop .main-menu-container {
    min-height: 70px; }
  .header .scrolling-down .desktop .action-menu {
    top: -60px; }
  .header .scrolling-down .desktop .main-menu {
    height: 70px; }
  .header .scrolling-down .desktop .search-container {
    height: 70px; }
  .header .scrolling-down .desktop .logo-container,
  .header .scrolling-down .desktop .logo-container .logo {
    height: 70px; }
    .header .scrolling-down .desktop .logo-container img,
    .header .scrolling-down .desktop .logo-container a,
    .header .scrolling-down .desktop .logo-container .logo img,
    .header .scrolling-down .desktop .logo-container .logo a {
      width: 80px; }
      @media only screen and (min-width: 1200px) {
        .header .scrolling-down .desktop .logo-container img,
        .header .scrolling-down .desktop .logo-container a,
        .header .scrolling-down .desktop .logo-container .logo img,
        .header .scrolling-down .desktop .logo-container .logo a {
          width: 100px; } }
    .header .scrolling-down .desktop .logo-container img,
    .header .scrolling-down .desktop .logo-container .logo img {
      top: 0; }
      @media only screen and (min-width: 1200px) {
        .header .scrolling-down .desktop .logo-container img,
        .header .scrolling-down .desktop .logo-container .logo img {
          top: auto; } }
  .header .scrolling-down .desktop .sub-menu {
    animation: animateSubMenuInScrolled 0.3s ease-out 0s 1 forwards; }
    .header .scrolling-down .desktop .sub-menu.animate-out {
      animation: animateSubMenuOutScrolled 0.3s ease-out 0s 1 forwards; }

@keyframes animateSubMenuIn {
  from {
    padding-top: 0; }
  to {
    padding-top: 120px; } }

@keyframes animateSubMenuInXsDesktop {
  from {
    padding-top: 0; }
  to {
    padding-top: 87px; } }

@keyframes animateSubMenuOut {
  from {
    padding-top: 120px; }
  to {
    padding-top: 0; } }

@keyframes animateSubMenuOutXsDesktop {
  from {
    padding-top: 87px; }
  to {
    padding-top: 0; } }

@keyframes animateSubMenuInScrolled {
  from {
    padding-top: 0; }
  to {
    padding-top: 70px; } }

@keyframes animateSubMenuOutScrolled {
  from {
    padding-top: 70px; }
  to {
    padding-top: 0; } }

.header-graduate-blog .banner img {
  width: 100%;
  height: auto;
  display: block; }

.header-graduate-blog .banner .header-links {
  position: absolute;
  left: 0;
  right: 0;
  top: 0; }
  @media only screen and (min-width: 992px) {
    .header-graduate-blog .banner .header-links {
      top: 50px;
      margin-left: 30px;
      margin-right: 30px; } }
  @media only screen and (min-width: 992px) and (min-width: 768px) {
    .header-graduate-blog .banner .header-links {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      .header-graduate-blog .banner .header-links.full-width {
        width: 100%;
        max-width: 1600px; }
      .header-graduate-blog .banner .header-links.regular {
        width: 86%;
        max-width: 1200px; }
      .header-graduate-blog .banner .header-links.slim {
        width: 47.2%;
        max-width: 660px; } }
  .header-graduate-blog .banner .header-links .header-links-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    background-color: rgba(255, 255, 255, 0.5); }
    @media only screen and (min-width: 992px) {
      .header-graduate-blog .banner .header-links .header-links-container {
        background-color: transparent; } }

.header-graduate-blog .banner .root img {
  padding: 15px 25px;
  height: 24px;
  width: auto; }
  @media only screen and (min-width: 992px) {
    .header-graduate-blog .banner .root img {
      padidng: 0;
      height: initial; } }

.header-graduate-blog .banner .graduate-blog {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: -0.23px;
  color: #24405b;
  text-decoration: none;
  margin-top: 17px;
  margin-left: 25px; }
  @media only screen and (min-width: 768px) {
    .header-graduate-blog .banner .graduate-blog {
      font-size: 17px; } }
  @media only screen and (min-width: 992px) {
    .header-graduate-blog .banner .graduate-blog {
      font-weight: bold;
      font-size: 20px;
      line-height: 1.33;
      letter-spacing: -0.6px;
      font-weight: 300;
      letter-spacing: -1px;
      margin-top: 10px;
      margin-left: 0; } }
  @media only screen and (min-width: 992px) and (min-width: 768px) {
    .header-graduate-blog .banner .graduate-blog {
      font-size: 30px; } }

.header .mobile-graduate-blog button {
  background: transparent;
  border: 0;
  padding: 0; }

.header .mobile-graduate-blog .open {
  position: absolute;
  top: 8px;
  right: 12px;
  width: 40px;
  height: 40px;
  background-color: #3344f6;
  border-radius: 50%;
  transition: all 0.3s ease; }
  .header .mobile-graduate-blog .open span {
    position: absolute;
    left: 11px;
    width: 18px;
    height: 3px;
    background-color: #ffffff;
    transition: all 0.3s ease; }
    .header .mobile-graduate-blog .open span:nth-child(1) {
      top: 14px; }
    .header .mobile-graduate-blog .open span:nth-child(2) {
      top: 19px; }
    .header .mobile-graduate-blog .open span:nth-child(3) {
      top: 24px; }
  .header .mobile-graduate-blog .open.close {
    top: 15px;
    right: 20px;
    width: 51px;
    height: 51px; }
    .header .mobile-graduate-blog .open.close span {
      left: 13px;
      width: 24px; }
      .header .mobile-graduate-blog .open.close span:nth-child(1) {
        transform: rotate(-45deg) translate(-3px, 4px);
        top: 19px; }
      .header .mobile-graduate-blog .open.close span:nth-child(2) {
        opacity: 0; }
      .header .mobile-graduate-blog .open.close span:nth-child(3) {
        transform: rotate(45deg) translate(-3px, -4px);
        top: 29px; }
  .header .mobile-graduate-blog .open.upper {
    background-color: rgba(255, 255, 255, 0.75); }

.header .mobile-graduate-blog .mobile-container {
  background-color: #24405b;
  padding: 25px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(100%);
  transition: transform 0.3s; }
  @media only screen and (min-width: 768px) {
    .header .mobile-graduate-blog .mobile-container {
      padding: 25px; } }
  .header .mobile-graduate-blog .mobile-container.active {
    transform: none; }
  .header .mobile-graduate-blog .mobile-container .logo {
    width: 40%; }
  .header .mobile-graduate-blog .mobile-container .search-field-spot {
    background-color: #24405b;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 10%;
    z-index: 1; }
    .header .mobile-graduate-blog .mobile-container .search-field-spot .container {
      padding: 25px; }
      .header .mobile-graduate-blog .mobile-container .search-field-spot .container .search-field {
        border-bottom: 1px solid #ffffff;
        display: flex;
        flex-direction: row;
        padding: 8px 0;
        margin-bottom: 8px; }
        .header .mobile-graduate-blog .mobile-container .search-field-spot .container .search-field input[type="text"] {
          background: none;
          border: none;
          color: #ffffff;
          font-size: 24px;
          flex-grow: 2;
          width: 100%; }
        .header .mobile-graduate-blog .mobile-container .search-field-spot .container .search-field input[type="text"]::placeholder {
          color: #f4f4f4; }
        .header .mobile-graduate-blog .mobile-container .search-field-spot .container .search-field input[type="text"]:focus {
          outline: none; }
        .header .mobile-graduate-blog .mobile-container .search-field-spot .container .search-field .search-icon {
          font-family: "Material Icons";
          font-weight: normal;
          font-style: normal;
          font-size: 24px;
          line-height: 1;
          letter-spacing: normal;
          text-transform: none;
          display: inline-block;
          white-space: nowrap;
          word-wrap: normal;
          direction: ltr;
          font-feature-settings: "liga";
          -webkit-font-feature-settings: "liga";
          -webkit-font-smoothing: antialiased;
          cursor: pointer; }
      .header .mobile-graduate-blog .mobile-container .search-field-spot .container .search-suggestion-container {
        overflow-y: auto; }
        .header .mobile-graduate-blog .mobile-container .search-field-spot .container .search-suggestion-container .search-suggestion {
          cursor: pointer;
          color: #ffffff;
          font-size: 20px;
          padding: 5px 0; }
  .header .mobile-graduate-blog .mobile-container .navigation-container {
    overflow: auto;
    scrollbar-width: thin;
    height: calc(100% - 200px);
    margin-top: 46px;
    transition: all 0.3s; }
    .header .mobile-graduate-blog .mobile-container .navigation-container.animate-out {
      transform: translateX(-110%); }
  .header .mobile-graduate-blog .mobile-container .action-menu {
    margin-top: 46px; }
  .header .mobile-graduate-blog .mobile-container .sub-menu {
    background-color: #24405b;
    position: absolute;
    overflow: auto;
    scrollbar-width: thin;
    bottom: 120px;
    animation: animateMobileSubMenuIn 0.3s ease-out 0s 1 forwards; }
    .header .mobile-graduate-blog .mobile-container .sub-menu.animate-out {
      animation: animateMobileSubMenuOut 0.3s ease-out 0s 1 forwards; }
  .header .mobile-graduate-blog .mobile-container .sub-menu-heading {
    font-weight: bold;
    font-size: 20px;
    line-height: 1.33;
    letter-spacing: -0.6px;
    color: #74cecd;
    margin-bottom: 46px; }
    @media only screen and (min-width: 768px) {
      .header .mobile-graduate-blog .mobile-container .sub-menu-heading {
        font-size: 30px; } }
  .header .mobile-graduate-blog .mobile-container li + li {
    margin-top: 46px; }
  .header .mobile-graduate-blog .mobile-container li ul {
    display: none; }
  .header .mobile-graduate-blog .mobile-container li a {
    font-weight: bold;
    font-size: 20px;
    line-height: 1.33;
    letter-spacing: -0.6px;
    color: #ffffff;
    text-decoration: none; }
    @media only screen and (min-width: 768px) {
      .header .mobile-graduate-blog .mobile-container li a {
        font-size: 30px; } }
    .header .mobile-graduate-blog .mobile-container li a::before {
      content: url("/design/build/demant2019/assets/arrow-clean.svg");
      display: inline-block;
      padding-right: 28px; }
      @media only screen and (min-width: 768px) {
        .header .mobile-graduate-blog .mobile-container li a::before {
          padding-right: 28px; } }
  .header .mobile-graduate-blog .mobile-container .actions {
    position: absolute;
    bottom: 30px;
    left: 30px;
    right: 30px;
    display: flex;
    align-items: center; }
  .header .mobile-graduate-blog .mobile-container .search {
    flex-grow: 1;
    padding-left: 14px;
    line-height: 0;
    font-size: 0;
    color: transparent; }
    @media only screen and (min-width: 768px) {
      .header .mobile-graduate-blog .mobile-container .search {
        padding-left: 14px; } }
    .header .mobile-graduate-blog .mobile-container .search:before {
      font-family: "Material Icons";
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      font-feature-settings: "liga";
      -webkit-font-feature-settings: "liga";
      -webkit-font-smoothing: antialiased;
      content: "search";
      color: #ffffff;
      font-size: 33px; }
  .header .mobile-graduate-blog .mobile-container .back {
    position: relative;
    display: flex;
    align-items: center;
    opacity: 0;
    z-index: 3;
    margin-right: -80px;
    transition: all 0.3s ease-out; }
    .header .mobile-graduate-blog .mobile-container .back.animate-in {
      opacity: 1;
      margin-right: 0; }
  .header .mobile-graduate-blog .mobile-container .close {
    position: relative;
    z-index: 4;
    display: flex;
    margin-left: 17px; }

.header .desktop-graduate-blog .main-menu-container {
  background-color: #24405b; }
  .header .desktop-graduate-blog .main-menu-container .main-menu {
    margin-left: 30px;
    margin-right: 30px; }
    @media only screen and (min-width: 768px) {
      .header .desktop-graduate-blog .main-menu-container .main-menu {
        margin-left: auto;
        margin-right: auto;
        width: 47.2%;
        max-width: 660px; }
        .header .desktop-graduate-blog .main-menu-container .main-menu.full-width {
          width: 100%;
          max-width: 1600px; }
        .header .desktop-graduate-blog .main-menu-container .main-menu.regular {
          width: 86%;
          max-width: 1200px; }
        .header .desktop-graduate-blog .main-menu-container .main-menu.slim {
          width: 47.2%;
          max-width: 660px; } }
    .header .desktop-graduate-blog .main-menu-container .main-menu > ul {
      display: flex;
      justify-content: space-between; }
      .header .desktop-graduate-blog .main-menu-container .main-menu > ul > li {
        margin: 20px 0; }
        .header .desktop-graduate-blog .main-menu-container .main-menu > ul > li a {
          color: #ffffff;
          text-decoration: none;
          font-size: 15px;
          font-weight: 600;
          line-height: 1.29;
          letter-spacing: -0.23px; }
          @media only screen and (min-width: 768px) {
            .header .desktop-graduate-blog .main-menu-container .main-menu > ul > li a {
              font-size: 17px; } }

html[lang="pl-pl"] .header .search-container {
  display: none; }

html[lang="pl-pl"] .header .desktop .main-menu-container .main-menu a::after {
  display: none; }

::selection {
  background: #edb833; }

div[class*=" circle-"] svg,
div[class*=" circle-"] .svg {
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: 50% 50%; }
  @media only screen and (min-width: 992px) {
    div[class*=" circle-"] svg,
    div[class*=" circle-"] .svg {
      animation-name: rotate; } }
  div[class*=" circle-"] svg ellipse,
  div[class*=" circle-"] .svg ellipse {
    fill: transparent; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.circle-charcoal-blue-v2 ellipse {
  fill-rule: evenodd; }
  .circle-charcoal-blue-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-charcoal-blue-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-charcoal-blue-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-charcoal-blue-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-charcoal-blue-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-multi-colour-earth-v2 ellipse {
  fill-rule: evenodd; }
  .circle-multi-colour-earth-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-multi-colour-earth-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-multi-colour-earth-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-multi-colour-earth-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-multi-colour-earth-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-multi-colour-fresh-v2 ellipse {
  fill-rule: evenodd; }
  .circle-multi-colour-fresh-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-multi-colour-fresh-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-multi-colour-fresh-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-multi-colour-fresh-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-multi-colour-fresh-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-multi-colour-gradient_1-v2 ellipse {
  fill-rule: evenodd; }
  .circle-multi-colour-gradient_1-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-multi-colour-gradient_1-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-multi-colour-gradient_1-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-multi-colour-gradient_1-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-multi-colour-gradient_1-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-multi-colour-gradient_3-v2 ellipse {
  fill-rule: evenodd; }
  .circle-multi-colour-gradient_3-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-multi-colour-gradient_3-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-multi-colour-gradient_3-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-multi-colour-gradient_3-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-multi-colour-gradient_3-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-multi-colour-gradient_4-v2 ellipse {
  fill-rule: evenodd; }
  .circle-multi-colour-gradient_4-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-multi-colour-gradient_4-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-multi-colour-gradient_4-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-multi-colour-gradient_4-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-multi-colour-gradient_4-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-multi-colour-v2 ellipse {
  fill-rule: evenodd; }
  .circle-multi-colour-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-multi-colour-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-multi-colour-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-multi-colour-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-multi-colour-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-pine-green-v2 ellipse {
  fill-rule: evenodd; }
  .circle-pine-green-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-pine-green-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-pine-green-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-pine-green-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-pine-green-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-seafoam-green-v2 ellipse {
  fill-rule: evenodd; }
  .circle-seafoam-green-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-seafoam-green-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-seafoam-green-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-seafoam-green-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-seafoam-green-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-sky-blue-v2 ellipse {
  fill-rule: evenodd; }
  .circle-sky-blue-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-sky-blue-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-sky-blue-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-sky-blue-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-sky-blue-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-walnut-brown-v2 ellipse {
  fill-rule: evenodd; }
  .circle-walnut-brown-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-walnut-brown-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-walnut-brown-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-walnut-brown-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-walnut-brown-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-white-v2 ellipse {
  fill-rule: evenodd; }
  .circle-white-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-white-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-white-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-white-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-white-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-burst-yellow ellipse {
  fill-rule: evenodd; }
  .circle-burst-yellow ellipse:nth-child(1) {
    fill: #edb833; }
  .circle-burst-yellow ellipse:nth-child(2) {
    fill: #edb833;
    mix-blend-mode: multiply; }
  .circle-burst-yellow ellipse:nth-child(3) {
    fill: #edb833;
    mix-blend-mode: multiply; }

.circle-burst-yellow .svg {
  background-image: url("/design/build/demant2019/assets/circle-burst-yellow.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-charcoal-blue ellipse {
  fill-rule: evenodd; }
  .circle-charcoal-blue ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-charcoal-blue ellipse:nth-child(2) {
    fill: #5b7085;
    mix-blend-mode: normal; }
  .circle-charcoal-blue ellipse:nth-child(3) {
    fill: rgba(36, 64, 91, 0.5);
    mix-blend-mode: multiply; }

.circle-charcoal-blue .svg {
  background-image: url("/design/build/demant2019/assets/circle-charcoal-blue.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-electric-seafoam ellipse {
  fill-rule: evenodd; }
  .circle-electric-seafoam ellipse:nth-child(1) {
    fill: black; }
  .circle-electric-seafoam ellipse:nth-child(2) {
    fill: black;
    mix-blend-mode: multiply; }
  .circle-electric-seafoam ellipse:nth-child(3) {
    fill: black;
    mix-blend-mode: multiply; }

.circle-electric-seafoam .svg {
  background-image: url("/design/build/demant2019/assets/circle-electric-seafoam.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-electric-seafoam-info ellipse {
  fill-rule: evenodd; }
  .circle-electric-seafoam-info ellipse:nth-child(1) {
    fill: black; }
  .circle-electric-seafoam-info ellipse:nth-child(2) {
    fill: black;
    mix-blend-mode: multiply; }
  .circle-electric-seafoam-info ellipse:nth-child(3) {
    fill: black;
    mix-blend-mode: multiply; }

.circle-electric-seafoam-info .svg {
  background-image: url("/design/build/demant2019/assets/circle-electric-seafoam-info.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-seafoam-gray ellipse {
  fill-rule: evenodd; }
  .circle-seafoam-gray ellipse:nth-child(1) {
    fill: black; }
  .circle-seafoam-gray ellipse:nth-child(2) {
    fill: black;
    mix-blend-mode: multiply; }
  .circle-seafoam-gray ellipse:nth-child(3) {
    fill: black;
    mix-blend-mode: multiply; }

.circle-seafoam-gray .svg {
  background-image: url("/design/build/demant2019/assets/circle-seafoam-gray.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-seafoam-gray-info ellipse {
  fill-rule: evenodd; }
  .circle-seafoam-gray-info ellipse:nth-child(1) {
    fill: black; }
  .circle-seafoam-gray-info ellipse:nth-child(2) {
    fill: black;
    mix-blend-mode: multiply; }
  .circle-seafoam-gray-info ellipse:nth-child(3) {
    fill: black;
    mix-blend-mode: multiply; }

.circle-seafoam-gray-info .svg {
  background-image: url("/design/build/demant2019/assets/circle-seafoam-gray-info.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-seafoam-green ellipse {
  fill-rule: evenodd; }
  .circle-seafoam-green ellipse:nth-child(1) {
    fill: #74cecd; }
  .circle-seafoam-green ellipse:nth-child(2) {
    fill: #74cecd;
    mix-blend-mode: multiply; }
  .circle-seafoam-green ellipse:nth-child(3) {
    fill: #74cecd;
    mix-blend-mode: multiply; }

.circle-seafoam-green .svg {
  background-image: url("/design/build/demant2019/assets/circle-seafoam-green.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-warm-gray ellipse {
  fill-rule: evenodd; }
  .circle-warm-gray ellipse:nth-child(1) {
    fill: #b9b9ad; }
  .circle-warm-gray ellipse:nth-child(2) {
    fill: rgba(185, 185, 173, 0.5);
    mix-blend-mode: multiply; }
  .circle-warm-gray ellipse:nth-child(3) {
    fill: rgba(185, 185, 173, 0.75);
    mix-blend-mode: multiply; }

.circle-warm-gray .svg {
  background-image: url("/design/build/demant2019/assets/circle-warm-gray.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-white ellipse {
  fill-rule: evenodd; }
  .circle-white ellipse:nth-child(1) {
    fill: rgba(255, 255, 255, 0.75); }
  .circle-white ellipse:nth-child(2) {
    fill: rgba(255, 255, 255, 0.5);
    mix-blend-mode: normal; }
  .circle-white ellipse:nth-child(3) {
    fill: rgba(255, 255, 255, 0.5);
    mix-blend-mode: normal; }

.circle-white .svg {
  background-image: url("/design/build/demant2019/assets/circle-white.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.content .component.two-section-grid > .component-content [class^="section-"] .component.image-spot {
  width: auto;
  margin-left: auto;
  margin-right: auto; }
  @media all and (-ms-high-contrast: none) {
    .content .component.two-section-grid > .component-content [class^="section-"] .component.image-spot .component-content .image-spot-element {
      margin: auto; } }

.language-selector {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 299; }
  .desktop .language-selector {
    top: 27px;
    right: 150px; }
  .mobile .language-selector {
    top: 25px;
    right: 25px; }
  .language-selector__toggle-button {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: "liga";
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
    margin-bottom: 0.5rem;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: inherit;
    color: white; }
    .mobile .language-selector__toggle-button {
      font-size: 36px; }
  .language-selector__list {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-end;
    background-color: #dcdcd6;
    padding: 0.5rem 1rem;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5); }
    .language-selector__list:after, .language-selector__list:before {
      bottom: 100%;
      right: 7px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none; }
      .mobile .language-selector__list:after, .mobile .language-selector__list:before {
        right: 12px; }
    .language-selector__list:after {
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: #dcdcd6;
      border-width: 5px;
      margin-left: -5px; }
    .language-selector__list:before {
      border-color: rgba(194, 225, 245, 0);
      border-bottom-color: #dcdcd6;
      border-width: 5px;
      margin-left: -5px; }
  .language-selector__item {
    margin: 0 0 0.5rem !important; }
    .language-selector__item:last-child {
      margin: 0 !important; }
  .language-selector__link {
    color: #24405b !important; }
    .language-selector__link:hover {
      color: #5b7085 !important; }
    .language-selector__link::before {
      display: none !important; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
#social-sharing .mobile-section {
  margin-left: 30px;
  margin-right: 30px; }
  @media only screen and (min-width: 768px) {
    #social-sharing .mobile-section {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      #social-sharing .mobile-section.full-width {
        width: 100%;
        max-width: 1600px; }
      #social-sharing .mobile-section.regular {
        width: 86%;
        max-width: 1200px; }
      #social-sharing .mobile-section.slim {
        width: 47.2%;
        max-width: 660px; } }

#social-sharing .desktop-section {
  margin-left: 30px;
  margin-right: 30px; }
  @media only screen and (min-width: 768px) {
    #social-sharing .desktop-section {
      margin-left: auto;
      margin-right: auto;
      width: 47.2%;
      max-width: 660px; }
      #social-sharing .desktop-section.full-width {
        width: 100%;
        max-width: 1600px; }
      #social-sharing .desktop-section.regular {
        width: 86%;
        max-width: 1200px; }
      #social-sharing .desktop-section.slim {
        width: 47.2%;
        max-width: 660px; } }

#social-sharing .service-elements-header {
  display: block;
  font-weight: bold;
  font-size: 15px;
  line-height: 1.35; }
  @media only screen and (min-width: 768px) {
    #social-sharing .service-elements-header {
      font-size: 17px; } }

#social-sharing .service-element a {
  display: inline-block;
  width: 40px;
  height: 40px;
  color: rgba(0, 0, 0, 0);
  margin-top: 20px;
  margin-right: 10px; }

#social-sharing .Facebook::before {
  content: "";
  display: inline-block;
  width: 40px;
  height: 40px;
  background-image: url("/design/build/demant2019/assets/social-facebook-circle-charcoal.svg");
  background-size: contain; }

#social-sharing .Twitter::before {
  content: "";
  display: inline-block;
  width: 40px;
  height: 40px;
  background-image: url("/design/build/demant2019/assets/social-twitter-circle-charcoal.svg");
  background-size: contain; }

#social-sharing .Linkedin::before {
  content: "";
  display: inline-block;
  width: 40px;
  height: 40px;
  background-image: url("/design/build/demant2019/assets/social-linked-circle-charcoal.svg");
  background-size: contain; }

/********************* global slider animations ******************/
#wrapper .slider-stack-wrapper {
  position: relative;
  height: 250px;
  overflow: hidden; }
  #wrapper .slider-stack-wrapper > .component.slider-stack {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    box-sizing: border-box; }
  #wrapper .slider-stack-wrapper > .slider-tabs {
    position: absolute;
    z-index: 1; }
    #wrapper .slider-stack-wrapper > .slider-tabs .slider-tab {
      display: inline-block;
      cursor: pointer;
      user-select: none; }
  #wrapper .slider-stack-wrapper > .slider-arrow-left,
  #wrapper .slider-stack-wrapper > .slider-arrow-right {
    position: absolute;
    z-index: 100;
    display: inline-block;
    cursor: pointer;
    user-select: none; }
  #wrapper .slider-stack-wrapper .slider-spinner {
    position: absolute;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }
    #wrapper .slider-stack-wrapper .slider-spinner:before {
      content: "";
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      /*background-color: rgba($black,.1);*/ }
    #wrapper .slider-stack-wrapper .slider-spinner:not(:required) {
      /* hide "loading..." text */
      font: 0/0 a;
      color: transparent;
      text-shadow: none;
      background-color: transparent;
      border: 0; }
    #wrapper .slider-stack-wrapper .slider-spinner:not(:required):after {
      content: "";
      display: block;
      font-size: 10px;
      width: 1em;
      height: 1em;
      margin-top: -0.5em;
      animation: spinner 1500ms infinite linear;
      border-radius: 0.5em; }
  #wrapper .slider-stack-wrapper .slider-arrow-left {
    left: 3rem;
    top: 50%;
    transform: translateY(-50%);
    background: #3344f6;
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center; }
    #wrapper .slider-stack-wrapper .slider-arrow-left::before {
      margin-right: 0.3rem;
      border-style: solid;
      border-width: 0.5rem 0.8rem 0.5rem 0;
      border-color: transparent #ffffff transparent transparent;
      content: "";
      display: inline-block;
      box-sizing: border-box; }
  #wrapper .slider-stack-wrapper .slider-arrow-right {
    right: 3rem;
    top: 50%;
    transform: translateY(-50%);
    background: #3344f6;
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center; }
    #wrapper .slider-stack-wrapper .slider-arrow-right::before {
      margin-left: 0.3rem;
      border-style: solid;
      border-width: 0.5rem 0 0.5rem 0.8rem;
      border-color: transparent transparent transparent #ffffff;
      content: "";
      display: inline-block;
      box-sizing: border-box; }
  #wrapper .slider-stack-wrapper .slider-tabs {
    bottom: 1rem;
    left: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: opacity 1.3s;
    transition: opacity 1.3s; }
    #wrapper .slider-stack-wrapper .slider-tabs .tabs-content {
      position: relative;
      width: 74rem;
      max-width: 90%;
      margin: 0 auto;
      text-align: center; }
    #wrapper .slider-stack-wrapper .slider-tabs .slider-tab {
      width: 0.5rem;
      height: 0.5rem;
      margin-left: 0.75rem;
      border-radius: 50%;
      background-color: #ffffff;
      -webkit-transition: background-color 0.2s;
      transition: background-color 0.2s;
      border: 0.09rem solid #24405b; }
      #wrapper .slider-stack-wrapper .slider-tabs .slider-tab.active-tab {
        background-color: #24405b; }

#wrapper > #content .slider-stack-wrapper > .component.component-ready.animate.slider-stack.going-out {
  opacity: 1;
  left: -100%;
  right: 100%;
  transition: all 1s linear; }

#wrapper > #content .slider-stack-wrapper > .component.component-ready.animate.slider-stack {
  opacity: 1;
  left: 100%;
  right: -100%;
  transition: none; }

#wrapper > #content .slider-stack-wrapper > .component.component-ready.animate.slider-stack.active-slide {
  opacity: 1;
  left: 0;
  right: 0;
  transition: all 1s linear; }

::selection {
  background: #edb833; }

.component.container .component-content {
  margin-left: 30px;
  margin-right: 30px; }
  @media only screen and (min-width: 768px) {
    .component.container .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      .component.container .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .component.container .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .component.container .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }

.component.container.dashboard > .component-content > .component {
  margin-bottom: 0; }
  .component.container.dashboard > .component-content > .component > .component-content {
    margin-left: 30px;
    margin-right: 30px;
    padding: 20px 0; }
    @media only screen and (min-width: 768px) {
      .component.container.dashboard > .component-content > .component > .component-content {
        margin-left: auto;
        margin-right: auto;
        width: 86%;
        max-width: 1200px; }
        .component.container.dashboard > .component-content > .component > .component-content.full-width {
          width: 100%;
          max-width: 1600px; }
        .component.container.dashboard > .component-content > .component > .component-content.regular {
          width: 86%;
          max-width: 1200px; }
        .component.container.dashboard > .component-content > .component > .component-content.slim {
          width: 47.2%;
          max-width: 660px; } }
    @media only screen and (min-width: 992px) {
      .component.container.dashboard > .component-content > .component > .component-content {
        padding: 30px 0; } }
  .component.container.dashboard > .component-content > .component:nth-child(1), .component.container.dashboard > .component-content > .component:nth-child(3) {
    background-color: #24405b; }
  .component.container.dashboard > .component-content > .component:nth-child(2) {
    background-color: #74cecd; }
  .component.container.dashboard > .component-content > .component:nth-child(4) {
    background-color: #dcdcd6; }

@media only screen and (min-width: 992px) {
  .component.container.dashboard > .component-content {
    display: flex;
    flex-wrap: wrap; }
    .component.container.dashboard > .component-content > .component {
      width: 50%; } }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

@keyframes in {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

@keyframes out {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

.component.accordion-spot {
  margin-top: 5rem; }
  .component.accordion-spot .component-content {
    position: relative; }
    .component.accordion-spot .component-content .accordion-spot-elements__expand-all,
    .component.accordion-spot .component-content .accordion-spot-element__title {
      cursor: pointer; }
    .component.accordion-spot .component-content .accordion-spot-elements__expand-all {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1; }
  .component.accordion-spot .accordion-spot-element__content {
    display: none; }
  .component.accordion-spot .accordion-spot-element.expand .accordion-spot-element__content {
    display: inherit; }
  .component.accordion-spot .accordion-spot-element.shrink {
    cursor: pointer; }

.component.accordion-spot.tabs .accordion-spot-tabs {
  display: flex;
  overflow-x: auto; }

.component.accordion-spot.tabs .accordion-spot-tab {
  cursor: pointer; }

.component.accordion-spot.tabs .accordion-spot-element + .accordion-spot-element {
  margin-top: 0; }

body.on-page-editor .component.accordion {
  min-height: 60px; }

/* REGULAR STYLING */
.component.accordion-spot .component-content {
  margin-left: 30px;
  margin-right: 30px; }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      .component.accordion-spot .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .component.accordion-spot .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .component.accordion-spot .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }

.component.accordion-spot.green-blue-gradient-bg {
  background-image: linear-gradient(to right, #74cbca, #3344f6);
  margin-top: 0; }
  .component.accordion-spot.green-blue-gradient-bg.first-repeating-background, .component.accordion-spot.green-blue-gradient-bg .repeating-background {
    margin-bottom: 0; }
  .component.accordion-spot.green-blue-gradient-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.accordion-spot.green-blue-gradient-bg.space-top {
        padding-top: 60px; } }
  .component.accordion-spot.green-blue-gradient-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.accordion-spot.green-blue-gradient-bg .component-content {
        padding: 30px 0; } }

.component.accordion-spot.charcoal-blue-bg {
  background-color: #24405b;
  margin-top: 0; }
  .component.accordion-spot.charcoal-blue-bg.first-repeating-background, .component.accordion-spot.charcoal-blue-bg .repeating-background {
    margin-bottom: 0; }
  .component.accordion-spot.charcoal-blue-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.accordion-spot.charcoal-blue-bg.space-top {
        padding-top: 60px; } }
  .component.accordion-spot.charcoal-blue-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.accordion-spot.charcoal-blue-bg .component-content {
        padding: 30px 0; } }

.component.accordion-spot.warm-gray-bg {
  background-color: #dcdcd6;
  margin-top: 0; }
  .component.accordion-spot.warm-gray-bg.first-repeating-background, .component.accordion-spot.warm-gray-bg .repeating-background {
    margin-bottom: 0; }
  .component.accordion-spot.warm-gray-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.accordion-spot.warm-gray-bg.space-top {
        padding-top: 60px; } }
  .component.accordion-spot.warm-gray-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.accordion-spot.warm-gray-bg .component-content {
        padding: 30px 0; } }

.component.accordion-spot.seafoam-green-50-bg {
  background-color: #badcdf;
  margin-top: 0; }
  .component.accordion-spot.seafoam-green-50-bg.first-repeating-background, .component.accordion-spot.seafoam-green-50-bg .repeating-background {
    margin-bottom: 0; }
  .component.accordion-spot.seafoam-green-50-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.accordion-spot.seafoam-green-50-bg.space-top {
        padding-top: 60px; } }
  .component.accordion-spot.seafoam-green-50-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.accordion-spot.seafoam-green-50-bg .component-content {
        padding: 30px 0; } }

.component.accordion-spot.sky-blue-75-bg {
  background-color: #bcdef7;
  margin-top: 0; }
  .component.accordion-spot.sky-blue-75-bg.first-repeating-background, .component.accordion-spot.sky-blue-75-bg .repeating-background {
    margin-bottom: 0; }
  .component.accordion-spot.sky-blue-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.accordion-spot.sky-blue-75-bg.space-top {
        padding-top: 60px; } }
  .component.accordion-spot.sky-blue-75-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.accordion-spot.sky-blue-75-bg .component-content {
        padding: 30px 0; } }

.component.accordion-spot.warm-grey-75-bg {
  background-color: #cacac1;
  margin-top: 0; }
  .component.accordion-spot.warm-grey-75-bg.first-repeating-background, .component.accordion-spot.warm-grey-75-bg .repeating-background {
    margin-bottom: 0; }
  .component.accordion-spot.warm-grey-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.accordion-spot.warm-grey-75-bg.space-top {
        padding-top: 60px; } }
  .component.accordion-spot.warm-grey-75-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.accordion-spot.warm-grey-75-bg .component-content {
        padding: 30px 0; } }

.component.accordion-spot.pine-green-75-bg {
  background-color: #609c9c;
  margin-top: 0; }
  .component.accordion-spot.pine-green-75-bg.first-repeating-background, .component.accordion-spot.pine-green-75-bg .repeating-background {
    margin-bottom: 0; }
  .component.accordion-spot.pine-green-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.accordion-spot.pine-green-75-bg.space-top {
        padding-top: 60px; } }
  .component.accordion-spot.pine-green-75-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.accordion-spot.pine-green-75-bg .component-content {
        padding: 30px 0; } }

.component.accordion-spot.burst-yellow-10-bg {
  background-color: #fcf7ea;
  margin-top: 0; }
  .component.accordion-spot.burst-yellow-10-bg.first-repeating-background, .component.accordion-spot.burst-yellow-10-bg .repeating-background {
    margin-bottom: 0; }
  .component.accordion-spot.burst-yellow-10-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.accordion-spot.burst-yellow-10-bg.space-top {
        padding-top: 60px; } }
  .component.accordion-spot.burst-yellow-10-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.accordion-spot.burst-yellow-10-bg .component-content {
        padding: 30px 0; } }

.component.accordion-spot.walnut-brown-bg {
  background-color: #6c4b42;
  margin-top: 0; }
  .component.accordion-spot.walnut-brown-bg.first-repeating-background, .component.accordion-spot.walnut-brown-bg .repeating-background {
    margin-bottom: 0; }
  .component.accordion-spot.walnut-brown-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.accordion-spot.walnut-brown-bg.space-top {
        padding-top: 60px; } }
  .component.accordion-spot.walnut-brown-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.accordion-spot.walnut-brown-bg .component-content {
        padding: 30px 0; } }

.component.accordion-spot .accordion-spot-elements__expand-all {
  font-weight: bold;
  font-size: 15px;
  line-height: 1.35;
  padding-right: 30px; }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-elements__expand-all {
      font-size: 17px; } }
  .component.accordion-spot .accordion-spot-elements__expand-all::after {
    position: absolute;
    top: 0.1em;
    right: 0;
    content: "";
    background: url("/design/build/demant2019/assets/expand-all.svg") no-repeat;
    background-size: contain;
    background-position: center;
    width: 1em;
    height: 1em;
    display: block;
    transform: rotate(90deg);
    transform-origin: 50% 50%; }
  .component.accordion-spot .accordion-spot-elements__expand-all.shrink-all::after {
    transform: rotate(-90deg); }

.component.accordion-spot .accordion-spot-element__title {
  font-weight: bold;
  font-size: 15px;
  line-height: 1.35;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 49px;
  border-bottom: 1px solid #979797;
  position: relative; }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__title {
      font-size: 17px; } }
  .component.accordion-spot .accordion-spot-element__title::before {
    position: absolute;
    left: 0.7em;
    content: "";
    background: url("/design/build/demant2019/assets/arrow-clean.svg") no-repeat;
    background-size: contain;
    background-position: center;
    width: 1em;
    height: 1em;
    display: block;
    transform: rotate(90deg);
    transform-origin: 50% 50%; }

.component.accordion-spot .accordion-spot-element.expand .accordion-spot-element__title::before {
  transform: rotate(-90deg); }

.component.accordion-spot .accordion-spot-element__content {
  padding: 10px 0px 20px 0px; }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__content {
      padding: 27px 30px 47px 49px; } }

.component.accordion-spot .accordion-spot-element__body ol,
.component.accordion-spot .accordion-spot-element__body ul {
  list-style: none;
  list-style-position: inside;
  padding-left: 12px; }

.component.accordion-spot .accordion-spot-element__body ol {
  counter-reset: demant-counter;
  padding-left: 50px; }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body ol {
      padding-left: 70px; } }

.component.accordion-spot .accordion-spot-element__body ol li,
.component.accordion-spot .accordion-spot-element__body ul li {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.4;
  position: relative;
  box-sizing: border-box; }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body ol li,
    .component.accordion-spot .accordion-spot-element__body ul li {
      font-size: 15px; } }

.component.accordion-spot .accordion-spot-element__body ol li {
  counter-increment: demant-counter;
  min-height: 35px;
  display: flex;
  align-items: center; }
  .component.accordion-spot .accordion-spot-element__body ol li + li {
    margin-top: 0.5rem; }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body ol li {
      min-height: 50px; } }

.component.accordion-spot .accordion-spot-element__body ol li::before,
.component.accordion-spot .accordion-spot-element__body ul li::before {
  content: "";
  display: inline-block;
  background-color: #24405b;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  left: -11px;
  top: 0.5em;
  width: 4px;
  height: 4px; }

.component.accordion-spot .accordion-spot-element__body ol li::before {
  content: counter(demant-counter);
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  left: -50px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%); }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body ol li::before {
      font-size: 18px; } }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body ol li::before {
      left: -70px;
      width: 50px;
      height: 50px; } }

.component.accordion-spot .accordion-spot-element__body.bullets-grey ol,
.component.accordion-spot .accordion-spot-element__body.bullets-grey ul {
  list-style: none;
  list-style-position: inside;
  padding-left: 12px; }

.component.accordion-spot .accordion-spot-element__body.bullets-grey ol {
  counter-reset: demant-counter;
  padding-left: 50px; }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body.bullets-grey ol {
      padding-left: 70px; } }

.component.accordion-spot .accordion-spot-element__body.bullets-grey ol li,
.component.accordion-spot .accordion-spot-element__body.bullets-grey ul li {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.4;
  position: relative;
  box-sizing: border-box; }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body.bullets-grey ol li,
    .component.accordion-spot .accordion-spot-element__body.bullets-grey ul li {
      font-size: 15px; } }

.component.accordion-spot .accordion-spot-element__body.bullets-grey ol li {
  counter-increment: demant-counter;
  min-height: 35px;
  display: flex;
  align-items: center; }
  .component.accordion-spot .accordion-spot-element__body.bullets-grey ol li + li {
    margin-top: 0.5rem; }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body.bullets-grey ol li {
      min-height: 50px; } }

.component.accordion-spot .accordion-spot-element__body.bullets-grey ol li::before,
.component.accordion-spot .accordion-spot-element__body.bullets-grey ul li::before {
  content: "";
  display: inline-block;
  background-color: #b9b9ad;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  left: -11px;
  top: 0.5em;
  width: 4px;
  height: 4px; }

.component.accordion-spot .accordion-spot-element__body.bullets-grey ol li::before {
  content: counter(demant-counter);
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  left: -50px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%); }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body.bullets-grey ol li::before {
      font-size: 18px; } }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body.bullets-grey ol li::before {
      left: -70px;
      width: 50px;
      height: 50px; } }

.component.accordion-spot .accordion-spot-element__body.bullets-green ol,
.component.accordion-spot .accordion-spot-element__body.bullets-green ul {
  list-style: none;
  list-style-position: inside;
  padding-left: 12px; }

.component.accordion-spot .accordion-spot-element__body.bullets-green ol {
  counter-reset: demant-counter;
  padding-left: 50px; }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body.bullets-green ol {
      padding-left: 70px; } }

.component.accordion-spot .accordion-spot-element__body.bullets-green ol li,
.component.accordion-spot .accordion-spot-element__body.bullets-green ul li {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.4;
  position: relative;
  box-sizing: border-box; }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body.bullets-green ol li,
    .component.accordion-spot .accordion-spot-element__body.bullets-green ul li {
      font-size: 15px; } }

.component.accordion-spot .accordion-spot-element__body.bullets-green ol li {
  counter-increment: demant-counter;
  min-height: 35px;
  display: flex;
  align-items: center; }
  .component.accordion-spot .accordion-spot-element__body.bullets-green ol li + li {
    margin-top: 0.5rem; }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body.bullets-green ol li {
      min-height: 50px; } }

.component.accordion-spot .accordion-spot-element__body.bullets-green ol li::before,
.component.accordion-spot .accordion-spot-element__body.bullets-green ul li::before {
  content: "";
  display: inline-block;
  background-color: #74cecd;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  left: -11px;
  top: 0.5em;
  width: 4px;
  height: 4px; }

.component.accordion-spot .accordion-spot-element__body.bullets-green ol li::before {
  content: counter(demant-counter);
  font-weight: 600;
  font-size: 15px;
  color: #24405b;
  left: -50px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%); }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body.bullets-green ol li::before {
      font-size: 18px; } }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body.bullets-green ol li::before {
      left: -70px;
      width: 50px;
      height: 50px; } }

.component.accordion-spot .accordion-spot-element__body.bullets-fat ol li::before,
.component.accordion-spot .accordion-spot-element__body.bullets-fat ul li::before {
  top: 50%;
  transform: translateY(-50%); }

.component.accordion-spot .accordion-spot-element__body.bullets-fat ul li {
  padding-left: 2rem;
  margin: 0 0 25px 0; }
  .component.accordion-spot .accordion-spot-element__body.bullets-fat ul li::before {
    width: 15px;
    height: 15px; }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body.bullets-fat ul li {
      margin: 0 0 51px 0; }
      .component.accordion-spot .accordion-spot-element__body.bullets-fat ul li::before {
        width: 30px;
        height: 30px; } }

.component.accordion-spot .accordion-spot-element__body.charcoal-blue-bg ol,
.component.accordion-spot .accordion-spot-element__body.charcoal-blue-bg ul {
  list-style: none;
  list-style-position: inside;
  padding-left: 12px; }

.component.accordion-spot .accordion-spot-element__body.charcoal-blue-bg ol {
  counter-reset: demant-counter;
  padding-left: 50px; }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body.charcoal-blue-bg ol {
      padding-left: 70px; } }

.component.accordion-spot .accordion-spot-element__body.charcoal-blue-bg ol li,
.component.accordion-spot .accordion-spot-element__body.charcoal-blue-bg ul li {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.4;
  position: relative;
  box-sizing: border-box; }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body.charcoal-blue-bg ol li,
    .component.accordion-spot .accordion-spot-element__body.charcoal-blue-bg ul li {
      font-size: 15px; } }

.component.accordion-spot .accordion-spot-element__body.charcoal-blue-bg ol li {
  counter-increment: demant-counter;
  min-height: 35px;
  display: flex;
  align-items: center; }
  .component.accordion-spot .accordion-spot-element__body.charcoal-blue-bg ol li + li {
    margin-top: 0.5rem; }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body.charcoal-blue-bg ol li {
      min-height: 50px; } }

.component.accordion-spot .accordion-spot-element__body.charcoal-blue-bg ol li::before,
.component.accordion-spot .accordion-spot-element__body.charcoal-blue-bg ul li::before {
  content: "";
  display: inline-block;
  background-color: #74cecd;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  left: -11px;
  top: 0.5em;
  width: 4px;
  height: 4px; }

.component.accordion-spot .accordion-spot-element__body.charcoal-blue-bg ol li::before {
  content: counter(demant-counter);
  font-weight: 600;
  font-size: 15px;
  color: #24405b;
  left: -50px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%); }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body.charcoal-blue-bg ol li::before {
      font-size: 18px; } }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body.charcoal-blue-bg ol li::before {
      left: -70px;
      width: 50px;
      height: 50px; } }

.component.accordion-spot .accordion-spot-element__body.warm-gray-bg ol,
.component.accordion-spot .accordion-spot-element__body.warm-gray-bg ul {
  list-style: none;
  list-style-position: inside;
  padding-left: 12px; }

.component.accordion-spot .accordion-spot-element__body.warm-gray-bg ol {
  counter-reset: demant-counter;
  padding-left: 50px; }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body.warm-gray-bg ol {
      padding-left: 70px; } }

.component.accordion-spot .accordion-spot-element__body.warm-gray-bg ol li,
.component.accordion-spot .accordion-spot-element__body.warm-gray-bg ul li {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.4;
  position: relative;
  box-sizing: border-box; }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body.warm-gray-bg ol li,
    .component.accordion-spot .accordion-spot-element__body.warm-gray-bg ul li {
      font-size: 15px; } }

.component.accordion-spot .accordion-spot-element__body.warm-gray-bg ol li {
  counter-increment: demant-counter;
  min-height: 35px;
  display: flex;
  align-items: center; }
  .component.accordion-spot .accordion-spot-element__body.warm-gray-bg ol li + li {
    margin-top: 0.5rem; }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body.warm-gray-bg ol li {
      min-height: 50px; } }

.component.accordion-spot .accordion-spot-element__body.warm-gray-bg ol li::before,
.component.accordion-spot .accordion-spot-element__body.warm-gray-bg ul li::before {
  content: "";
  display: inline-block;
  background-color: #24405b;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  left: -11px;
  top: 0.5em;
  width: 4px;
  height: 4px; }

.component.accordion-spot .accordion-spot-element__body.warm-gray-bg ol li::before {
  content: counter(demant-counter);
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  left: -50px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%); }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body.warm-gray-bg ol li::before {
      font-size: 18px; } }
  @media only screen and (min-width: 768px) {
    .component.accordion-spot .accordion-spot-element__body.warm-gray-bg ol li::before {
      left: -70px;
      width: 50px;
      height: 50px; } }

.component.accordion-spot .accordion-spot-element__body img {
  max-width: 100%; }

.component.accordion-spot .accordion-spot-element__header {
  font-weight: bold; }

.component.accordion-spot .accordion-spot-element + .accordion-spot-element {
  margin-top: 24px;
  display: flow-root; }

/* GENERAL TAB STYLING */
.component.accordion-spot.tabs .accordion-spot-element__title {
  padding-left: 0; }
  .component.accordion-spot.tabs .accordion-spot-element__title::before {
    display: none;
    background: none; }

.component.accordion-spot.tabs .accordion-spot-element__content {
  padding: 41px 0 0 0; }

/* BUTTON TAB STYLING */
.component.accordion-spot.tabs.button .accordion-spot-tab {
  margin-right: 10px; }
  .component.accordion-spot.tabs.button .accordion-spot-tab__title {
    font-size: 12px;
    color: #ffffff;
    background-color: #3344f6;
    border: 0;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    border-radius: 19.5px;
    padding: 11px 26px 12px;
    border: 1px solid #b9b9ad;
    background-color: transparent;
    color: #b9b9ad; }
    @media only screen and (min-width: 768px) {
      .component.accordion-spot.tabs.button .accordion-spot-tab__title {
        font-size: 15px; } }
    .component.accordion-spot.tabs.button .accordion-spot-tab__title a {
      color: #ffffff;
      text-decoration: none; }
    @media only screen and (min-width: 768px) {
      .component.accordion-spot.tabs.button .accordion-spot-tab__title {
        border-radius: 25px;
        padding: 14px 35px 15px; } }
  .component.accordion-spot.tabs.button .accordion-spot-tab.passive:hover .accordion-spot-tab__title, .component.accordion-spot.tabs.button .accordion-spot-tab.active .accordion-spot-tab__title {
    border: 1px solid #3344f6;
    background-color: #3344f6;
    color: #ffffff; }

/* BUTTON TAB STYLING */
/* HEADLINE TAB STYLING */
.component.accordion-spot.tabs.headline .accordion-spot-tab {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  min-width: 100px;
  border-bottom: 3px solid #74cecd;
  padding-bottom: 11px; }
  @media only screen and (min-width: 992px) {
    .component.accordion-spot.tabs.headline .accordion-spot-tab {
      margin-right: 100px;
      min-width: 150px;
      border-bottom-width: 5px;
      padding-bottom: 15px; } }
  .component.accordion-spot.tabs.headline .accordion-spot-tab.passive {
    border-bottom-color: #b9b9ad; }
  .component.accordion-spot.tabs.headline .accordion-spot-tab__title {
    color: #74cecd;
    border: 0;
    padding-bottom: 4px; }

/* HEADLINE TAB STYLING */
/* MODERN STYLING */
::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
/* MODERN STYLING */
.component.accordion-spot.modern {
  margin-top: 0; }
  .component.accordion-spot.modern .component-content {
    margin: 0; }
    @media only screen and (min-width: 768px) {
      .component.accordion-spot.modern .component-content {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 1600px; }
        .component.accordion-spot.modern .component-content.full-width {
          width: 100%;
          max-width: 1600px; }
        .component.accordion-spot.modern .component-content.regular {
          width: 86%;
          max-width: 1200px; }
        .component.accordion-spot.modern .component-content.slim {
          width: 47.2%;
          max-width: 660px; } }
  .component.accordion-spot.modern .accordion-spot-elements {
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column; }
    @media only screen and (min-width: 992px) {
      .component.accordion-spot.modern .accordion-spot-elements {
        flex-direction: row; } }
  .component.accordion-spot.modern .accordion-spot-elements__expand-all {
    display: none; }
  .component.accordion-spot.modern .accordion-spot-element {
    position: relative;
    height: 67px;
    flex-grow: 1;
    transition: all 0.6s ease-out, background-color 0.3s; }
    @media only screen and (min-width: 992px) {
      .component.accordion-spot.modern .accordion-spot-element {
        height: 500px;
        min-width: 95px; } }
    .component.accordion-spot.modern .accordion-spot-element::before {
      font-weight: 300;
      content: "0" attr(data-index);
      position: absolute;
      top: 15px;
      left: 25px;
      padding-bottom: 2px;
      font-size: 22px;
      color: #74cecd;
      border-bottom: 2px solid #74cecd;
      transform: rotate(-90deg);
      transform-origin: 50% 50%; }
      @media only screen and (min-width: 992px) {
        .component.accordion-spot.modern .accordion-spot-element::before {
          transform: none;
          font-size: 30px;
          top: 30px;
          left: 28px; } }
    .component.accordion-spot.modern .accordion-spot-element::after {
      position: absolute;
      top: 15px;
      right: 27px;
      content: "";
      background: url("/design/build/demant2019/assets/open.svg") no-repeat;
      background-size: contain;
      width: 37px;
      height: 37px;
      transition: opacity 0.6s; }
      @media only screen and (min-width: 992px) {
        .component.accordion-spot.modern .accordion-spot-element::after {
          top: auto;
          right: auto;
          bottom: 30px;
          left: 21px;
          width: 50px;
          height: 50px; } }
    .component.accordion-spot.modern .accordion-spot-element:not(:last-child) {
      border-bottom: 1px solid #ffffff; }
      @media only screen and (min-width: 992px) {
        .component.accordion-spot.modern .accordion-spot-element:not(:last-child) {
          border-bottom: 0;
          border-right: 1px solid #ffffff; } }
    .component.accordion-spot.modern .accordion-spot-element__title {
      font-weight: 100;
      top: 0;
      left: 0;
      padding: 18px 69px;
      color: #ffffff;
      font-size: 22px;
      border: 0;
      transition: all 0.3s ease-out; }
      @media only screen and (min-width: 992px) {
        .component.accordion-spot.modern .accordion-spot-element__title {
          font-size: 30px;
          padding: 0;
          top: 50px;
          left: 25px;
          position: absolute;
          transform: rotate(90deg);
          transform-origin: 0 100%;
          transition: none; } }
      .component.accordion-spot.modern .accordion-spot-element__title::before {
        content: none;
        background: none; }
    .component.accordion-spot.modern .accordion-spot-element__content {
      position: absolute;
      top: 4em;
      left: -9999px;
      right: 7999px;
      bottom: 0;
      padding-left: 30px;
      opacity: 0;
      display: block; }
      @media only screen and (min-width: 992px) {
        .component.accordion-spot.modern .accordion-spot-element__content {
          top: 132px;
          padding: 0 89px 72px; } }
    .component.accordion-spot.modern .accordion-spot-element__header, .component.accordion-spot.modern .accordion-spot-element__body {
      opacity: 0;
      transition: none; }
    .component.accordion-spot.modern .accordion-spot-element.expand {
      background-color: #24405b;
      color: #ffffff;
      height: 420px;
      transition: all 0.6s ease-out; }
      .component.accordion-spot.modern .accordion-spot-element.expand h1,
      .component.accordion-spot.modern .accordion-spot-element.expand .like-h1,
      .component.accordion-spot.modern .accordion-spot-element.expand a,
      .component.accordion-spot.modern .accordion-spot-element.expand .like-a {
        color: #ffffff; }
      @media only screen and (min-width: 992px) {
        .component.accordion-spot.modern .accordion-spot-element.expand {
          height: auto;
          flex-grow: 20; } }
      .component.accordion-spot.modern .accordion-spot-element.expand::after {
        opacity: 0; }
      .component.accordion-spot.modern .accordion-spot-element.expand .accordion-spot-element__title {
        position: static;
        padding: 12px 67px;
        font-size: 30px; }
        @media only screen and (min-width: 992px) {
          .component.accordion-spot.modern .accordion-spot-element.expand .accordion-spot-element__title {
            transform: none;
            font-size: 50px;
            padding: 23px 89px; } }
        .component.accordion-spot.modern .accordion-spot-element.expand .accordion-spot-element__title::before {
          content: none;
          background: none; }
      .component.accordion-spot.modern .accordion-spot-element.expand .accordion-spot-element__content {
        left: 0;
        right: 0;
        opacity: 1; }
      .component.accordion-spot.modern .accordion-spot-element.expand .accordion-spot-element__header,
      .component.accordion-spot.modern .accordion-spot-element.expand .accordion-spot-element__body {
        opacity: 1;
        transition: opacity 0.3s ease-out 0.6s; }
    .component.accordion-spot.modern .accordion-spot-element + .accordion-spot-element {
      margin-top: 0; }

/* MODERN STYLING */
::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

@keyframes in {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

@keyframes out {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

::selection {
  background: #edb833; }

div[class*=" circle-"] svg,
div[class*=" circle-"] .svg {
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: 50% 50%; }
  @media only screen and (min-width: 992px) {
    div[class*=" circle-"] svg,
    div[class*=" circle-"] .svg {
      animation-name: rotate; } }
  div[class*=" circle-"] svg ellipse,
  div[class*=" circle-"] .svg ellipse {
    fill: transparent; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.circle-charcoal-blue-v2 ellipse {
  fill-rule: evenodd; }
  .circle-charcoal-blue-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-charcoal-blue-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-charcoal-blue-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-charcoal-blue-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-charcoal-blue-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-multi-colour-earth-v2 ellipse {
  fill-rule: evenodd; }
  .circle-multi-colour-earth-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-multi-colour-earth-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-multi-colour-earth-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-multi-colour-earth-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-multi-colour-earth-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-multi-colour-fresh-v2 ellipse {
  fill-rule: evenodd; }
  .circle-multi-colour-fresh-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-multi-colour-fresh-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-multi-colour-fresh-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-multi-colour-fresh-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-multi-colour-fresh-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-multi-colour-gradient_1-v2 ellipse {
  fill-rule: evenodd; }
  .circle-multi-colour-gradient_1-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-multi-colour-gradient_1-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-multi-colour-gradient_1-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-multi-colour-gradient_1-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-multi-colour-gradient_1-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-multi-colour-gradient_3-v2 ellipse {
  fill-rule: evenodd; }
  .circle-multi-colour-gradient_3-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-multi-colour-gradient_3-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-multi-colour-gradient_3-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-multi-colour-gradient_3-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-multi-colour-gradient_3-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-multi-colour-gradient_4-v2 ellipse {
  fill-rule: evenodd; }
  .circle-multi-colour-gradient_4-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-multi-colour-gradient_4-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-multi-colour-gradient_4-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-multi-colour-gradient_4-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-multi-colour-gradient_4-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-multi-colour-v2 ellipse {
  fill-rule: evenodd; }
  .circle-multi-colour-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-multi-colour-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-multi-colour-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-multi-colour-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-multi-colour-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-pine-green-v2 ellipse {
  fill-rule: evenodd; }
  .circle-pine-green-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-pine-green-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-pine-green-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-pine-green-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-pine-green-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-seafoam-green-v2 ellipse {
  fill-rule: evenodd; }
  .circle-seafoam-green-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-seafoam-green-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-seafoam-green-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-seafoam-green-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-seafoam-green-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-sky-blue-v2 ellipse {
  fill-rule: evenodd; }
  .circle-sky-blue-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-sky-blue-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-sky-blue-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-sky-blue-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-sky-blue-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-walnut-brown-v2 ellipse {
  fill-rule: evenodd; }
  .circle-walnut-brown-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-walnut-brown-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-walnut-brown-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-walnut-brown-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-walnut-brown-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-white-v2 ellipse {
  fill-rule: evenodd; }
  .circle-white-v2 ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-white-v2 ellipse:nth-child(2) {
    fill: #24405b;
    mix-blend-mode: multiply; }
  .circle-white-v2 ellipse:nth-child(3) {
    fill: #24405b;
    mix-blend-mode: multiply; }

.circle-white-v2 .svg {
  background-image: url("/design/build/demant2019/assets/circle-white-v2.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-burst-yellow ellipse {
  fill-rule: evenodd; }
  .circle-burst-yellow ellipse:nth-child(1) {
    fill: #edb833; }
  .circle-burst-yellow ellipse:nth-child(2) {
    fill: #edb833;
    mix-blend-mode: multiply; }
  .circle-burst-yellow ellipse:nth-child(3) {
    fill: #edb833;
    mix-blend-mode: multiply; }

.circle-burst-yellow .svg {
  background-image: url("/design/build/demant2019/assets/circle-burst-yellow.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-charcoal-blue ellipse {
  fill-rule: evenodd; }
  .circle-charcoal-blue ellipse:nth-child(1) {
    fill: #24405b; }
  .circle-charcoal-blue ellipse:nth-child(2) {
    fill: #5b7085;
    mix-blend-mode: normal; }
  .circle-charcoal-blue ellipse:nth-child(3) {
    fill: rgba(36, 64, 91, 0.5);
    mix-blend-mode: multiply; }

.circle-charcoal-blue .svg {
  background-image: url("/design/build/demant2019/assets/circle-charcoal-blue.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-electric-seafoam ellipse {
  fill-rule: evenodd; }
  .circle-electric-seafoam ellipse:nth-child(1) {
    fill: black; }
  .circle-electric-seafoam ellipse:nth-child(2) {
    fill: black;
    mix-blend-mode: multiply; }
  .circle-electric-seafoam ellipse:nth-child(3) {
    fill: black;
    mix-blend-mode: multiply; }

.circle-electric-seafoam .svg {
  background-image: url("/design/build/demant2019/assets/circle-electric-seafoam.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-electric-seafoam-info ellipse {
  fill-rule: evenodd; }
  .circle-electric-seafoam-info ellipse:nth-child(1) {
    fill: black; }
  .circle-electric-seafoam-info ellipse:nth-child(2) {
    fill: black;
    mix-blend-mode: multiply; }
  .circle-electric-seafoam-info ellipse:nth-child(3) {
    fill: black;
    mix-blend-mode: multiply; }

.circle-electric-seafoam-info .svg {
  background-image: url("/design/build/demant2019/assets/circle-electric-seafoam-info.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-seafoam-gray ellipse {
  fill-rule: evenodd; }
  .circle-seafoam-gray ellipse:nth-child(1) {
    fill: black; }
  .circle-seafoam-gray ellipse:nth-child(2) {
    fill: black;
    mix-blend-mode: multiply; }
  .circle-seafoam-gray ellipse:nth-child(3) {
    fill: black;
    mix-blend-mode: multiply; }

.circle-seafoam-gray .svg {
  background-image: url("/design/build/demant2019/assets/circle-seafoam-gray.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-seafoam-gray-info ellipse {
  fill-rule: evenodd; }
  .circle-seafoam-gray-info ellipse:nth-child(1) {
    fill: black; }
  .circle-seafoam-gray-info ellipse:nth-child(2) {
    fill: black;
    mix-blend-mode: multiply; }
  .circle-seafoam-gray-info ellipse:nth-child(3) {
    fill: black;
    mix-blend-mode: multiply; }

.circle-seafoam-gray-info .svg {
  background-image: url("/design/build/demant2019/assets/circle-seafoam-gray-info.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-seafoam-green ellipse {
  fill-rule: evenodd; }
  .circle-seafoam-green ellipse:nth-child(1) {
    fill: #74cecd; }
  .circle-seafoam-green ellipse:nth-child(2) {
    fill: #74cecd;
    mix-blend-mode: multiply; }
  .circle-seafoam-green ellipse:nth-child(3) {
    fill: #74cecd;
    mix-blend-mode: multiply; }

.circle-seafoam-green .svg {
  background-image: url("/design/build/demant2019/assets/circle-seafoam-green.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-warm-gray ellipse {
  fill-rule: evenodd; }
  .circle-warm-gray ellipse:nth-child(1) {
    fill: #b9b9ad; }
  .circle-warm-gray ellipse:nth-child(2) {
    fill: rgba(185, 185, 173, 0.5);
    mix-blend-mode: multiply; }
  .circle-warm-gray ellipse:nth-child(3) {
    fill: rgba(185, 185, 173, 0.75);
    mix-blend-mode: multiply; }

.circle-warm-gray .svg {
  background-image: url("/design/build/demant2019/assets/circle-warm-gray.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.circle-white ellipse {
  fill-rule: evenodd; }
  .circle-white ellipse:nth-child(1) {
    fill: rgba(255, 255, 255, 0.75); }
  .circle-white ellipse:nth-child(2) {
    fill: rgba(255, 255, 255, 0.5);
    mix-blend-mode: normal; }
  .circle-white ellipse:nth-child(3) {
    fill: rgba(255, 255, 255, 0.5);
    mix-blend-mode: normal; }

.circle-white .svg {
  background-image: url("/design/build/demant2019/assets/circle-white.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

body.on-page-editor .component.image-spot {
  min-height: 60px; }

.component.image-spot .component-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch; }
  .component.image-spot .component-content .image-spot-element {
    width: 80vw; }
  .component.image-spot .component-content .image-spot-element-image {
    margin: 0 15%; }
  @media only screen and (min-width: 768px) {
    .component.image-spot .component-content .image-spot-element {
      width: 40vw; }
    .component.image-spot .component-content .image-spot-element-image {
      margin: 0 0%; } }
  @media only screen and (min-width: 992px) {
    .component.image-spot .component-content .image-spot-element {
      width: 25vw; }
    .component.image-spot .component-content .image-spot-element-image {
      margin: 0 0%; } }
  @media only screen and (min-width: 1200px) {
    .component.image-spot .component-content .image-spot-element {
      width: 30%; }
    .component.image-spot .component-content .image-spot-element-image {
      margin: 0 0%; } }
  .component.image-spot .component-content .image-spot-element-header,
  .component.image-spot .component-content .image-spot-element-text {
    text-align: center;
    z-index: 1;
    position: relative; }
    @media only screen and (min-width: 768px) {
      .component.image-spot .component-content .image-spot-element-header,
      .component.image-spot .component-content .image-spot-element-text {
        text-align: inherit; } }
  .component.image-spot .component-content .image-spot-element-link {
    display: flex;
    justify-content: center; }
  .component.image-spot .component-content img {
    width: 100%;
    height: auto; }
  .component.image-spot .component-content .image-spot-element-image {
    position: relative; }
    .component.image-spot .component-content .image-spot-element-image .play-button-container {
      display: none; }
    .component.image-spot .component-content .image-spot-element-image a.has-video .play-button-container {
      bottom: 20px;
      display: inherit;
      position: absolute;
      right: 20px; }
      .component.image-spot .component-content .image-spot-element-image a.has-video .play-button-container .like-button-minimal {
        animation: out 0.7s -0.1s ease-in-out forwards;
        background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
        background-size: 420% 100%;
        background-position: right bottom;
        background-color: #3344f6;
        color: #ffffff;
        transition: all 0.4s ease-in-out;
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        font-feature-settings: "liga";
        -webkit-font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
        border-radius: 50%;
        height: 42px;
        line-height: 42px;
        padding: 0;
        text-align: center;
        width: 42px;
        display: block; }
    .component.image-spot .component-content .image-spot-element-image a.has-video:hover .play-button-container .like-button-minimal {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
      .component.image-spot .component-content .image-spot-element-image a.has-video:hover .play-button-container .like-button-minimal a {
        color: #3344f6;
        transition: all 0.8s ease-in-out; }

.content .component.image-spot .component-content {
  margin-left: 30px;
  margin-right: 30px; }
  @media only screen and (min-width: 768px) {
    .content .component.image-spot .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      .content .component.image-spot .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .content .component.image-spot .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .content .component.image-spot .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }

.content .component.image-spot.slim .component-content {
  margin-left: 30px;
  margin-right: 30px; }
  @media only screen and (min-width: 768px) {
    .content .component.image-spot.slim .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 47.2%;
      max-width: 660px; }
      .content .component.image-spot.slim .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .content .component.image-spot.slim .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .content .component.image-spot.slim .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }
  @media only screen and (min-width: 1200px) {
    .content .component.image-spot.slim .component-content {
      min-width: 850px; } }

.content .component.image-spot.green-blue-gradient-bg {
  background-image: linear-gradient(to right, #74cbca, #3344f6);
  margin-top: 0; }
  .content .component.image-spot.green-blue-gradient-bg.first-repeating-background, .content .component.image-spot.green-blue-gradient-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.image-spot.green-blue-gradient-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.image-spot.green-blue-gradient-bg.space-top {
        padding-top: 60px; } }
  .content .component.image-spot.green-blue-gradient-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .content .component.image-spot.green-blue-gradient-bg .component-content {
        padding: 30px 0; } }

.content .component.image-spot.charcoal-blue-bg {
  background-color: #24405b;
  margin-top: 0; }
  .content .component.image-spot.charcoal-blue-bg.first-repeating-background, .content .component.image-spot.charcoal-blue-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.image-spot.charcoal-blue-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.image-spot.charcoal-blue-bg.space-top {
        padding-top: 60px; } }
  .content .component.image-spot.charcoal-blue-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .content .component.image-spot.charcoal-blue-bg .component-content {
        padding: 30px 0; } }

.content .component.image-spot.warm-gray-bg {
  background-color: #dcdcd6;
  margin-top: 0; }
  .content .component.image-spot.warm-gray-bg.first-repeating-background, .content .component.image-spot.warm-gray-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.image-spot.warm-gray-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.image-spot.warm-gray-bg.space-top {
        padding-top: 60px; } }
  .content .component.image-spot.warm-gray-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .content .component.image-spot.warm-gray-bg .component-content {
        padding: 30px 0; } }

.content .component.image-spot.seafoam-green-50-bg {
  background-color: #badcdf;
  margin-top: 0; }
  .content .component.image-spot.seafoam-green-50-bg.first-repeating-background, .content .component.image-spot.seafoam-green-50-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.image-spot.seafoam-green-50-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.image-spot.seafoam-green-50-bg.space-top {
        padding-top: 60px; } }
  .content .component.image-spot.seafoam-green-50-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .content .component.image-spot.seafoam-green-50-bg .component-content {
        padding: 30px 0; } }

.content .component.image-spot.sky-blue-75-bg {
  background-color: #bcdef7;
  margin-top: 0; }
  .content .component.image-spot.sky-blue-75-bg.first-repeating-background, .content .component.image-spot.sky-blue-75-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.image-spot.sky-blue-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.image-spot.sky-blue-75-bg.space-top {
        padding-top: 60px; } }
  .content .component.image-spot.sky-blue-75-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .content .component.image-spot.sky-blue-75-bg .component-content {
        padding: 30px 0; } }

.content .component.image-spot.warm-grey-75-bg {
  background-color: #cacac1;
  margin-top: 0; }
  .content .component.image-spot.warm-grey-75-bg.first-repeating-background, .content .component.image-spot.warm-grey-75-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.image-spot.warm-grey-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.image-spot.warm-grey-75-bg.space-top {
        padding-top: 60px; } }
  .content .component.image-spot.warm-grey-75-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .content .component.image-spot.warm-grey-75-bg .component-content {
        padding: 30px 0; } }

.content .component.image-spot.pine-green-75-bg {
  background-color: #609c9c;
  margin-top: 0; }
  .content .component.image-spot.pine-green-75-bg.first-repeating-background, .content .component.image-spot.pine-green-75-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.image-spot.pine-green-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.image-spot.pine-green-75-bg.space-top {
        padding-top: 60px; } }
  .content .component.image-spot.pine-green-75-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .content .component.image-spot.pine-green-75-bg .component-content {
        padding: 30px 0; } }

.content .component.image-spot.burst-yellow-10-bg {
  background-color: #fcf7ea;
  margin-top: 0; }
  .content .component.image-spot.burst-yellow-10-bg.first-repeating-background, .content .component.image-spot.burst-yellow-10-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.image-spot.burst-yellow-10-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.image-spot.burst-yellow-10-bg.space-top {
        padding-top: 60px; } }
  .content .component.image-spot.burst-yellow-10-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .content .component.image-spot.burst-yellow-10-bg .component-content {
        padding: 30px 0; } }

.content .component.image-spot.walnut-brown-bg {
  background-color: #6c4b42;
  margin-top: 0; }
  .content .component.image-spot.walnut-brown-bg.first-repeating-background, .content .component.image-spot.walnut-brown-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.image-spot.walnut-brown-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.image-spot.walnut-brown-bg.space-top {
        padding-top: 60px; } }
  .content .component.image-spot.walnut-brown-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .content .component.image-spot.walnut-brown-bg .component-content {
        padding: 30px 0; } }

.content .component.image-spot .image-spot-element {
  margin: 0 auto; }
  .content .component.image-spot .image-spot-element + .image-spot-element {
    margin-top: 40px; }
  @media only screen and (min-width: 768px) {
    .content .component.image-spot .image-spot-element {
      margin: 0; }
      .content .component.image-spot .image-spot-element + .image-spot-element {
        margin: 0; }
      .content .component.image-spot .image-spot-element:nth-child(n + 2) {
        margin: 0; }
      .content .component.image-spot .image-spot-element:nth-child(1n + 1):last-child {
        margin-left: 0;
        margin-right: 0; }
      .content .component.image-spot .image-spot-element:nth-child(n + 3) {
        margin: 0; }
      .content .component.image-spot .image-spot-element:nth-child(2n + 1):last-child {
        margin-left: 0;
        margin-right: 0; }
      .content .component.image-spot .image-spot-element:nth-child(n + 3) {
        margin-top: 40px; }
      .content .component.image-spot .image-spot-element:nth-child(2n + 1):last-child {
        margin-left: auto;
        margin-right: auto; } }
  @media only screen and (min-width: 992px) {
    .content .component.image-spot .image-spot-element:nth-child(2n + 3), .content .component.image-spot .image-spot-element:nth-child(2n + 3):last-child {
      margin: 0; }
    .content .component.image-spot .image-spot-element:nth-child(1):nth-last-child(2) {
      margin-right: 5%;
      margin-left: auto; }
    .content .component.image-spot .image-spot-element:nth-child(2):nth-last-child(1):last-child {
      margin-left: 0;
      margin-right: auto; } }

.content .component.image-spot .image-spot-element-header {
  font-weight: bold;
  padding-top: 12px; }
  @media only screen and (min-width: 992px) {
    .content .component.image-spot .image-spot-element-header {
      padding-top: 24px; } }

.content .component.image-spot .image-spot-element-link {
  margin-top: 1em; }

.content .component.image-spot .image-spot-element-link a.has-video {
  display: none; }

@media only screen and (min-width: 768px) {
  .content .component.image-spot.four .image-spot-element {
    max-width: 35%; } }

@media only screen and (min-width: 992px) {
  .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(4),
  .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(4) ~ .image-spot-element {
    max-width: 20%; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(4):nth-child(n + 2),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(4) ~ .image-spot-element:nth-child(n + 2) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(4):nth-child(1n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(4) ~ .image-spot-element:nth-child(1n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(4):nth-child(n + 3),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(4) ~ .image-spot-element:nth-child(n + 3) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(4):nth-child(2n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(4) ~ .image-spot-element:nth-child(2n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(4):nth-child(n + 4),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(4) ~ .image-spot-element:nth-child(n + 4) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(4):nth-child(3n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(4) ~ .image-spot-element:nth-child(3n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(4):nth-child(n + 5),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(4) ~ .image-spot-element:nth-child(n + 5) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(4):nth-child(4n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(4) ~ .image-spot-element:nth-child(4n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(4):nth-child(n + 5),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(4) ~ .image-spot-element:nth-child(n + 5) {
      margin-top: 40px; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(4):nth-child(4n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(4) ~ .image-spot-element:nth-child(4n + 1):last-child {
      margin-left: auto;
      margin-right: auto; }
  .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5),
  .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5) ~ .image-spot-element {
    max-width: 16.66667%; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5):nth-child(n + 2),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5) ~ .image-spot-element:nth-child(n + 2) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5):nth-child(1n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5) ~ .image-spot-element:nth-child(1n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5):nth-child(n + 3),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5) ~ .image-spot-element:nth-child(n + 3) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5):nth-child(2n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5) ~ .image-spot-element:nth-child(2n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5):nth-child(n + 4),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5) ~ .image-spot-element:nth-child(n + 4) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5):nth-child(3n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5) ~ .image-spot-element:nth-child(3n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5):nth-child(n + 5),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5) ~ .image-spot-element:nth-child(n + 5) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5):nth-child(4n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5) ~ .image-spot-element:nth-child(4n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5):nth-child(n + 6),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5) ~ .image-spot-element:nth-child(n + 6) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5):nth-child(5n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5) ~ .image-spot-element:nth-child(5n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5):nth-child(n + 6),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5) ~ .image-spot-element:nth-child(n + 6) {
      margin-top: 40px; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5):nth-child(5n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(5) ~ .image-spot-element:nth-child(5n + 1):last-child {
      margin-left: auto;
      margin-right: auto; }
  .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6),
  .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6) ~ .image-spot-element {
    max-width: 14.28571%; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6):nth-child(n + 2),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6) ~ .image-spot-element:nth-child(n + 2) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6):nth-child(1n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6) ~ .image-spot-element:nth-child(1n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6):nth-child(n + 3),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6) ~ .image-spot-element:nth-child(n + 3) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6):nth-child(2n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6) ~ .image-spot-element:nth-child(2n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6):nth-child(n + 4),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6) ~ .image-spot-element:nth-child(n + 4) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6):nth-child(3n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6) ~ .image-spot-element:nth-child(3n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6):nth-child(n + 5),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6) ~ .image-spot-element:nth-child(n + 5) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6):nth-child(4n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6) ~ .image-spot-element:nth-child(4n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6):nth-child(n + 6),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6) ~ .image-spot-element:nth-child(n + 6) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6):nth-child(5n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6) ~ .image-spot-element:nth-child(5n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6):nth-child(n + 7),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6) ~ .image-spot-element:nth-child(n + 7) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6):nth-child(6n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6) ~ .image-spot-element:nth-child(6n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6):nth-child(n + 7),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6) ~ .image-spot-element:nth-child(n + 7) {
      margin-top: 40px; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6):nth-child(6n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(6) ~ .image-spot-element:nth-child(6n + 1):last-child {
      margin-left: auto;
      margin-right: auto; }
  .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7),
  .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7) ~ .image-spot-element {
    max-width: 12.5%; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7):nth-child(n + 2),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7) ~ .image-spot-element:nth-child(n + 2) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7):nth-child(1n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7) ~ .image-spot-element:nth-child(1n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7):nth-child(n + 3),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7) ~ .image-spot-element:nth-child(n + 3) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7):nth-child(2n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7) ~ .image-spot-element:nth-child(2n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7):nth-child(n + 4),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7) ~ .image-spot-element:nth-child(n + 4) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7):nth-child(3n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7) ~ .image-spot-element:nth-child(3n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7):nth-child(n + 5),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7) ~ .image-spot-element:nth-child(n + 5) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7):nth-child(4n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7) ~ .image-spot-element:nth-child(4n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7):nth-child(n + 6),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7) ~ .image-spot-element:nth-child(n + 6) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7):nth-child(5n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7) ~ .image-spot-element:nth-child(5n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7):nth-child(n + 7),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7) ~ .image-spot-element:nth-child(n + 7) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7):nth-child(6n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7) ~ .image-spot-element:nth-child(6n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7):nth-child(n + 8),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7) ~ .image-spot-element:nth-child(n + 8) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7):nth-child(7n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7) ~ .image-spot-element:nth-child(7n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7):nth-child(n + 8),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7) ~ .image-spot-element:nth-child(n + 8) {
      margin-top: 40px; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7):nth-child(7n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(7) ~ .image-spot-element:nth-child(7n + 1):last-child {
      margin-left: auto;
      margin-right: auto; }
  .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8),
  .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8) ~ .image-spot-element {
    max-width: 11.11111%; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8):nth-child(n + 2),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8) ~ .image-spot-element:nth-child(n + 2) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8):nth-child(1n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8) ~ .image-spot-element:nth-child(1n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8):nth-child(n + 3),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8) ~ .image-spot-element:nth-child(n + 3) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8):nth-child(2n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8) ~ .image-spot-element:nth-child(2n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8):nth-child(n + 4),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8) ~ .image-spot-element:nth-child(n + 4) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8):nth-child(3n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8) ~ .image-spot-element:nth-child(3n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8):nth-child(n + 5),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8) ~ .image-spot-element:nth-child(n + 5) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8):nth-child(4n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8) ~ .image-spot-element:nth-child(4n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8):nth-child(n + 6),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8) ~ .image-spot-element:nth-child(n + 6) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8):nth-child(5n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8) ~ .image-spot-element:nth-child(5n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8):nth-child(n + 7),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8) ~ .image-spot-element:nth-child(n + 7) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8):nth-child(6n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8) ~ .image-spot-element:nth-child(6n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8):nth-child(n + 8),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8) ~ .image-spot-element:nth-child(n + 8) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8):nth-child(7n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8) ~ .image-spot-element:nth-child(7n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8):nth-child(n + 9),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8) ~ .image-spot-element:nth-child(n + 9) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8):nth-child(8n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8) ~ .image-spot-element:nth-child(8n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8):nth-child(n + 9),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8) ~ .image-spot-element:nth-child(n + 9) {
      margin-top: 40px; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8):nth-child(8n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(8) ~ .image-spot-element:nth-child(8n + 1):last-child {
      margin-left: auto;
      margin-right: auto; }
  .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9),
  .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9) ~ .image-spot-element {
    max-width: 10%; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9):nth-child(n + 2),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9) ~ .image-spot-element:nth-child(n + 2) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9):nth-child(1n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9) ~ .image-spot-element:nth-child(1n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9):nth-child(n + 3),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9) ~ .image-spot-element:nth-child(n + 3) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9):nth-child(2n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9) ~ .image-spot-element:nth-child(2n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9):nth-child(n + 4),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9) ~ .image-spot-element:nth-child(n + 4) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9):nth-child(3n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9) ~ .image-spot-element:nth-child(3n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9):nth-child(n + 5),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9) ~ .image-spot-element:nth-child(n + 5) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9):nth-child(4n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9) ~ .image-spot-element:nth-child(4n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9):nth-child(n + 6),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9) ~ .image-spot-element:nth-child(n + 6) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9):nth-child(5n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9) ~ .image-spot-element:nth-child(5n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9):nth-child(n + 7),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9) ~ .image-spot-element:nth-child(n + 7) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9):nth-child(6n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9) ~ .image-spot-element:nth-child(6n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9):nth-child(n + 8),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9) ~ .image-spot-element:nth-child(n + 8) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9):nth-child(7n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9) ~ .image-spot-element:nth-child(7n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9):nth-child(n + 9),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9) ~ .image-spot-element:nth-child(n + 9) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9):nth-child(8n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9) ~ .image-spot-element:nth-child(8n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9):nth-child(n + 10),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9) ~ .image-spot-element:nth-child(n + 10) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9):nth-child(9n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9) ~ .image-spot-element:nth-child(9n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9):nth-child(n + 10),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9) ~ .image-spot-element:nth-child(n + 10) {
      margin-top: 40px; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9):nth-child(9n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(9) ~ .image-spot-element:nth-child(9n + 1):last-child {
      margin-left: auto;
      margin-right: auto; }
  .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10),
  .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10) ~ .image-spot-element {
    max-width: 9.09091%; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10):nth-child(n + 2),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10) ~ .image-spot-element:nth-child(n + 2) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10):nth-child(1n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10) ~ .image-spot-element:nth-child(1n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10):nth-child(n + 3),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10) ~ .image-spot-element:nth-child(n + 3) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10):nth-child(2n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10) ~ .image-spot-element:nth-child(2n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10):nth-child(n + 4),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10) ~ .image-spot-element:nth-child(n + 4) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10):nth-child(3n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10) ~ .image-spot-element:nth-child(3n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10):nth-child(n + 5),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10) ~ .image-spot-element:nth-child(n + 5) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10):nth-child(4n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10) ~ .image-spot-element:nth-child(4n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10):nth-child(n + 6),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10) ~ .image-spot-element:nth-child(n + 6) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10):nth-child(5n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10) ~ .image-spot-element:nth-child(5n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10):nth-child(n + 7),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10) ~ .image-spot-element:nth-child(n + 7) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10):nth-child(6n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10) ~ .image-spot-element:nth-child(6n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10):nth-child(n + 8),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10) ~ .image-spot-element:nth-child(n + 8) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10):nth-child(7n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10) ~ .image-spot-element:nth-child(7n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10):nth-child(n + 9),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10) ~ .image-spot-element:nth-child(n + 9) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10):nth-child(8n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10) ~ .image-spot-element:nth-child(8n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10):nth-child(n + 10),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10) ~ .image-spot-element:nth-child(n + 10) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10):nth-child(9n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10) ~ .image-spot-element:nth-child(9n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10):nth-child(n + 11),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10) ~ .image-spot-element:nth-child(n + 11) {
      margin: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10):nth-child(10n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10) ~ .image-spot-element:nth-child(10n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10):nth-child(n + 11),
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10) ~ .image-spot-element:nth-child(n + 11) {
      margin-top: 40px; }
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10):nth-child(10n + 1):last-child,
    .content .component.image-spot.four .image-spot-element:first-child:nth-last-child(10) ~ .image-spot-element:nth-child(10n + 1):last-child {
      margin-left: auto;
      margin-right: auto; } }

.content .component.image-spot.live-circles svg,
.content .component.image-spot.live-circles .svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.content .component.image-spot.live-circles .image-spot-element-header-inner {
  position: relative; }

.content .component.image-spot.live-circles:not([class*=" circle-"]) svg,
.content .component.image-spot.live-circles:not([class*=" circle-"]) .svg {
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: 50% 50%; }
  @media only screen and (min-width: 992px) {
    .content .component.image-spot.live-circles:not([class*=" circle-"]) svg,
    .content .component.image-spot.live-circles:not([class*=" circle-"]) .svg {
      animation-name: rotate; } }
  .content .component.image-spot.live-circles:not([class*=" circle-"]) svg ellipse,
  .content .component.image-spot.live-circles:not([class*=" circle-"]) .svg ellipse {
    fill: transparent; }

.content .component.image-spot.live-circles:not([class*=" circle-"]) .image-spot-element:nth-child(odd) ellipse {
  fill-rule: evenodd; }
  .content .component.image-spot.live-circles:not([class*=" circle-"]) .image-spot-element:nth-child(odd) ellipse:nth-child(1) {
    fill: black; }
  .content .component.image-spot.live-circles:not([class*=" circle-"]) .image-spot-element:nth-child(odd) ellipse:nth-child(2) {
    fill: black;
    mix-blend-mode: multiply; }
  .content .component.image-spot.live-circles:not([class*=" circle-"]) .image-spot-element:nth-child(odd) ellipse:nth-child(3) {
    fill: black;
    mix-blend-mode: multiply; }

.content .component.image-spot.live-circles:not([class*=" circle-"]) .image-spot-element:nth-child(odd) .svg {
  background-image: url("/design/build/demant2019/assets/circle-seafoam-gray-info.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

.content .component.image-spot.live-circles:not([class*=" circle-"]) .image-spot-element:nth-child(even) ellipse {
  fill-rule: evenodd; }
  .content .component.image-spot.live-circles:not([class*=" circle-"]) .image-spot-element:nth-child(even) ellipse:nth-child(1) {
    fill: black; }
  .content .component.image-spot.live-circles:not([class*=" circle-"]) .image-spot-element:nth-child(even) ellipse:nth-child(2) {
    fill: black;
    mix-blend-mode: multiply; }
  .content .component.image-spot.live-circles:not([class*=" circle-"]) .image-spot-element:nth-child(even) ellipse:nth-child(3) {
    fill: black;
    mix-blend-mode: multiply; }

.content .component.image-spot.live-circles:not([class*=" circle-"]) .image-spot-element:nth-child(even) .svg {
  background-image: url("/design/build/demant2019/assets/circle-electric-seafoam-info.png");
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat; }

@media only screen and (min-width: 768px) {
  .content .component.image-spot.profile .image-spot-element {
    max-width: 35%; } }

@media only screen and (min-width: 992px) {
  .content .component.image-spot.profile .image-spot-element {
    max-width: 20%; } }

.content .component.image-spot.profile .image-spot-element-header *:only-child {
  font-weight: bold;
  font-size: 15px;
  line-height: 1.35;
  padding-bottom: 6px;
  border-bottom: 2px solid #979797;
  margin-bottom: 12px; }
  @media only screen and (min-width: 768px) {
    .content .component.image-spot.profile .image-spot-element-header *:only-child {
      font-size: 17px; } }
  @media only screen and (min-width: 768px) {
    .content .component.image-spot.profile .image-spot-element-header *:only-child {
      padding-bottom: 6px; } }

.content .component.image-spot.profile .image-spot-element-text {
  font-weight: normal;
  margin-bottom: 46px; }
  .content .component.image-spot.profile .image-spot-element-text p + p {
    margin-top: 0; }

@media only screen and (min-width: 992px) {
  .content .component.image-spot.profile:not(.button) .image-spot-element:nth-child(n + 2) {
    margin: 0; }
  .content .component.image-spot.profile:not(.button) .image-spot-element:nth-child(1n + 1):last-child {
    margin-left: 0;
    margin-right: 0; }
  .content .component.image-spot.profile:not(.button) .image-spot-element:nth-child(n + 3) {
    margin: 0; }
  .content .component.image-spot.profile:not(.button) .image-spot-element:nth-child(2n + 1):last-child {
    margin-left: 0;
    margin-right: 0; }
  .content .component.image-spot.profile:not(.button) .image-spot-element:nth-child(n + 4) {
    margin: 0; }
  .content .component.image-spot.profile:not(.button) .image-spot-element:nth-child(3n + 1):last-child {
    margin-left: 0;
    margin-right: 0; }
  .content .component.image-spot.profile:not(.button) .image-spot-element:nth-child(n + 5) {
    margin: 0; }
  .content .component.image-spot.profile:not(.button) .image-spot-element:nth-child(4n + 1):last-child {
    margin-left: 0;
    margin-right: 0; }
  .content .component.image-spot.profile:not(.button) .image-spot-element:nth-child(n + 5) {
    margin-top: 40px; }
  .content .component.image-spot.profile:not(.button) .image-spot-element:nth-child(4n + 1):last-child {
    margin-left: auto;
    margin-right: auto; } }

.content .component.image-spot.profile:not(.button) .image-spot-element-text {
  margin-bottom: 34px; }

@media only screen and (min-width: 768px) {
  .content .component.image-spot.profile:not(.button) .image-spot-element-link {
    justify-content: flex-start; } }

.content .component.image-spot.profile:not(.button) .image-spot-element-link a {
  background: none;
  box-shadow: none;
  text-decoration: none;
  color: #3344f6;
  padding: 0;
  display: flex;
  align-items: center; }
  .content .component.image-spot.profile:not(.button) .image-spot-element-link a::before {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: "liga";
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
    content: "email";
    padding-right: 0.2em; }

.content .component.image-spot.element-as-background .image-spot-element {
  position: relative; }
  @media only screen and (min-width: 992px) {
    .content .component.image-spot.element-as-background .image-spot-element:nth-child(n + 2) {
      margin: 0; }
    .content .component.image-spot.element-as-background .image-spot-element:nth-child(1n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.element-as-background .image-spot-element:nth-child(n + 3) {
      margin: 0; }
    .content .component.image-spot.element-as-background .image-spot-element:nth-child(2n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.element-as-background .image-spot-element:nth-child(n + 3) {
      margin-top: 40px; }
    .content .component.image-spot.element-as-background .image-spot-element:nth-child(2n + 1):last-child {
      margin-left: auto;
      margin-right: auto; } }
  @media only screen and (min-width: 1200px) {
    .content .component.image-spot.element-as-background .image-spot-element:nth-child(n + 2) {
      margin: 0; }
    .content .component.image-spot.element-as-background .image-spot-element:nth-child(1n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.element-as-background .image-spot-element:nth-child(n + 3) {
      margin: 0; }
    .content .component.image-spot.element-as-background .image-spot-element:nth-child(2n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.element-as-background .image-spot-element:nth-child(n + 4) {
      margin: 0; }
    .content .component.image-spot.element-as-background .image-spot-element:nth-child(3n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.element-as-background .image-spot-element:nth-child(n + 5) {
      margin: 0; }
    .content .component.image-spot.element-as-background .image-spot-element:nth-child(4n + 1):last-child {
      margin-left: 0;
      margin-right: 0; }
    .content .component.image-spot.element-as-background .image-spot-element:nth-child(n + 5) {
      margin-top: 40px; }
    .content .component.image-spot.element-as-background .image-spot-element:nth-child(4n + 1):last-child {
      margin-left: auto;
      margin-right: auto; } }
  .content .component.image-spot.element-as-background .image-spot-element:first-child:last-child {
    width: auto; }

.content .component.image-spot.element-as-background .image-spot-element-image {
  display: none; }

.content .component.image-spot.element-as-background .image-spot-element-header,
.content .component.image-spot.element-as-background .image-spot-element-header-inner {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
  margin: 0 auto; }

.content .component.image-spot.element-as-background .image-spot-element-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 56vw;
  height: 56vw; }
  @media only screen and (min-width: 768px) {
    .content .component.image-spot.element-as-background .image-spot-element-header {
      width: 38vw;
      height: 38vw; } }
  @media only screen and (min-width: 992px) {
    .content .component.image-spot.element-as-background .image-spot-element-header {
      width: 25vw;
      height: 25vw; } }
  @media only screen and (min-width: 1200px) {
    .content .component.image-spot.element-as-background .image-spot-element-header {
      width: 250px;
      height: 250px; } }
  .content .component.image-spot.element-as-background .image-spot-element-header p + p {
    margin-top: 0; }

.content .component.image-spot.element-as-background .image-spot-element-header-inner {
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .content .component.image-spot.element-as-background .image-spot-element-header-inner > p:nth-child(1) {
    font-size: 11px;
    font-weight: normal;
    color: #ffffff; }
    @media only screen and (min-width: 768px) {
      .content .component.image-spot.element-as-background .image-spot-element-header-inner > p:nth-child(1) {
        font-size: 13px; } }
  .content .component.image-spot.element-as-background .image-spot-element-header-inner > p:nth-child(2) {
    font-size: 20px;
    font-weight: 800;
    color: #74cecd; }
    @media only screen and (min-width: 768px) {
      .content .component.image-spot.element-as-background .image-spot-element-header-inner > p:nth-child(2) {
        font-size: 30px; } }
  .content .component.image-spot.element-as-background .image-spot-element-header-inner > p:nth-child(3) img,
  .content .component.image-spot.element-as-background .image-spot-element-header-inner img:nth-child(3) {
    max-width: 50px;
    height: auto; }

.content .component.image-spot.element-as-background .image-spot-element-text {
  text-align: center;
  font-weight: normal; }

@media only screen and (min-width: 992px) {
  .content .component.image-spot.element-as-background .image-spot-element {
    width: 40vw; }
  .content .component.image-spot.element-as-background .image-spot-element-image {
    margin: 0 0%; } }

@media only screen and (min-width: 1200px) {
  .content .component.image-spot.element-as-background .image-spot-element {
    width: 250px; }
  .content .component.image-spot.element-as-background .image-spot-element-image {
    margin: 0 0%; } }

.content .component.image-spot.element-as-background .image-spot-element-text,
.content .component.image-spot.element-as-background .image-spot-element-link {
  margin-top: 1em; }

@media only screen and (min-width: 768px) {
  .content .component.image-spot.four-in-a-row .component-content {
    gap: calc(20% / 3);
    justify-content: center; }
    .content .component.image-spot.four-in-a-row .component-content .image-spot-element {
      width: 20%;
      max-width: 20%;
      margin: 0; }
      .content .component.image-spot.four-in-a-row .component-content .image-spot-element:nth-child(n + 5) {
        margin: 60px 0 0 0; }
      .content .component.image-spot.four-in-a-row .component-content .image-spot-element:nth-child(2):nth-last-child(1):last-child {
        margin: 0; } }

.content .component.image-spot.four-in-a-row.align-left .component-content {
  justify-content: flex-start; }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

@keyframes in {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

@keyframes out {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
body.on-page-editor .component.text-image-spot {
  min-height: 60px; }

.text-image-spot.green-blue-gradient-bg {
  background-image: linear-gradient(to right, #74cbca, #3344f6);
  margin-top: 0; }
  .text-image-spot.green-blue-gradient-bg.first-repeating-background, .text-image-spot.green-blue-gradient-bg .repeating-background {
    margin-bottom: 0; }
  .text-image-spot.green-blue-gradient-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .text-image-spot.green-blue-gradient-bg.space-top {
        padding-top: 60px; } }
  .text-image-spot.green-blue-gradient-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .text-image-spot.green-blue-gradient-bg .component-content {
        padding: 30px 0; } }

.text-image-spot.charcoal-blue-bg {
  background-color: #24405b;
  margin-top: 0; }
  .text-image-spot.charcoal-blue-bg.first-repeating-background, .text-image-spot.charcoal-blue-bg .repeating-background {
    margin-bottom: 0; }
  .text-image-spot.charcoal-blue-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .text-image-spot.charcoal-blue-bg.space-top {
        padding-top: 60px; } }
  .text-image-spot.charcoal-blue-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .text-image-spot.charcoal-blue-bg .component-content {
        padding: 30px 0; } }

.text-image-spot.warm-gray-bg {
  background-color: #dcdcd6;
  margin-top: 0; }
  .text-image-spot.warm-gray-bg.first-repeating-background, .text-image-spot.warm-gray-bg .repeating-background {
    margin-bottom: 0; }
  .text-image-spot.warm-gray-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .text-image-spot.warm-gray-bg.space-top {
        padding-top: 60px; } }
  .text-image-spot.warm-gray-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .text-image-spot.warm-gray-bg .component-content {
        padding: 30px 0; } }

.text-image-spot.seafoam-green-50-bg {
  background-color: #badcdf;
  margin-top: 0; }
  .text-image-spot.seafoam-green-50-bg.first-repeating-background, .text-image-spot.seafoam-green-50-bg .repeating-background {
    margin-bottom: 0; }
  .text-image-spot.seafoam-green-50-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .text-image-spot.seafoam-green-50-bg.space-top {
        padding-top: 60px; } }
  .text-image-spot.seafoam-green-50-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .text-image-spot.seafoam-green-50-bg .component-content {
        padding: 30px 0; } }

.text-image-spot.sky-blue-75-bg {
  background-color: #bcdef7;
  margin-top: 0; }
  .text-image-spot.sky-blue-75-bg.first-repeating-background, .text-image-spot.sky-blue-75-bg .repeating-background {
    margin-bottom: 0; }
  .text-image-spot.sky-blue-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .text-image-spot.sky-blue-75-bg.space-top {
        padding-top: 60px; } }
  .text-image-spot.sky-blue-75-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .text-image-spot.sky-blue-75-bg .component-content {
        padding: 30px 0; } }

.text-image-spot.warm-grey-75-bg {
  background-color: #cacac1;
  margin-top: 0; }
  .text-image-spot.warm-grey-75-bg.first-repeating-background, .text-image-spot.warm-grey-75-bg .repeating-background {
    margin-bottom: 0; }
  .text-image-spot.warm-grey-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .text-image-spot.warm-grey-75-bg.space-top {
        padding-top: 60px; } }
  .text-image-spot.warm-grey-75-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .text-image-spot.warm-grey-75-bg .component-content {
        padding: 30px 0; } }

.text-image-spot.pine-green-75-bg {
  background-color: #609c9c;
  margin-top: 0; }
  .text-image-spot.pine-green-75-bg.first-repeating-background, .text-image-spot.pine-green-75-bg .repeating-background {
    margin-bottom: 0; }
  .text-image-spot.pine-green-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .text-image-spot.pine-green-75-bg.space-top {
        padding-top: 60px; } }
  .text-image-spot.pine-green-75-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .text-image-spot.pine-green-75-bg .component-content {
        padding: 30px 0; } }

.text-image-spot.burst-yellow-10-bg {
  background-color: #fcf7ea;
  margin-top: 0; }
  .text-image-spot.burst-yellow-10-bg.first-repeating-background, .text-image-spot.burst-yellow-10-bg .repeating-background {
    margin-bottom: 0; }
  .text-image-spot.burst-yellow-10-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .text-image-spot.burst-yellow-10-bg.space-top {
        padding-top: 60px; } }
  .text-image-spot.burst-yellow-10-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .text-image-spot.burst-yellow-10-bg .component-content {
        padding: 30px 0; } }

.text-image-spot.walnut-brown-bg {
  background-color: #6c4b42;
  margin-top: 0; }
  .text-image-spot.walnut-brown-bg.first-repeating-background, .text-image-spot.walnut-brown-bg .repeating-background {
    margin-bottom: 0; }
  .text-image-spot.walnut-brown-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .text-image-spot.walnut-brown-bg.space-top {
        padding-top: 60px; } }
  .text-image-spot.walnut-brown-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .text-image-spot.walnut-brown-bg .component-content {
        padding: 30px 0; } }

.text-image-spot ol,
.text-image-spot ul {
  list-style: none;
  list-style-position: inside;
  padding-left: 12px; }

.text-image-spot ol {
  counter-reset: demant-counter;
  padding-left: 50px; }
  @media only screen and (min-width: 768px) {
    .text-image-spot ol {
      padding-left: 70px; } }

.text-image-spot ol li,
.text-image-spot ul li {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.4;
  position: relative;
  box-sizing: border-box; }
  @media only screen and (min-width: 768px) {
    .text-image-spot ol li,
    .text-image-spot ul li {
      font-size: 15px; } }

.text-image-spot ol li {
  counter-increment: demant-counter;
  min-height: 35px;
  display: flex;
  align-items: center; }
  .text-image-spot ol li + li {
    margin-top: 0.5rem; }
  @media only screen and (min-width: 768px) {
    .text-image-spot ol li {
      min-height: 50px; } }

.text-image-spot ol li::before,
.text-image-spot ul li::before {
  content: "";
  display: inline-block;
  background-color: #24405b;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  left: -11px;
  top: 0.5em;
  width: 4px;
  height: 4px; }

.text-image-spot ol li::before {
  content: counter(demant-counter);
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  left: -50px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%); }
  @media only screen and (min-width: 768px) {
    .text-image-spot ol li::before {
      font-size: 18px; } }
  @media only screen and (min-width: 768px) {
    .text-image-spot ol li::before {
      left: -70px;
      width: 50px;
      height: 50px; } }

.text-image-spot.bullets-grey ol,
.text-image-spot.bullets-grey ul {
  list-style: none;
  list-style-position: inside;
  padding-left: 12px; }

.text-image-spot.bullets-grey ol {
  counter-reset: demant-counter;
  padding-left: 50px; }
  @media only screen and (min-width: 768px) {
    .text-image-spot.bullets-grey ol {
      padding-left: 70px; } }

.text-image-spot.bullets-grey ol li,
.text-image-spot.bullets-grey ul li {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.4;
  position: relative;
  box-sizing: border-box; }
  @media only screen and (min-width: 768px) {
    .text-image-spot.bullets-grey ol li,
    .text-image-spot.bullets-grey ul li {
      font-size: 15px; } }

.text-image-spot.bullets-grey ol li {
  counter-increment: demant-counter;
  min-height: 35px;
  display: flex;
  align-items: center; }
  .text-image-spot.bullets-grey ol li + li {
    margin-top: 0.5rem; }
  @media only screen and (min-width: 768px) {
    .text-image-spot.bullets-grey ol li {
      min-height: 50px; } }

.text-image-spot.bullets-grey ol li::before,
.text-image-spot.bullets-grey ul li::before {
  content: "";
  display: inline-block;
  background-color: #b9b9ad;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  left: -11px;
  top: 0.5em;
  width: 4px;
  height: 4px; }

.text-image-spot.bullets-grey ol li::before {
  content: counter(demant-counter);
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  left: -50px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%); }
  @media only screen and (min-width: 768px) {
    .text-image-spot.bullets-grey ol li::before {
      font-size: 18px; } }
  @media only screen and (min-width: 768px) {
    .text-image-spot.bullets-grey ol li::before {
      left: -70px;
      width: 50px;
      height: 50px; } }

.text-image-spot.bullets-green ol,
.text-image-spot.bullets-green ul {
  list-style: none;
  list-style-position: inside;
  padding-left: 12px; }

.text-image-spot.bullets-green ol {
  counter-reset: demant-counter;
  padding-left: 50px; }
  @media only screen and (min-width: 768px) {
    .text-image-spot.bullets-green ol {
      padding-left: 70px; } }

.text-image-spot.bullets-green ol li,
.text-image-spot.bullets-green ul li {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.4;
  position: relative;
  box-sizing: border-box; }
  @media only screen and (min-width: 768px) {
    .text-image-spot.bullets-green ol li,
    .text-image-spot.bullets-green ul li {
      font-size: 15px; } }

.text-image-spot.bullets-green ol li {
  counter-increment: demant-counter;
  min-height: 35px;
  display: flex;
  align-items: center; }
  .text-image-spot.bullets-green ol li + li {
    margin-top: 0.5rem; }
  @media only screen and (min-width: 768px) {
    .text-image-spot.bullets-green ol li {
      min-height: 50px; } }

.text-image-spot.bullets-green ol li::before,
.text-image-spot.bullets-green ul li::before {
  content: "";
  display: inline-block;
  background-color: #74cecd;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  left: -11px;
  top: 0.5em;
  width: 4px;
  height: 4px; }

.text-image-spot.bullets-green ol li::before {
  content: counter(demant-counter);
  font-weight: 600;
  font-size: 15px;
  color: #24405b;
  left: -50px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%); }
  @media only screen and (min-width: 768px) {
    .text-image-spot.bullets-green ol li::before {
      font-size: 18px; } }
  @media only screen and (min-width: 768px) {
    .text-image-spot.bullets-green ol li::before {
      left: -70px;
      width: 50px;
      height: 50px; } }

.text-image-spot.bullets-fat ol li::before,
.text-image-spot.bullets-fat ul li::before {
  top: 50%;
  transform: translateY(-50%); }

.text-image-spot.bullets-fat ul li {
  padding-left: 2rem;
  margin: 0 0 25px 0; }
  .text-image-spot.bullets-fat ul li::before {
    width: 15px;
    height: 15px; }
  @media only screen and (min-width: 768px) {
    .text-image-spot.bullets-fat ul li {
      margin: 0 0 51px 0; }
      .text-image-spot.bullets-fat ul li::before {
        width: 30px;
        height: 30px; } }

.text-image-spot.charcoal-blue-bg ol,
.text-image-spot.charcoal-blue-bg ul {
  list-style: none;
  list-style-position: inside;
  padding-left: 12px; }

.text-image-spot.charcoal-blue-bg ol {
  counter-reset: demant-counter;
  padding-left: 50px; }
  @media only screen and (min-width: 768px) {
    .text-image-spot.charcoal-blue-bg ol {
      padding-left: 70px; } }

.text-image-spot.charcoal-blue-bg ol li,
.text-image-spot.charcoal-blue-bg ul li {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.4;
  position: relative;
  box-sizing: border-box; }
  @media only screen and (min-width: 768px) {
    .text-image-spot.charcoal-blue-bg ol li,
    .text-image-spot.charcoal-blue-bg ul li {
      font-size: 15px; } }

.text-image-spot.charcoal-blue-bg ol li {
  counter-increment: demant-counter;
  min-height: 35px;
  display: flex;
  align-items: center; }
  .text-image-spot.charcoal-blue-bg ol li + li {
    margin-top: 0.5rem; }
  @media only screen and (min-width: 768px) {
    .text-image-spot.charcoal-blue-bg ol li {
      min-height: 50px; } }

.text-image-spot.charcoal-blue-bg ol li::before,
.text-image-spot.charcoal-blue-bg ul li::before {
  content: "";
  display: inline-block;
  background-color: #74cecd;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  left: -11px;
  top: 0.5em;
  width: 4px;
  height: 4px; }

.text-image-spot.charcoal-blue-bg ol li::before {
  content: counter(demant-counter);
  font-weight: 600;
  font-size: 15px;
  color: #24405b;
  left: -50px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%); }
  @media only screen and (min-width: 768px) {
    .text-image-spot.charcoal-blue-bg ol li::before {
      font-size: 18px; } }
  @media only screen and (min-width: 768px) {
    .text-image-spot.charcoal-blue-bg ol li::before {
      left: -70px;
      width: 50px;
      height: 50px; } }

.text-image-spot.warm-gray-bg ol,
.text-image-spot.warm-gray-bg ul {
  list-style: none;
  list-style-position: inside;
  padding-left: 12px; }

.text-image-spot.warm-gray-bg ol {
  counter-reset: demant-counter;
  padding-left: 50px; }
  @media only screen and (min-width: 768px) {
    .text-image-spot.warm-gray-bg ol {
      padding-left: 70px; } }

.text-image-spot.warm-gray-bg ol li,
.text-image-spot.warm-gray-bg ul li {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.4;
  position: relative;
  box-sizing: border-box; }
  @media only screen and (min-width: 768px) {
    .text-image-spot.warm-gray-bg ol li,
    .text-image-spot.warm-gray-bg ul li {
      font-size: 15px; } }

.text-image-spot.warm-gray-bg ol li {
  counter-increment: demant-counter;
  min-height: 35px;
  display: flex;
  align-items: center; }
  .text-image-spot.warm-gray-bg ol li + li {
    margin-top: 0.5rem; }
  @media only screen and (min-width: 768px) {
    .text-image-spot.warm-gray-bg ol li {
      min-height: 50px; } }

.text-image-spot.warm-gray-bg ol li::before,
.text-image-spot.warm-gray-bg ul li::before {
  content: "";
  display: inline-block;
  background-color: #24405b;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  left: -11px;
  top: 0.5em;
  width: 4px;
  height: 4px; }

.text-image-spot.warm-gray-bg ol li::before {
  content: counter(demant-counter);
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  left: -50px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%); }
  @media only screen and (min-width: 768px) {
    .text-image-spot.warm-gray-bg ol li::before {
      font-size: 18px; } }
  @media only screen and (min-width: 768px) {
    .text-image-spot.warm-gray-bg ol li::before {
      left: -70px;
      width: 50px;
      height: 50px; } }

.text-image-spot .component-content {
  align-items: center; }
  @media only screen and (min-width: 768px) {
    .text-image-spot .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 1600px; }
      .text-image-spot .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .text-image-spot .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .text-image-spot .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }
  @media only screen and (min-width: 992px) {
    .text-image-spot .component-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; } }
  .text-image-spot .component-content * {
    box-sizing: border-box; }

.text-image-spot .text-image-spot-image-container {
  margin-bottom: 30px; }
  .text-image-spot .text-image-spot-image-container img {
    max-width: 100%;
    height: auto; }
  @media only screen and (min-width: 992px) {
    .text-image-spot .text-image-spot-image-container {
      margin-bottom: 0;
      width: 40%;
      padding-right: 30px; } }

@media only screen and (min-width: 992px) {
  .text-image-spot .text-image-spot-text-container {
    width: 60%; } }

.text-image-spot .text-image-spot-readmore {
  display: none;
  width: 100%;
  margin-top: 20px; }
  @media only screen and (min-width: 768px) {
    .text-image-spot .text-image-spot-readmore {
      margin-top: 30px; } }
  .text-image-spot .text-image-spot-readmore-open, .text-image-spot .text-image-spot-readmore-less {
    margin-top: 20px; }
    @media only screen and (min-width: 768px) {
      .text-image-spot .text-image-spot-readmore-open, .text-image-spot .text-image-spot-readmore-less {
        margin-top: 30px; } }
  .text-image-spot .text-image-spot-readmore-close {
    display: none; }

.text-image-spot.read-more-open .text-image-spot-readmore {
  display: block; }

.text-image-spot .text-image-spot-readmore-open {
  animation: out 0.7s -0.1s ease-in-out forwards;
  background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
  background-size: 420% 100%;
  background-position: right bottom;
  background-color: #3344f6;
  color: #ffffff;
  transition: all 0.4s ease-in-out;
  font-size: 12px;
  color: #ffffff;
  background-color: #3344f6;
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: bold;
  border-radius: 19.5px;
  padding: 11px 26px 12px; }
  .text-image-spot .text-image-spot-readmore-open:hover {
    animation: in 0.7s -0.1s ease-in-out forwards;
    background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
    background-size: 420% 100%;
    background-position: right bottom;
    background-color: #3344f6;
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
    color: #3344f6;
    transition: all 0.8s ease-in-out; }
    .text-image-spot .text-image-spot-readmore-open:hover a {
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
  @media only screen and (min-width: 768px) {
    .text-image-spot .text-image-spot-readmore-open {
      font-size: 15px; } }
  .text-image-spot .text-image-spot-readmore-open a {
    color: #ffffff;
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .text-image-spot .text-image-spot-readmore-open {
      border-radius: 25px;
      padding: 14px 35px 15px; } }

.text-image-spot .text-image-spot-readmore-less {
  display: none; }

.text-image-spot.read-more-open .text-image-spot-readmore-open {
  display: none; }

.text-image-spot.read-more-open .text-image-spot-readmore-less {
  animation: out 0.7s -0.1s ease-in-out forwards;
  background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
  background-size: 420% 100%;
  background-position: right bottom;
  background-color: #3344f6;
  color: #ffffff;
  transition: all 0.4s ease-in-out;
  font-size: 12px;
  color: #ffffff;
  background-color: #3344f6;
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: bold;
  border-radius: 19.5px;
  padding: 11px 26px 12px; }
  .text-image-spot.read-more-open .text-image-spot-readmore-less:hover {
    animation: in 0.7s -0.1s ease-in-out forwards;
    background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
    background-size: 420% 100%;
    background-position: right bottom;
    background-color: #3344f6;
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
    color: #3344f6;
    transition: all 0.8s ease-in-out; }
    .text-image-spot.read-more-open .text-image-spot-readmore-less:hover a {
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
  @media only screen and (min-width: 768px) {
    .text-image-spot.read-more-open .text-image-spot-readmore-less {
      font-size: 15px; } }
  .text-image-spot.read-more-open .text-image-spot-readmore-less a {
    color: #ffffff;
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .text-image-spot.read-more-open .text-image-spot-readmore-less {
      border-radius: 25px;
      padding: 14px 35px 15px; } }

.text-image-spot.white-buttons .text-image-spot-readmore-open {
  animation: out 0.7s -0.1s ease-in-out forwards;
  background: radial-gradient(50% 300% at right, #3344f6 50%, transparent 50%), radial-gradient(circle at right, #ffffff 100%, transparent 100%);
  background-size: 420% 100%;
  background-position: right bottom;
  background-color: #ffffff;
  color: #3344f6;
  transition: all 0.4s ease-in-out;
  font-size: 12px;
  color: #ffffff;
  background-color: #3344f6;
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: bold;
  border-radius: 19.5px;
  padding: 11px 26px 12px;
  color: #3344f6;
  background-color: #ffffff; }
  .text-image-spot.white-buttons .text-image-spot-readmore-open:hover {
    animation: in 0.7s -0.1s ease-in-out forwards;
    background: radial-gradient(50% 300% at left, #3344f6 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(51, 68, 246, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #ffffff 100%, transparent 100%);
    background-size: 420% 100%;
    background-position: right bottom;
    background-color: #ffffff;
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
    color: #ffffff;
    transition: all 0.8s ease-in-out; }
    .text-image-spot.white-buttons .text-image-spot-readmore-open:hover a {
      color: #ffffff;
      transition: all 0.8s ease-in-out; }
  @media only screen and (min-width: 768px) {
    .text-image-spot.white-buttons .text-image-spot-readmore-open {
      font-size: 15px; } }
  .text-image-spot.white-buttons .text-image-spot-readmore-open a {
    color: #ffffff;
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .text-image-spot.white-buttons .text-image-spot-readmore-open {
      border-radius: 25px;
      padding: 14px 35px 15px; } }
  .text-image-spot.white-buttons .text-image-spot-readmore-open a {
    color: #3344f6; }

.text-image-spot.white-buttons .text-image-spot-readmore-less {
  display: none; }

.text-image-spot.white-buttons.read-more-open .text-image-spot-readmore-open {
  display: none; }

.text-image-spot.white-buttons.read-more-open .text-image-spot-readmore-less {
  animation: out 0.7s -0.1s ease-in-out forwards;
  background: radial-gradient(50% 300% at right, #3344f6 50%, transparent 50%), radial-gradient(circle at right, #ffffff 100%, transparent 100%);
  background-size: 420% 100%;
  background-position: right bottom;
  background-color: #ffffff;
  color: #3344f6;
  transition: all 0.4s ease-in-out;
  font-size: 12px;
  color: #ffffff;
  background-color: #3344f6;
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: bold;
  border-radius: 19.5px;
  padding: 11px 26px 12px;
  color: #3344f6;
  background-color: #ffffff; }
  .text-image-spot.white-buttons.read-more-open .text-image-spot-readmore-less:hover {
    animation: in 0.7s -0.1s ease-in-out forwards;
    background: radial-gradient(50% 300% at left, #3344f6 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(51, 68, 246, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #ffffff 100%, transparent 100%);
    background-size: 420% 100%;
    background-position: right bottom;
    background-color: #ffffff;
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
    color: #ffffff;
    transition: all 0.8s ease-in-out; }
    .text-image-spot.white-buttons.read-more-open .text-image-spot-readmore-less:hover a {
      color: #ffffff;
      transition: all 0.8s ease-in-out; }
  @media only screen and (min-width: 768px) {
    .text-image-spot.white-buttons.read-more-open .text-image-spot-readmore-less {
      font-size: 15px; } }
  .text-image-spot.white-buttons.read-more-open .text-image-spot-readmore-less a {
    color: #ffffff;
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .text-image-spot.white-buttons.read-more-open .text-image-spot-readmore-less {
      border-radius: 25px;
      padding: 14px 35px 15px; } }
  .text-image-spot.white-buttons.read-more-open .text-image-spot-readmore-less a {
    color: #3344f6; }

.text-image-spot .clearfix {
  display: none; }

@media only screen and (min-width: 992px) {
  .text-image-spot.reverse .component-content {
    flex-direction: row-reverse; }
    .text-image-spot.reverse .component-content .text-image-spot-image-container {
      padding-right: 0;
      padding-left: 30px; } }

.text-image-spot.slim .component-content {
  margin-left: 30px;
  margin-right: 30px; }
  @media only screen and (min-width: 768px) {
    .text-image-spot.slim .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      .text-image-spot.slim .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .text-image-spot.slim .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .text-image-spot.slim .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }

.text-image-spot.round-image .text-image-spot-image img {
  border-radius: 50%; }

.text-image-spot.center-txt .text-image-spot-text,
.text-image-spot.center-txt .text-image-spot-readmore-text {
  text-align: center; }

.text-image-spot.center-txt .text-image-spot-readmore-open,
.text-image-spot.center-txt .text-image-spot-readmore-less {
  position: relative;
  left: 50%;
  transform: translateX(-50%); }

@media only screen and (min-width: 992px) {
  .text-image-spot.text-top .component-content {
    display: flex;
    align-items: start; } }

body.on-page-editor .component.iframe {
  min-height: 60px; }

.content .component.iframe .component-content {
  min-height: 70vh; }
  @media only screen and (min-width: 768px) {
    .content .component.iframe .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 1600px; }
      .content .component.iframe .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .content .component.iframe .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .content .component.iframe .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }
  @media only screen and (min-width: 992px) {
    .content .component.iframe .component-content {
      min-height: 50vh; } }
  .content .component.iframe .component-content iframe {
    width: 100%; }

.content .component.iframe.instrument .component-content {
  max-width: 340px;
  width: 100%;
  margin: 0 auto; }

.content .component.iframe.green-blue-gradient-bg {
  background-image: linear-gradient(to right, #74cbca, #3344f6);
  margin-top: 0; }
  .content .component.iframe.green-blue-gradient-bg.first-repeating-background, .content .component.iframe.green-blue-gradient-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.iframe.green-blue-gradient-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.iframe.green-blue-gradient-bg.space-top {
        padding-top: 60px; } }
  .content .component.iframe.green-blue-gradient-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .content .component.iframe.green-blue-gradient-bg .component-content {
        padding: 30px 0; } }

.content .component.iframe.charcoal-blue-bg {
  background-color: #24405b;
  margin-top: 0; }
  .content .component.iframe.charcoal-blue-bg.first-repeating-background, .content .component.iframe.charcoal-blue-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.iframe.charcoal-blue-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.iframe.charcoal-blue-bg.space-top {
        padding-top: 60px; } }
  .content .component.iframe.charcoal-blue-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .content .component.iframe.charcoal-blue-bg .component-content {
        padding: 30px 0; } }

.content .component.iframe.warm-gray-bg {
  background-color: #dcdcd6;
  margin-top: 0; }
  .content .component.iframe.warm-gray-bg.first-repeating-background, .content .component.iframe.warm-gray-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.iframe.warm-gray-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.iframe.warm-gray-bg.space-top {
        padding-top: 60px; } }
  .content .component.iframe.warm-gray-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .content .component.iframe.warm-gray-bg .component-content {
        padding: 30px 0; } }

.content .component.iframe.seafoam-green-50-bg {
  background-color: #badcdf;
  margin-top: 0; }
  .content .component.iframe.seafoam-green-50-bg.first-repeating-background, .content .component.iframe.seafoam-green-50-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.iframe.seafoam-green-50-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.iframe.seafoam-green-50-bg.space-top {
        padding-top: 60px; } }
  .content .component.iframe.seafoam-green-50-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .content .component.iframe.seafoam-green-50-bg .component-content {
        padding: 30px 0; } }

.content .component.iframe.sky-blue-75-bg {
  background-color: #bcdef7;
  margin-top: 0; }
  .content .component.iframe.sky-blue-75-bg.first-repeating-background, .content .component.iframe.sky-blue-75-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.iframe.sky-blue-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.iframe.sky-blue-75-bg.space-top {
        padding-top: 60px; } }
  .content .component.iframe.sky-blue-75-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .content .component.iframe.sky-blue-75-bg .component-content {
        padding: 30px 0; } }

.content .component.iframe.warm-grey-75-bg {
  background-color: #cacac1;
  margin-top: 0; }
  .content .component.iframe.warm-grey-75-bg.first-repeating-background, .content .component.iframe.warm-grey-75-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.iframe.warm-grey-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.iframe.warm-grey-75-bg.space-top {
        padding-top: 60px; } }
  .content .component.iframe.warm-grey-75-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .content .component.iframe.warm-grey-75-bg .component-content {
        padding: 30px 0; } }

.content .component.iframe.pine-green-75-bg {
  background-color: #609c9c;
  margin-top: 0; }
  .content .component.iframe.pine-green-75-bg.first-repeating-background, .content .component.iframe.pine-green-75-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.iframe.pine-green-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.iframe.pine-green-75-bg.space-top {
        padding-top: 60px; } }
  .content .component.iframe.pine-green-75-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .content .component.iframe.pine-green-75-bg .component-content {
        padding: 30px 0; } }

.content .component.iframe.burst-yellow-10-bg {
  background-color: #fcf7ea;
  margin-top: 0; }
  .content .component.iframe.burst-yellow-10-bg.first-repeating-background, .content .component.iframe.burst-yellow-10-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.iframe.burst-yellow-10-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.iframe.burst-yellow-10-bg.space-top {
        padding-top: 60px; } }
  .content .component.iframe.burst-yellow-10-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .content .component.iframe.burst-yellow-10-bg .component-content {
        padding: 30px 0; } }

.content .component.iframe.walnut-brown-bg {
  background-color: #6c4b42;
  margin-top: 0; }
  .content .component.iframe.walnut-brown-bg.first-repeating-background, .content .component.iframe.walnut-brown-bg .repeating-background {
    margin-bottom: 0; }
  .content .component.iframe.walnut-brown-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .content .component.iframe.walnut-brown-bg.space-top {
        padding-top: 60px; } }
  .content .component.iframe.walnut-brown-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .content .component.iframe.walnut-brown-bg .component-content {
        padding: 30px 0; } }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
body.on-page-editor .component.box-spot {
  min-height: 60px; }

.content .component.box-spot .component-content {
  margin-left: 30px;
  margin-right: 30px;
  margin: 0;
  display: flex;
  flex-direction: column; }
  @media only screen and (min-width: 768px) {
    .content .component.box-spot .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      .content .component.box-spot .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .content .component.box-spot .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .content .component.box-spot .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }
  @media only screen and (min-width: 992px) {
    .content .component.box-spot .component-content {
      flex-direction: row; } }
  .content .component.box-spot .component-content .box-spot-item-outer {
    color: #24405b;
    display: block;
    width: 100%;
    text-decoration: none; }
    @media only screen and (min-width: 992px) {
      .content .component.box-spot .component-content .box-spot-item-outer {
        width: 50%; } }
    .content .component.box-spot .component-content .box-spot-item-outer:nth-child(1) {
      background-color: #74cecd; }
    .content .component.box-spot .component-content .box-spot-item-outer:nth-child(2) {
      background-color: #dcdcd6; }
  .content .component.box-spot .component-content .box-spot-item {
    width: 100%;
    display: flex;
    min-height: 180px;
    box-sizing: border-box;
    padding: 1em 2em 1em 1em;
    align-items: center;
    flex: 1; }
  .content .component.box-spot .component-content .box-spot-item-image {
    width: 19%;
    padding-right: 1em; }
    .content .component.box-spot .component-content .box-spot-item-image img {
      width: 100%;
      height: auto; }
  .content .component.box-spot .component-content .box-spot-item-text {
    width: calc(100% - 19%);
    padding-left: 1em; }

.content .component.box-spot.small .component-content {
  margin-left: 30px;
  margin-right: 30px; }
  @media only screen and (min-width: 768px) {
    .content .component.box-spot.small .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 47.2%;
      max-width: 660px; }
      .content .component.box-spot.small .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .content .component.box-spot.small .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .content .component.box-spot.small .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }
  .content .component.box-spot.small .component-content .box-spot-item {
    position: relative;
    min-height: 125px; }
    .content .component.box-spot.small .component-content .box-spot-item .box-spot-item-image {
      display: none; }
    .content .component.box-spot.small .component-content .box-spot-item .box-spot-item-text h1 {
      font-size: 22px; }
    .content .component.box-spot.small .component-content .box-spot-item .box-spot-item-text::after {
      font-family: "Material Icons";
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      font-feature-settings: "liga";
      -webkit-font-feature-settings: "liga";
      -webkit-font-smoothing: antialiased;
      content: "arrow_forward";
      bottom: 5%;
      font-size: 50px;
      position: absolute;
      right: 5%; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

body.on-page-editor .component.video-spot {
  min-height: 60px; }

.component.video-spot .component-content {
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  justify-content: center; }
  @media only screen and (min-width: 768px) {
    .component.video-spot .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 47.2%;
      max-width: 660px; }
      .component.video-spot .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .component.video-spot .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .component.video-spot .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }
  .component.video-spot .component-content .video-spot-container {
    width: 100%; }
  .component.video-spot .component-content .video-spot-video {
    position: relative;
    cursor: pointer; }
    .component.video-spot .component-content .video-spot-video .video-spot-image img {
      width: 100%;
      height: auto; }
    .component.video-spot .component-content .video-spot-video .video-spot-play-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      font-size: 70px; }
      .component.video-spot .component-content .video-spot-video .video-spot-play-btn .like-button-minimal {
        animation: out 0.7s -0.1s ease-in-out forwards;
        background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
        background-size: 420% 100%;
        background-position: right bottom;
        background-color: #3344f6;
        color: #ffffff;
        transition: all 0.4s ease-in-out;
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        font-feature-settings: "liga";
        -webkit-font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
        border-radius: 50%;
        display: block;
        height: 48px;
        line-height: 48px;
        padding: 0;
        margin: 0;
        text-align: center;
        width: 48px; }
    .component.video-spot .component-content .video-spot-video:hover .like-button-minimal {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
      .component.video-spot .component-content .video-spot-video:hover .like-button-minimal a {
        color: #3344f6;
        transition: all 0.8s ease-in-out; }
  .component.video-spot .component-content code {
    display: none; }

.component.video-spot.green-blue-gradient-bg {
  background-image: linear-gradient(to right, #74cbca, #3344f6);
  margin-top: 0; }
  .component.video-spot.green-blue-gradient-bg.first-repeating-background, .component.video-spot.green-blue-gradient-bg .repeating-background {
    margin-bottom: 0; }
  .component.video-spot.green-blue-gradient-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.video-spot.green-blue-gradient-bg.space-top {
        padding-top: 60px; } }
  .component.video-spot.green-blue-gradient-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.video-spot.green-blue-gradient-bg .component-content {
        padding: 30px 0; } }

.component.video-spot.charcoal-blue-bg {
  background-color: #24405b;
  margin-top: 0; }
  .component.video-spot.charcoal-blue-bg.first-repeating-background, .component.video-spot.charcoal-blue-bg .repeating-background {
    margin-bottom: 0; }
  .component.video-spot.charcoal-blue-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.video-spot.charcoal-blue-bg.space-top {
        padding-top: 60px; } }
  .component.video-spot.charcoal-blue-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.video-spot.charcoal-blue-bg .component-content {
        padding: 30px 0; } }

.component.video-spot.warm-gray-bg {
  background-color: #dcdcd6;
  margin-top: 0; }
  .component.video-spot.warm-gray-bg.first-repeating-background, .component.video-spot.warm-gray-bg .repeating-background {
    margin-bottom: 0; }
  .component.video-spot.warm-gray-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.video-spot.warm-gray-bg.space-top {
        padding-top: 60px; } }
  .component.video-spot.warm-gray-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.video-spot.warm-gray-bg .component-content {
        padding: 30px 0; } }

.component.video-spot.seafoam-green-50-bg {
  background-color: #badcdf;
  margin-top: 0; }
  .component.video-spot.seafoam-green-50-bg.first-repeating-background, .component.video-spot.seafoam-green-50-bg .repeating-background {
    margin-bottom: 0; }
  .component.video-spot.seafoam-green-50-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.video-spot.seafoam-green-50-bg.space-top {
        padding-top: 60px; } }
  .component.video-spot.seafoam-green-50-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.video-spot.seafoam-green-50-bg .component-content {
        padding: 30px 0; } }

.component.video-spot.sky-blue-75-bg {
  background-color: #bcdef7;
  margin-top: 0; }
  .component.video-spot.sky-blue-75-bg.first-repeating-background, .component.video-spot.sky-blue-75-bg .repeating-background {
    margin-bottom: 0; }
  .component.video-spot.sky-blue-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.video-spot.sky-blue-75-bg.space-top {
        padding-top: 60px; } }
  .component.video-spot.sky-blue-75-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.video-spot.sky-blue-75-bg .component-content {
        padding: 30px 0; } }

.component.video-spot.warm-grey-75-bg {
  background-color: #cacac1;
  margin-top: 0; }
  .component.video-spot.warm-grey-75-bg.first-repeating-background, .component.video-spot.warm-grey-75-bg .repeating-background {
    margin-bottom: 0; }
  .component.video-spot.warm-grey-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.video-spot.warm-grey-75-bg.space-top {
        padding-top: 60px; } }
  .component.video-spot.warm-grey-75-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.video-spot.warm-grey-75-bg .component-content {
        padding: 30px 0; } }

.component.video-spot.pine-green-75-bg {
  background-color: #609c9c;
  margin-top: 0; }
  .component.video-spot.pine-green-75-bg.first-repeating-background, .component.video-spot.pine-green-75-bg .repeating-background {
    margin-bottom: 0; }
  .component.video-spot.pine-green-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.video-spot.pine-green-75-bg.space-top {
        padding-top: 60px; } }
  .component.video-spot.pine-green-75-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.video-spot.pine-green-75-bg .component-content {
        padding: 30px 0; } }

.component.video-spot.burst-yellow-10-bg {
  background-color: #fcf7ea;
  margin-top: 0; }
  .component.video-spot.burst-yellow-10-bg.first-repeating-background, .component.video-spot.burst-yellow-10-bg .repeating-background {
    margin-bottom: 0; }
  .component.video-spot.burst-yellow-10-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.video-spot.burst-yellow-10-bg.space-top {
        padding-top: 60px; } }
  .component.video-spot.burst-yellow-10-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.video-spot.burst-yellow-10-bg .component-content {
        padding: 30px 0; } }

.component.video-spot.walnut-brown-bg {
  background-color: #6c4b42;
  margin-top: 0; }
  .component.video-spot.walnut-brown-bg.first-repeating-background, .component.video-spot.walnut-brown-bg .repeating-background {
    margin-bottom: 0; }
  .component.video-spot.walnut-brown-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.video-spot.walnut-brown-bg.space-top {
        padding-top: 60px; } }
  .component.video-spot.walnut-brown-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.video-spot.walnut-brown-bg .component-content {
        padding: 30px 0; } }

.component.video-spot.wide-thumbnail .component-content {
  margin-left: 30px;
  margin-right: 30px; }
  @media only screen and (min-width: 768px) {
    .component.video-spot.wide-thumbnail .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      .component.video-spot.wide-thumbnail .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .component.video-spot.wide-thumbnail .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .component.video-spot.wide-thumbnail .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }
  @media only screen and (min-width: 992px) {
    .component.video-spot.wide-thumbnail .component-content .video-spot-video .video-spot-play-btn .like-button-minimal {
      height: 96px;
      width: 96px;
      line-height: 96px;
      font-size: calc(96px / 2); } }

.video-spot-overlay {
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999; }
  .video-spot-overlay .video-spot-overlay-close {
    font-size: 35px;
    width: 1em;
    height: 1em;
    position: fixed;
    top: 0.5em;
    right: 0.5em;
    cursor: pointer; }
    @media only screen and (min-width: 768px) {
      .video-spot-overlay .video-spot-overlay-close {
        font-size: 70px; } }
    @media only screen and (min-width: 992px) {
      .video-spot-overlay .video-spot-overlay-close {
        font-size: 55px; } }
    @media only screen and (min-width: 1200px) {
      .video-spot-overlay .video-spot-overlay-close {
        font-size: 70px; } }
    .video-spot-overlay .video-spot-overlay-close::after {
      font-family: "Material Icons";
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      font-feature-settings: "liga";
      -webkit-font-feature-settings: "liga";
      -webkit-font-smoothing: antialiased;
      content: "close";
      position: absolute;
      top: 0;
      left: 0;
      speak: none;
      font-size: 1em;
      color: #ffffff; }
  .video-spot-overlay .video-spot-overlay-video {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center; }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
/********************* global slider animations ******************/
@keyframes timeline-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.component.vertical-line-spot {
  margin-left: 30px;
  margin-right: 30px;
  margin: 0;
  padding-bottom: 40px; }
  .component.vertical-line-spot .vertical-line-header,
  .component.vertical-line-spot .vertical-line,
  .component.vertical-line-spot .vertical-line-cta,
  .component.vertical-line-spot ul {
    display: none; }
  @media only screen and (min-width: 768px) {
    .component.vertical-line-spot {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      .component.vertical-line-spot.full-width {
        width: 100%;
        max-width: 1600px; }
      .component.vertical-line-spot.regular {
        width: 86%;
        max-width: 1200px; }
      .component.vertical-line-spot.slim {
        width: 47.2%;
        max-width: 660px; } }
  @media only screen and (min-width: 992px) {
    .component.vertical-line-spot {
      margin: auto; } }
  .component.vertical-line-spot .component-content {
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column; }
    @media only screen and (min-width: 992px) {
      .component.vertical-line-spot .component-content {
        flex-direction: row; } }
    .component.vertical-line-spot .component-content .timeline-dates {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 0;
      box-sizing: border-box; }
      .component.vertical-line-spot .component-content .timeline-dates .timeline-arrow {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        display: none; }
      @media only screen and (min-width: 992px) {
        .component.vertical-line-spot .component-content .timeline-dates {
          flex-direction: row;
          height: 50vw;
          max-height: 75vh;
          width: 10%;
          max-width: 250px;
          padding: 2.3vw 0;
          justify-content: center;
          position: relative; }
          .component.vertical-line-spot .component-content .timeline-dates .timeline-arrow {
            display: inline-block;
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            width: 55px;
            cursor: pointer;
            user-select: none; }
            .component.vertical-line-spot .component-content .timeline-dates .timeline-arrow i {
              font-size: 55px; }
            .component.vertical-line-spot .component-content .timeline-dates .timeline-arrow:first-child {
              top: -12px; }
            .component.vertical-line-spot .component-content .timeline-dates .timeline-arrow:last-child {
              bottom: -12px; }
            .component.vertical-line-spot .component-content .timeline-dates .timeline-arrow.disabled {
              color: rgba(36, 64, 91, 0.3); } }
      .component.vertical-line-spot .component-content .timeline-dates .date-selector {
        order: 2; }
        @media only screen and (min-width: 992px) {
          .component.vertical-line-spot .component-content .timeline-dates .date-selector {
            order: 1;
            position: relative;
            top: -12px; } }
        .component.vertical-line-spot .component-content .timeline-dates .date-selector .timeline {
          padding: 24px 0;
          display: flex; }
          @media only screen and (min-width: 992px) {
            .component.vertical-line-spot .component-content .timeline-dates .date-selector .timeline {
              height: 59.7vh;
              margin-right: 10px; } }
      .component.vertical-line-spot .component-content .timeline-dates .date-container {
        order: 1;
        height: 100%;
        display: flex;
        justify-content: space-between;
        box-sizing: content-box;
        position: relative;
        overflow-x: scroll;
        overflow-y: hidden;
        text-align: center;
        -webkit-overflow-scrolling: touch; }
        .component.vertical-line-spot .component-content .timeline-dates .date-container__overlay {
          pointer-events: none;
          width: 2rem;
          position: absolute;
          right: 0;
          top: 0;
          bottom: -2rem;
          background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 0.8) 61%, white 100%); }
          .component.vertical-line-spot .component-content .timeline-dates .date-container__overlay--left {
            left: 0;
            right: auto;
            background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 0.8) 61%, white 100%); }
          @media only screen and (min-width: 992px) {
            .component.vertical-line-spot .component-content .timeline-dates .date-container__overlay {
              display: none; } }
        @media only screen and (min-width: 992px) {
          .component.vertical-line-spot .component-content .timeline-dates .date-container {
            order: 2;
            flex-direction: column;
            justify-content: flex-start;
            position: static;
            overflow-x: hidden;
            overflow-y: hidden; } }
        .component.vertical-line-spot .component-content .timeline-dates .date-container .date {
          background: none;
          color: inherit;
          border: none;
          padding: 0;
          font: inherit;
          cursor: pointer;
          outline: inherit;
          cursor: pointer;
          user-select: none;
          margin: 14px 7px;
          flex: 0 0 auto; }
          .component.vertical-line-spot .component-content .timeline-dates .date-container .date:first-child {
            padding-left: 2rem;
            margin-left: 0; }
          .component.vertical-line-spot .component-content .timeline-dates .date-container .date:last-child {
            padding-right: 2rem;
            margin-right: 0; }
          @media only screen and (min-width: 992px) {
            .component.vertical-line-spot .component-content .timeline-dates .date-container .date {
              margin: 0 0 10px 0; }
              .component.vertical-line-spot .component-content .timeline-dates .date-container .date:first-child, .component.vertical-line-spot .component-content .timeline-dates .date-container .date:last-child {
                padding-right: 0;
                padding-left: 0; } }
          .component.vertical-line-spot .component-content .timeline-dates .date-container .date span {
            display: inline-block;
            font-size: 16px;
            font-weight: bold;
            color: #24405b;
            height: 25px;
            box-sizing: border-box;
            padding-top: 3px;
            transition: all 0.2s ease; }
            @media only screen and (min-width: 992px) {
              .component.vertical-line-spot .component-content .timeline-dates .date-container .date span {
                height: 34px;
                padding-top: 0; } }
          .component.vertical-line-spot .component-content .timeline-dates .date-container .date span.active {
            font-size: 16px;
            font-weight: bold;
            color: #24405b;
            height: 25px;
            box-sizing: border-box;
            padding-top: 3px;
            transition: all 0.2s ease;
            color: #74cecd;
            font-size: 20px;
            padding-top: 0px; }
            @media only screen and (min-width: 992px) {
              .component.vertical-line-spot .component-content .timeline-dates .date-container .date span.active {
                height: 34px;
                padding-top: 0; } }
    .component.vertical-line-spot .component-content .timeline-item {
      width: 100%;
      height: auto;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      @media only screen and (min-width: 992px) {
        .component.vertical-line-spot .component-content .timeline-item {
          width: 50vw;
          max-width: 75vh;
          height: 50vw;
          max-height: 75vh;
          position: relative;
          align-items: flex-start; } }
      .component.vertical-line-spot .component-content .timeline-item .timeline-img {
        transition: background-image 0.5s ease;
        background: no-repeat center top;
        background-size: cover;
        width: 100%;
        height: 85.33333vw; }
        @media only screen and (min-width: 992px) {
          .component.vertical-line-spot .component-content .timeline-item .timeline-img {
            height: 100%;
            background: no-repeat center;
            background-size: cover; } }
      .component.vertical-line-spot .component-content .timeline-item .timeline-text {
        width: calc(100% - 60px);
        margin-top: -77px;
        padding: 8.61vw 10vw;
        box-sizing: border-box;
        background-color: rgba(116, 206, 205, 0.95);
        position: relative; }
        .component.vertical-line-spot .component-content .timeline-item .timeline-text__read-more {
          background: none;
          color: inherit;
          border: none;
          padding: 0;
          font: inherit;
          cursor: pointer;
          outline: inherit;
          cursor: pointer;
          position: absolute;
          bottom: -20px;
          left: calc(50% - 20px);
          width: 40px;
          height: 40px;
          background-color: #3344f6;
          color: #ffffff;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          border-radius: 20px;
          justify-content: center; }
          @media only screen and (min-width: 992px) {
            .component.vertical-line-spot .component-content .timeline-item .timeline-text__read-more {
              display: none; } }
          .component.vertical-line-spot .component-content .timeline-item .timeline-text__read-more i {
            font-size: 35px; }
        @media only screen and (min-width: 992px) {
          .component.vertical-line-spot .component-content .timeline-item .timeline-text {
            width: 100%;
            position: absolute;
            bottom: 0;
            margin: 0;
            padding: 2.66vw 2.75vw;
            background-color: rgba(116, 206, 205, 0.9); } }
        .component.vertical-line-spot .component-content .timeline-item .timeline-text span {
          max-height: 115px;
          display: block;
          overflow: hidden;
          font-size: 13px;
          line-height: 19px;
          color: #24405b; }
          @media only screen and (min-width: 992px) {
            .component.vertical-line-spot .component-content .timeline-item .timeline-text span {
              max-height: none;
              font-size: 16px;
              line-height: 24px; } }
        .component.vertical-line-spot .component-content .timeline-item .timeline-text.expanded span {
          max-height: none; }
          .component.vertical-line-spot .component-content .timeline-item .timeline-text.expanded span .timeline-text__readmore {
            display: block; }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
body.on-page-editor .component.intro-banner {
  min-height: 60px; }

.intro-banner {
  opacity: 0; }
  @media only screen and (min-width: 768px) {
    .intro-banner {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 1600px; }
      .intro-banner.full-width {
        width: 100%;
        max-width: 1600px; }
      .intro-banner.regular {
        width: 86%;
        max-width: 1200px; }
      .intro-banner.slim {
        width: 47.2%;
        max-width: 660px; } }
  .intro-banner__nudge {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    border: 0; }
    .intro-banner__nudge button {
      border: 0;
      border-radius: 50%;
      width: 56px;
      height: 56px;
      background-color: rgba(255, 255, 255, 0.5);
      cursor: pointer; }
      .intro-banner__nudge button::after {
        position: relative;
        top: -2px;
        content: "";
        display: inline-block;
        box-sizing: border-box;
        border-style: none solid solid none;
        border-color: #ffffff;
        width: 20px;
        height: 20px;
        transform: rotate(45deg); }
      .intro-banner__nudge button:hover::after {
        border-color: #5b7085; }
  .intro-banner .banner-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.component.intro-banner:not(.hero) .banner-container {
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center; }

.component.intro-banner:not(.hero) .banner-content {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px; }
  @media only screen and (min-width: 768px) {
    .component.intro-banner:not(.hero) .banner-content {
      padding-top: 60px; } }
  @media only screen and (min-width: 768px) {
    .component.intro-banner:not(.hero) .banner-content {
      padding-bottom: 60px; } }
  @media only screen and (min-width: 768px) {
    .component.intro-banner:not(.hero) .banner-content {
      padding-left: 235px; } }
  @media only screen and (min-width: 768px) {
    .component.intro-banner:not(.hero) .banner-content {
      padding-right: 235px; } }
  .component.intro-banner:not(.hero) .banner-content p + p {
    margin-top: 3em; }
  .component.intro-banner:not(.hero) .banner-content p {
    font-size: 20px;
    line-height: 1.4; }
    @media only screen and (min-width: 768px) {
      .component.intro-banner:not(.hero) .banner-content p {
        font-size: 20px; } }
  .component.intro-banner:not(.hero) .banner-content h1, .component.intro-banner:not(.hero) .banner-content h2, .component.intro-banner:not(.hero) .banner-content h3, .component.intro-banner:not(.hero) .banner-content h4, .component.intro-banner:not(.hero) .banner-content h5 {
    font-weight: bold;
    font-size: 22px;
    line-height: 1.27; }
    @media only screen and (min-width: 768px) {
      .component.intro-banner:not(.hero) .banner-content h1, .component.intro-banner:not(.hero) .banner-content h2, .component.intro-banner:not(.hero) .banner-content h3, .component.intro-banner:not(.hero) .banner-content h4, .component.intro-banner:not(.hero) .banner-content h5 {
        font-size: 22px; } }

.component.intro-banner:not(.hero) .banner-container {
  background-position: center; }

.component.intro-banner:not(.hero).top-left-image .banner-container {
  background-position: top left; }

.component.intro-banner:not(.hero).middle-left-image .banner-container {
  background-position: center left; }

.component.intro-banner:not(.hero).bottom-left-image .banner-container {
  background-position: bottom left; }

.component.intro-banner:not(.hero).top-center-image .banner-container {
  background-position: top center; }

.component.intro-banner:not(.hero).bottom-center-image .banner-container {
  background-position: bottom center; }

.component.intro-banner:not(.hero).top-right-image .banner-container {
  background-position: top right; }

.component.intro-banner:not(.hero).middle-right-image .banner-container {
  background-position: center right; }

.component.intro-banner:not(.hero).bottom-right-image .banner-container {
  background-position: bottom right; }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
.component.intro-banner.hero {
  position: relative;
  overflow: hidden; }
  @media only screen and (min-width: 992px) {
    .component.intro-banner.hero {
      margin-top: 0; } }
  .component.intro-banner.hero img {
    width: 100%;
    height: auto;
    display: block; }
  .component.intro-banner.hero .banner-container {
    position: absolute;
    top: 10%;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden; }
  @media only screen and (min-width: 768px) {
    .component.intro-banner.hero:first-child .banner-container {
      top: 15%; } }
  .component.intro-banner.hero .banner-video > div {
    margin: 0 auto; }
  .component.intro-banner.hero .banner-container-inner {
    margin-left: 30px;
    margin-right: 30px; }
    @media only screen and (min-width: 768px) {
      .component.intro-banner.hero .banner-container-inner {
        margin-left: auto;
        margin-right: auto;
        width: 86%;
        max-width: 1200px; }
        .component.intro-banner.hero .banner-container-inner.full-width {
          width: 100%;
          max-width: 1600px; }
        .component.intro-banner.hero .banner-container-inner.regular {
          width: 86%;
          max-width: 1200px; }
        .component.intro-banner.hero .banner-container-inner.slim {
          width: 47.2%;
          max-width: 660px; } }
  .component.intro-banner.hero .banner-content {
    text-align: center; }
    @media only screen and (min-width: 992px) {
      .component.intro-banner.hero .banner-content {
        text-align: inherit;
        width: 50%; }
        .component.intro-banner.hero .banner-content [class*="like-"] {
          position: relative;
          left: 45%;
          transform: translateX(-50%); } }
  .component.intro-banner.hero svg,
  .component.intro-banner.hero .svg {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    width: 200vw;
    height: 200vw;
    bottom: 20px;
    opacity: 0.5; }
    .component.intro-banner.hero svg.fixed-bottom,
    .component.intro-banner.hero .svg.fixed-bottom {
      top: auto;
      bottom: 15px; }
    @media only screen and (min-width: 768px) {
      .component.intro-banner.hero svg.fixed-bottom,
      .component.intro-banner.hero .svg.fixed-bottom {
        bottom: 40px; } }
    @media only screen and (min-width: 992px) {
      .component.intro-banner.hero svg,
      .component.intro-banner.hero .svg {
        width: 100vw;
        height: 100vw;
        top: -55vw;
        left: -33.33333vw;
        bottom: auto; }
        .component.intro-banner.hero svg.fixed-bottom,
        .component.intro-banner.hero .svg.fixed-bottom {
          bottom: 40px; } }
    @media only screen and (min-width: 1200px) {
      .component.intro-banner.hero svg,
      .component.intro-banner.hero .svg {
        width: 90vw;
        height: 90vw;
        top: -45.9vw;
        left: -25vw;
        bottom: auto; }
        .component.intro-banner.hero svg.fixed-bottom,
        .component.intro-banner.hero .svg.fixed-bottom {
          bottom: 60px; } }
    @media only screen and (min-width: 1400px) {
      .component.intro-banner.hero svg,
      .component.intro-banner.hero .svg {
        width: 1120px;
        height: 1120px;
        top: -504px;
        left: -280px;
        bottom: auto; }
        .component.intro-banner.hero svg.fixed-bottom,
        .component.intro-banner.hero .svg.fixed-bottom {
          bottom: 60px; } }
  .component.intro-banner.hero h1 {
    font-weight: 600;
    font-size: 35px;
    line-height: 1.14;
    letter-spacing: -0.6px; }
    @media only screen and (min-width: 768px) {
      .component.intro-banner.hero h1 {
        font-size: 70px; } }
    @media only screen and (min-width: 992px) {
      .component.intro-banner.hero h1 {
        font-size: 55px; } }
    @media only screen and (min-width: 1200px) {
      .component.intro-banner.hero h1 {
        font-size: 70px; } }
  .component.intro-banner.hero:not(.charcoal-blue-txt) h1 {
    color: #f4f4f4; }

body.on-page-editor .component.rich-text {
  min-height: 60px; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
.component.rich-text ol,
.component.rich-text ul {
  list-style: none;
  list-style-position: inside;
  padding-left: 12px; }

.component.rich-text ol {
  counter-reset: demant-counter;
  padding-left: 50px; }
  @media only screen and (min-width: 768px) {
    .component.rich-text ol {
      padding-left: 70px; } }

.component.rich-text ol li,
.component.rich-text ul li {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.4;
  position: relative;
  box-sizing: border-box; }
  @media only screen and (min-width: 768px) {
    .component.rich-text ol li,
    .component.rich-text ul li {
      font-size: 15px; } }

.component.rich-text ol li {
  counter-increment: demant-counter;
  min-height: 35px;
  display: flex;
  align-items: center; }
  .component.rich-text ol li + li {
    margin-top: 0.5rem; }
  @media only screen and (min-width: 768px) {
    .component.rich-text ol li {
      min-height: 50px; } }

.component.rich-text ol li::before,
.component.rich-text ul li::before {
  content: "";
  display: inline-block;
  background-color: #24405b;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  left: -11px;
  top: 0.5em;
  width: 4px;
  height: 4px; }

.component.rich-text ol li::before {
  content: counter(demant-counter);
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  left: -50px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%); }
  @media only screen and (min-width: 768px) {
    .component.rich-text ol li::before {
      font-size: 18px; } }
  @media only screen and (min-width: 768px) {
    .component.rich-text ol li::before {
      left: -70px;
      width: 50px;
      height: 50px; } }

.component.rich-text.bullets-grey ol,
.component.rich-text.bullets-grey ul {
  list-style: none;
  list-style-position: inside;
  padding-left: 12px; }

.component.rich-text.bullets-grey ol {
  counter-reset: demant-counter;
  padding-left: 50px; }
  @media only screen and (min-width: 768px) {
    .component.rich-text.bullets-grey ol {
      padding-left: 70px; } }

.component.rich-text.bullets-grey ol li,
.component.rich-text.bullets-grey ul li {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.4;
  position: relative;
  box-sizing: border-box; }
  @media only screen and (min-width: 768px) {
    .component.rich-text.bullets-grey ol li,
    .component.rich-text.bullets-grey ul li {
      font-size: 15px; } }

.component.rich-text.bullets-grey ol li {
  counter-increment: demant-counter;
  min-height: 35px;
  display: flex;
  align-items: center; }
  .component.rich-text.bullets-grey ol li + li {
    margin-top: 0.5rem; }
  @media only screen and (min-width: 768px) {
    .component.rich-text.bullets-grey ol li {
      min-height: 50px; } }

.component.rich-text.bullets-grey ol li::before,
.component.rich-text.bullets-grey ul li::before {
  content: "";
  display: inline-block;
  background-color: #b9b9ad;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  left: -11px;
  top: 0.5em;
  width: 4px;
  height: 4px; }

.component.rich-text.bullets-grey ol li::before {
  content: counter(demant-counter);
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  left: -50px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%); }
  @media only screen and (min-width: 768px) {
    .component.rich-text.bullets-grey ol li::before {
      font-size: 18px; } }
  @media only screen and (min-width: 768px) {
    .component.rich-text.bullets-grey ol li::before {
      left: -70px;
      width: 50px;
      height: 50px; } }

.component.rich-text.bullets-green ol,
.component.rich-text.bullets-green ul {
  list-style: none;
  list-style-position: inside;
  padding-left: 12px; }

.component.rich-text.bullets-green ol {
  counter-reset: demant-counter;
  padding-left: 50px; }
  @media only screen and (min-width: 768px) {
    .component.rich-text.bullets-green ol {
      padding-left: 70px; } }

.component.rich-text.bullets-green ol li,
.component.rich-text.bullets-green ul li {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.4;
  position: relative;
  box-sizing: border-box; }
  @media only screen and (min-width: 768px) {
    .component.rich-text.bullets-green ol li,
    .component.rich-text.bullets-green ul li {
      font-size: 15px; } }

.component.rich-text.bullets-green ol li {
  counter-increment: demant-counter;
  min-height: 35px;
  display: flex;
  align-items: center; }
  .component.rich-text.bullets-green ol li + li {
    margin-top: 0.5rem; }
  @media only screen and (min-width: 768px) {
    .component.rich-text.bullets-green ol li {
      min-height: 50px; } }

.component.rich-text.bullets-green ol li::before,
.component.rich-text.bullets-green ul li::before {
  content: "";
  display: inline-block;
  background-color: #74cecd;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  left: -11px;
  top: 0.5em;
  width: 4px;
  height: 4px; }

.component.rich-text.bullets-green ol li::before {
  content: counter(demant-counter);
  font-weight: 600;
  font-size: 15px;
  color: #24405b;
  left: -50px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%); }
  @media only screen and (min-width: 768px) {
    .component.rich-text.bullets-green ol li::before {
      font-size: 18px; } }
  @media only screen and (min-width: 768px) {
    .component.rich-text.bullets-green ol li::before {
      left: -70px;
      width: 50px;
      height: 50px; } }

.component.rich-text.bullets-fat ol li::before,
.component.rich-text.bullets-fat ul li::before {
  top: 50%;
  transform: translateY(-50%); }

.component.rich-text.bullets-fat ul li {
  padding-left: 2rem;
  margin: 0 0 25px 0; }
  .component.rich-text.bullets-fat ul li::before {
    width: 15px;
    height: 15px; }
  @media only screen and (min-width: 768px) {
    .component.rich-text.bullets-fat ul li {
      margin: 0 0 51px 0; }
      .component.rich-text.bullets-fat ul li::before {
        width: 30px;
        height: 30px; } }

.component.rich-text.charcoal-blue-bg ol,
.component.rich-text.charcoal-blue-bg ul {
  list-style: none;
  list-style-position: inside;
  padding-left: 12px; }

.component.rich-text.charcoal-blue-bg ol {
  counter-reset: demant-counter;
  padding-left: 50px; }
  @media only screen and (min-width: 768px) {
    .component.rich-text.charcoal-blue-bg ol {
      padding-left: 70px; } }

.component.rich-text.charcoal-blue-bg ol li,
.component.rich-text.charcoal-blue-bg ul li {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.4;
  position: relative;
  box-sizing: border-box; }
  @media only screen and (min-width: 768px) {
    .component.rich-text.charcoal-blue-bg ol li,
    .component.rich-text.charcoal-blue-bg ul li {
      font-size: 15px; } }

.component.rich-text.charcoal-blue-bg ol li {
  counter-increment: demant-counter;
  min-height: 35px;
  display: flex;
  align-items: center; }
  .component.rich-text.charcoal-blue-bg ol li + li {
    margin-top: 0.5rem; }
  @media only screen and (min-width: 768px) {
    .component.rich-text.charcoal-blue-bg ol li {
      min-height: 50px; } }

.component.rich-text.charcoal-blue-bg ol li::before,
.component.rich-text.charcoal-blue-bg ul li::before {
  content: "";
  display: inline-block;
  background-color: #74cecd;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  left: -11px;
  top: 0.5em;
  width: 4px;
  height: 4px; }

.component.rich-text.charcoal-blue-bg ol li::before {
  content: counter(demant-counter);
  font-weight: 600;
  font-size: 15px;
  color: #24405b;
  left: -50px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%); }
  @media only screen and (min-width: 768px) {
    .component.rich-text.charcoal-blue-bg ol li::before {
      font-size: 18px; } }
  @media only screen and (min-width: 768px) {
    .component.rich-text.charcoal-blue-bg ol li::before {
      left: -70px;
      width: 50px;
      height: 50px; } }

.component.rich-text.warm-gray-bg ol,
.component.rich-text.warm-gray-bg ul {
  list-style: none;
  list-style-position: inside;
  padding-left: 12px; }

.component.rich-text.warm-gray-bg ol {
  counter-reset: demant-counter;
  padding-left: 50px; }
  @media only screen and (min-width: 768px) {
    .component.rich-text.warm-gray-bg ol {
      padding-left: 70px; } }

.component.rich-text.warm-gray-bg ol li,
.component.rich-text.warm-gray-bg ul li {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.4;
  position: relative;
  box-sizing: border-box; }
  @media only screen and (min-width: 768px) {
    .component.rich-text.warm-gray-bg ol li,
    .component.rich-text.warm-gray-bg ul li {
      font-size: 15px; } }

.component.rich-text.warm-gray-bg ol li {
  counter-increment: demant-counter;
  min-height: 35px;
  display: flex;
  align-items: center; }
  .component.rich-text.warm-gray-bg ol li + li {
    margin-top: 0.5rem; }
  @media only screen and (min-width: 768px) {
    .component.rich-text.warm-gray-bg ol li {
      min-height: 50px; } }

.component.rich-text.warm-gray-bg ol li::before,
.component.rich-text.warm-gray-bg ul li::before {
  content: "";
  display: inline-block;
  background-color: #24405b;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  left: -11px;
  top: 0.5em;
  width: 4px;
  height: 4px; }

.component.rich-text.warm-gray-bg ol li::before {
  content: counter(demant-counter);
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  left: -50px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%); }
  @media only screen and (min-width: 768px) {
    .component.rich-text.warm-gray-bg ol li::before {
      font-size: 18px; } }
  @media only screen and (min-width: 768px) {
    .component.rich-text.warm-gray-bg ol li::before {
      left: -70px;
      width: 50px;
      height: 50px; } }

.component.rich-text.green-blue-gradient-bg {
  background-image: linear-gradient(to right, #74cbca, #3344f6);
  margin-top: 0; }
  .component.rich-text.green-blue-gradient-bg.first-repeating-background, .component.rich-text.green-blue-gradient-bg .repeating-background {
    margin-bottom: 0; }
  .component.rich-text.green-blue-gradient-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.rich-text.green-blue-gradient-bg.space-top {
        padding-top: 60px; } }
  .component.rich-text.green-blue-gradient-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.rich-text.green-blue-gradient-bg .component-content {
        padding: 30px 0; } }

.component.rich-text.charcoal-blue-bg {
  background-color: #24405b;
  margin-top: 0; }
  .component.rich-text.charcoal-blue-bg.first-repeating-background, .component.rich-text.charcoal-blue-bg .repeating-background {
    margin-bottom: 0; }
  .component.rich-text.charcoal-blue-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.rich-text.charcoal-blue-bg.space-top {
        padding-top: 60px; } }
  .component.rich-text.charcoal-blue-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.rich-text.charcoal-blue-bg .component-content {
        padding: 30px 0; } }

.component.rich-text.warm-gray-bg {
  background-color: #dcdcd6;
  margin-top: 0; }
  .component.rich-text.warm-gray-bg.first-repeating-background, .component.rich-text.warm-gray-bg .repeating-background {
    margin-bottom: 0; }
  .component.rich-text.warm-gray-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.rich-text.warm-gray-bg.space-top {
        padding-top: 60px; } }
  .component.rich-text.warm-gray-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.rich-text.warm-gray-bg .component-content {
        padding: 30px 0; } }

.component.rich-text.seafoam-green-50-bg {
  background-color: #badcdf;
  margin-top: 0; }
  .component.rich-text.seafoam-green-50-bg.first-repeating-background, .component.rich-text.seafoam-green-50-bg .repeating-background {
    margin-bottom: 0; }
  .component.rich-text.seafoam-green-50-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.rich-text.seafoam-green-50-bg.space-top {
        padding-top: 60px; } }
  .component.rich-text.seafoam-green-50-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.rich-text.seafoam-green-50-bg .component-content {
        padding: 30px 0; } }

.component.rich-text.sky-blue-75-bg {
  background-color: #bcdef7;
  margin-top: 0; }
  .component.rich-text.sky-blue-75-bg.first-repeating-background, .component.rich-text.sky-blue-75-bg .repeating-background {
    margin-bottom: 0; }
  .component.rich-text.sky-blue-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.rich-text.sky-blue-75-bg.space-top {
        padding-top: 60px; } }
  .component.rich-text.sky-blue-75-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.rich-text.sky-blue-75-bg .component-content {
        padding: 30px 0; } }

.component.rich-text.warm-grey-75-bg {
  background-color: #cacac1;
  margin-top: 0; }
  .component.rich-text.warm-grey-75-bg.first-repeating-background, .component.rich-text.warm-grey-75-bg .repeating-background {
    margin-bottom: 0; }
  .component.rich-text.warm-grey-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.rich-text.warm-grey-75-bg.space-top {
        padding-top: 60px; } }
  .component.rich-text.warm-grey-75-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.rich-text.warm-grey-75-bg .component-content {
        padding: 30px 0; } }

.component.rich-text.pine-green-75-bg {
  background-color: #609c9c;
  margin-top: 0; }
  .component.rich-text.pine-green-75-bg.first-repeating-background, .component.rich-text.pine-green-75-bg .repeating-background {
    margin-bottom: 0; }
  .component.rich-text.pine-green-75-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.rich-text.pine-green-75-bg.space-top {
        padding-top: 60px; } }
  .component.rich-text.pine-green-75-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.rich-text.pine-green-75-bg .component-content {
        padding: 30px 0; } }

.component.rich-text.burst-yellow-10-bg {
  background-color: #fcf7ea;
  margin-top: 0; }
  .component.rich-text.burst-yellow-10-bg.first-repeating-background, .component.rich-text.burst-yellow-10-bg .repeating-background {
    margin-bottom: 0; }
  .component.rich-text.burst-yellow-10-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.rich-text.burst-yellow-10-bg.space-top {
        padding-top: 60px; } }
  .component.rich-text.burst-yellow-10-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.rich-text.burst-yellow-10-bg .component-content {
        padding: 30px 0; } }

.component.rich-text.walnut-brown-bg {
  background-color: #6c4b42;
  margin-top: 0; }
  .component.rich-text.walnut-brown-bg.first-repeating-background, .component.rich-text.walnut-brown-bg .repeating-background {
    margin-bottom: 0; }
  .component.rich-text.walnut-brown-bg.space-top {
    padding-top: 40px; }
    @media only screen and (min-width: 992px) {
      .component.rich-text.walnut-brown-bg.space-top {
        padding-top: 60px; } }
  .component.rich-text.walnut-brown-bg .component-content {
    padding: 20px 0; }
    @media only screen and (min-width: 992px) {
      .component.rich-text.walnut-brown-bg .component-content {
        padding: 30px 0; } }

.component.rich-text .component-content {
  margin-left: 30px;
  margin-right: 30px; }
  @media only screen and (min-width: 768px) {
    .component.rich-text .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 47.2%;
      max-width: 660px; }
      .component.rich-text .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .component.rich-text .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .component.rich-text .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }

.component.rich-text[class*="quote-"] .component-content {
  position: relative;
  font-size: 15px;
  line-height: 1.39; }
  @media only screen and (min-width: 768px) {
    .component.rich-text[class*="quote-"] .component-content {
      font-size: 18px; } }
  .component.rich-text[class*="quote-"] .component-content::before {
    content: "";
    background-color: transparent;
    box-sizing: border-box;
    margin: 0 auto;
    display: inline-block;
    margin-left: 0;
    margin-bottom: 1rem;
    width: 100px;
    height: 100px;
    padding: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100px; }
    @media only screen and (min-width: 768px) {
      .component.rich-text[class*="quote-"] .component-content::before {
        transform: translate(-50%, 0); } }
    @media only screen and (min-width: 992px) {
      .component.rich-text[class*="quote-"] .component-content::before {
        position: absolute;
        left: -176px;
        width: 138px;
        height: 138px;
        transform: translate(0);
        background-size: 138px; } }

@media only screen and (min-width: 992px) {
  .component.rich-text[class*="quote-"].small .component-content::before {
    left: -88px;
    width: 69px;
    height: 69px;
    background-size: 69px; } }

.component.rich-text.bottom-margin-0 {
  margin-bottom: 0; }

.component.rich-text.quote-charcoal-blue .component-content::before {
  background-image: url("/design/build/demant2019/assets/quote-charcoal.svg"), none; }

.component.rich-text.quote-skyblue .component-content::before {
  background-image: url("/design/build/demant2019/assets/quote-skyblue.svg"), none; }

.component.rich-text.quote-white .component-content::before {
  background-image: url("/design/build/demant2019/assets/quote-white.svg"), none; }

.component.rich-text.quote-seafoam-green .component-content::before {
  background-image: url("/design/build/demant2019/assets/quote-seafoam-green.svg"), none; }

.component.rich-text.quote-yellow .component-content::before {
  background-image: url("/design/build/demant2019/assets/quote-yellow.svg"), none; }

.component.rich-text.quote-warm-gray .component-content::before {
  background-image: url("/design/build/demant2019/assets/quote-warm-gray.svg"), none; }

.component.rich-text[class*="arrow-"] {
  padding-top: 22px; }
  @media only screen and (min-width: 992px) {
    .component.rich-text[class*="arrow-"] {
      padding-top: 43px; } }
  .component.rich-text[class*="arrow-"] .component-content {
    padding: 0.5rem;
    position: relative;
    background: #ffffff; }
    @media only screen and (min-width: 992px) {
      .component.rich-text[class*="arrow-"] .component-content {
        padding: 1rem; } }
    .component.rich-text[class*="arrow-"] .component-content::before {
      content: "";
      width: 43px;
      height: 43px;
      margin: 0;
      padding: 0;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-50%, -50%);
      box-sizing: border-box;
      z-index: -1; }
      @media only screen and (min-width: 992px) {
        .component.rich-text[class*="arrow-"] .component-content::before {
          width: 86px;
          height: 86px; } }

.component.rich-text.arrow-seafoam-green .component-content::before {
  background: #74cecd; }

.component.rich-text.arrow-charcoal-blue .component-content::before {
  background: #24405b; }

.component.rich-text.arrow-skyblue .component-content::before {
  background: #a4d6fc; }

.component.rich-text.arrow-warm-gray .component-content::before {
  background: #b9b9ad; }

.component.rich-text.arrow-walnut-brown .component-content::before {
  background: #6c4b42; }

.component.rich-text.arrow-pine-green .component-content::before {
  background: #407f7f; }

.component.rich-text.arrow-electric-blue .component-content::before {
  background: #3344f6; }

.component.rich-text.arrow-burst-yellow .component-content::before {
  background: #edb833; }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
.rich-text.revenue-and-profit {
  background-color: #24405b;
  color: #ffffff; }
  .rich-text.revenue-and-profit h1,
  .rich-text.revenue-and-profit .like-h1,
  .rich-text.revenue-and-profit a,
  .rich-text.revenue-and-profit .like-a {
    color: #ffffff; }
  .rich-text.revenue-and-profit .rev-header {
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 5px;
    border-bottom: 1px solid #ffffff;
    line-height: 1.1; }
    @media only screen and (min-width: 768px) {
      .rich-text.revenue-and-profit .rev-header {
        font-size: 20px; } }
  .rich-text.revenue-and-profit .rev-diff {
    font-size: 35px;
    font-weight: 300;
    margin-bottom: 10px; }
    @media only screen and (min-width: 768px) {
      .rich-text.revenue-and-profit .rev-diff {
        font-size: 70px; } }
    @media only screen and (min-width: 992px) {
      .rich-text.revenue-and-profit .rev-diff {
        font-size: 55px; } }
    @media only screen and (min-width: 1200px) {
      .rich-text.revenue-and-profit .rev-diff {
        font-size: 70px; } }
  .rich-text.revenue-and-profit .rev-amount {
    font-size: 12px;
    color: #74cecd; }
    @media only screen and (min-width: 768px) {
      .rich-text.revenue-and-profit .rev-amount {
        font-size: 15px; } }
    .rich-text.revenue-and-profit .rev-amount + .rev-header {
      margin-top: 40px; }
  .rich-text.revenue-and-profit .arrow-up,
  .rich-text.revenue-and-profit .arrow-down {
    position: relative; }
    .rich-text.revenue-and-profit .arrow-up::before,
    .rich-text.revenue-and-profit .arrow-down::before {
      position: absolute;
      top: -9px;
      right: -32px;
      transform: scale(0.5);
      content: url("/design/build/demant2019/assets/arrow_up.png");
      display: inline-block; }
      @media only screen and (min-width: 768px) {
        .rich-text.revenue-and-profit .arrow-up::before,
        .rich-text.revenue-and-profit .arrow-down::before {
          top: -23px;
          right: -42px;
          transform: none; } }
      @media only screen and (min-width: 992px) {
        .rich-text.revenue-and-profit .arrow-up::before,
        .rich-text.revenue-and-profit .arrow-down::before {
          top: -18px;
          right: -36px;
          transform: scale(0.7); } }
      @media only screen and (min-width: 1200px) {
        .rich-text.revenue-and-profit .arrow-up::before,
        .rich-text.revenue-and-profit .arrow-down::before {
          top: -23px;
          right: -42px;
          transform: none; } }
  .rich-text.revenue-and-profit .arrow-down::before {
    content: url("/design/build/demant2019/assets/arrow_down.png"); }

::selection {
  background: #edb833; }

.rich-text.meet-us {
  background-color: #24405b;
  color: #ffffff; }
  .rich-text.meet-us h1,
  .rich-text.meet-us .like-h1,
  .rich-text.meet-us a,
  .rich-text.meet-us .like-a {
    color: #ffffff; }
  .rich-text.meet-us ul {
    padding-left: 0; }
    .rich-text.meet-us ul div {
      box-sizing: border-box; }
    .rich-text.meet-us ul > .meet-us-container {
      font-weight: 600;
      display: flex;
      flex-wrap: wrap; }
      .rich-text.meet-us ul > .meet-us-container div {
        padding-bottom: 9px; }
    .rich-text.meet-us ul .meet-us-date {
      border-right: 1px solid #ffffff;
      padding-right: 20px;
      text-align: right;
      width: 35%;
      word-spacing: 100px; }
      @media only screen and (min-width: 400px) {
        .rich-text.meet-us ul .meet-us-date {
          word-spacing: normal; } }
      @media only screen and (min-width: 992px) {
        .rich-text.meet-us ul .meet-us-date {
          width: 25%; } }
    .rich-text.meet-us ul .meet-us-location {
      padding-left: 20px;
      width: 65%; }
      @media only screen and (min-width: 992px) {
        .rich-text.meet-us ul .meet-us-location {
          width: 75%; } }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
.component.rich-text.latest-annual-report {
  padding: 0 !important;
  position: relative;
  height: 100%;
  /*.component-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }*/ }
  .component.rich-text.latest-annual-report .component-content,
  .component.rich-text.latest-annual-report .latest-annual-report {
    min-height: 100%;
    height: 100%; }
  .component.rich-text.latest-annual-report .latest-annual-report {
    background-size: cover;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px; }
    @media only screen and (min-width: 992px) {
      .component.rich-text.latest-annual-report .latest-annual-report {
        padding: 30px; } }
    .component.rich-text.latest-annual-report .latest-annual-report__headline {
      font-size: 20px;
      font-weight: 600;
      border-bottom: 1px solid #24405b;
      margin-bottom: 27px; }
      @media only screen and (min-width: 768px) {
        .component.rich-text.latest-annual-report .latest-annual-report__headline {
          font-size: 20px; } }
    .component.rich-text.latest-annual-report .latest-annual-report__image {
      margin-bottom: 27px; }
      .component.rich-text.latest-annual-report .latest-annual-report__image img {
        display: block;
        margin: 0 auto;
        width: 55%;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5); }
    .component.rich-text.latest-annual-report .latest-annual-report__link {
      text-align: center; }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
.component.rich-text.demant-table h2 {
  text-align: center;
  padding-bottom: 1.2rem; }

.component.rich-text.demant-table .shares-table {
  display: flex;
  flex-direction: column;
  width: 100%; }

.component.rich-text.demant-table .table-heading-row-0 {
  border-bottom: 1px solid #5b7085;
  padding: 0 0.4rem; }
  .component.rich-text.demant-table .table-heading-row-0 + .table-cell-row .table-cell:last-child {
    border: 0; }
  @media only screen and (min-width: 992px) {
    .component.rich-text.demant-table .table-heading-row-0 {
      border: transparent;
      padding-bottom: 0.4rem;
      padding-left: inherit;
      padding-right: inherit; } }

.component.rich-text.demant-table .table-heading-row {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  flex-direction: row-reverse;
  font-weight: bold; }

.component.rich-text.demant-table .table-heading {
  display: flex;
  line-height: 1.4rem; }
  @media only screen and (min-width: 992px) {
    .component.rich-text.demant-table .table-heading {
      flex: 1;
      justify-content: flex-end; } }
  .component.rich-text.demant-table .table-heading:last-child {
    flex-basis: 100%;
    justify-content: center;
    font-weight: normal; }
    @media only screen and (min-width: 992px) {
      .component.rich-text.demant-table .table-heading:last-child {
        display: flex;
        flex: 4;
        justify-content: flex-start; } }

.component.rich-text.demant-table .table-cell-row {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  padding-bottom: .4rem; }
  @media only screen and (min-width: 992px) {
    .component.rich-text.demant-table .table-cell-row {
      flex-direction: row-reverse; } }

.component.rich-text.demant-table .table-cell {
  display: flex;
  line-height: 1.3rem;
  padding: 0 0.4rem; }
  @media only screen and (min-width: 992px) {
    .component.rich-text.demant-table .table-cell {
      flex: 1;
      justify-content: flex-end;
      padding: inherit; } }
  .component.rich-text.demant-table .table-cell:last-child {
    flex-basis: 100%;
    justify-content: center;
    border-top: 1px solid #b9b9ad;
    font-weight: bold;
    padding-top: .5rem;
    padding-bottom: .3rem; }
    @media only screen and (min-width: 992px) {
      .component.rich-text.demant-table .table-cell:last-child {
        border: transparent;
        background-color: inherit;
        flex: 4;
        justify-content: flex-start;
        font-weight: normal;
        padding: inherit; } }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
.component.rich-text.consensus .consensus-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 85%; }
  @media only screen and (min-width: 768px) {
    .component.rich-text.consensus .consensus-table {
      font-size: 80%; } }
  @media only screen and (min-width: 992px) {
    .component.rich-text.consensus .consensus-table {
      font-size: 100%; } }

.component.rich-text.consensus .table-headline-0 {
  font-weight: bold;
  border-bottom: 1px solid #5b7085;
  padding-bottom: .5rem; }

.component.rich-text.consensus .table-headline {
  padding-left: .2rem; }

.component.rich-text.consensus .table-headers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  font-weight: bold;
  padding-left: .2rem;
  padding-right: .2rem; }

.component.rich-text.consensus .table-cells {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: .2rem;
  padding-right: .2rem; }

.component.rich-text.consensus .table-cell,
.component.rich-text.consensus .table-header {
  display: flex;
  width: 15%;
  justify-content: flex-end;
  padding-bottom: .5rem;
  padding-top: .5rem; }

.component.rich-text.consensus .table-cell-0,
.component.rich-text.consensus .table-header-0 {
  display: flex;
  justify-content: flex-start;
  width: 28%; }
  @media only screen and (min-width: 992px) {
    .component.rich-text.consensus .table-cell-0,
    .component.rich-text.consensus .table-header-0 {
      width: 40%; } }

.component.rich-text.consensus .table-1 .table-cell-0,
.component.rich-text.consensus .table-1 .table-cell-1 {
  width: auto; }

.component.rich-text.consensus .table-1 .table-header-0 {
  width: 100%;
  padding-top: 0; }

.component.rich-text.consensus .table-2 .table-headers-0,
.component.rich-text.consensus .table-1 .table-headers-0 {
  border-bottom: 1px solid #b9b9ad;
  margin-bottom: .5rem;
  margin-top: 1rem;
  padding-left: .2rem;
  padding-right: .2rem; }

#wrapper #content .component.rich-text.wide-table .component-content {
  margin-left: 30px;
  margin-right: 30px; }
  @media only screen and (min-width: 768px) {
    #wrapper #content .component.rich-text.wide-table .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      #wrapper #content .component.rich-text.wide-table .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      #wrapper #content .component.rich-text.wide-table .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      #wrapper #content .component.rich-text.wide-table .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }
  #wrapper #content .component.rich-text.wide-table .component-content table {
    width: 100% !important;
    max-width: 100%; }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
.component.search-result-spot {
  margin-left: 30px;
  margin-right: 30px; }
  @media only screen and (min-width: 768px) {
    .component.search-result-spot {
      margin-left: auto;
      margin-right: auto;
      width: 47.2%;
      max-width: 660px; }
      .component.search-result-spot.full-width {
        width: 100%;
        max-width: 1600px; }
      .component.search-result-spot.regular {
        width: 86%;
        max-width: 1200px; }
      .component.search-result-spot.slim {
        width: 47.2%;
        max-width: 660px; } }
  .component.search-result-spot.padding-top {
    padding-top: 30px; }
  .component.search-result-spot .component-content .search-field {
    padding-bottom: 10px; }
    .component.search-result-spot .component-content .search-field input[type=text] {
      border: 1px solid #24405b;
      padding: 10px;
      width: 90%; }
    .component.search-result-spot .component-content .search-field input[type=text]:focus {
      border: 1px solid #74cecd; }
    .component.search-result-spot .component-content .search-field .search-result-count {
      color: #5b7085;
      font-size: 13px; }
  .component.search-result-spot .component-content .search-result ul li {
    padding-bottom: 15px; }
    .component.search-result-spot .component-content .search-result ul li h3 a {
      color: #24405b;
      text-decoration: none; }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
.wrapper.cookie-banner {
  margin-top: 5rem; }
  @media only screen and (min-width: 992px) {
    .wrapper.cookie-banner {
      margin-top: 3rem; } }
  @media only screen and (min-width: 1200px) {
    .wrapper.cookie-banner {
      margin-top: 2rem; } }
  .wrapper.cookie-banner .header .desktop {
    top: 3rem; }
    @media only screen and (min-width: 1200px) {
      .wrapper.cookie-banner .header .desktop {
        top: 2rem; } }

.component.cookie-warning {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: #24405b;
  color: #ffffff;
  box-sizing: border-box;
  font-size: 11px;
  height: 5rem;
  padding: 0.5rem 2rem 0 0.5rem; }
  @media only screen and (min-width: 768px) {
    .component.cookie-warning {
      font-size: 13px; } }
  @media only screen and (min-width: 992px) {
    .component.cookie-warning {
      padding: 0.5rem 2rem 0 0.5rem;
      height: 3rem;
      border-bottom: 1px solid #b9b9ad; } }
  @media only screen and (min-width: 1200px) {
    .component.cookie-warning {
      height: 2rem; } }
  @media only screen and (min-width: 768px) {
    .component.cookie-warning .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 1600px; }
      .component.cookie-warning .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .component.cookie-warning .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .component.cookie-warning .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }
  .component.cookie-warning .component-content .info a {
    color: #ffffff; }
  .component.cookie-warning .component-content .submit a {
    color: #3344f6;
    font-weight: bold;
    color: #ffffff;
    text-decoration: none;
    position: absolute;
    right: 1rem;
    top: 0.5rem; }

.header .desktop .main-menu-container {
  box-shadow: 0 9px 20px -10px #000000; }

::selection {
  background: #edb833; }

.component.job-list-spot .component-content {
  margin-left: 30px;
  margin-right: 30px; }
  @media only screen and (min-width: 768px) {
    .component.job-list-spot .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      .component.job-list-spot .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .component.job-list-spot .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .component.job-list-spot .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }
  .component.job-list-spot .component-content li {
    border-top: 1.5px solid #dcdcd6;
    line-height: 2rem;
    padding: 1.5rem 0; }
    .component.job-list-spot .component-content li img {
      width: 1.357em;
      margin: 0 0.5em 0 1px;
      height: auto;
      display: inline-block;
      vertical-align: middle; }
    .component.job-list-spot .component-content li .location {
      display: inline-block;
      vertical-align: middle; }
    .component.job-list-spot .component-content li a {
      margin-top: 2em;
      color: #24405b;
      text-decoration: none; }
      .component.job-list-spot .component-content li a:hover {
        color: #3344f6; }
  .component.job-list-spot .component-content .custom-select-wrapper {
    position: relative;
    display: inline-block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .component.job-list-spot .component-content .custom-select {
    position: relative;
    display: inline-block; }
  .component.job-list-spot .component-content .custom-select-trigger {
    position: relative;
    display: block;
    width: 100%;
    padding: 0 84px 0 1.25rem;
    color: #24405b;
    line-height: 50px;
    background: #ffffff;
    cursor: pointer;
    box-sizing: border-box;
    font-weight: 600; }
    .component.job-list-spot .component-content .custom-select-trigger::after {
      position: absolute;
      display: block;
      content: '';
      width: 15px;
      height: 15px;
      top: 50%;
      right: 25px;
      margin-top: -3px;
      border-bottom: 1px solid #74cecd;
      border-right: 1px solid #74cecd;
      transform: rotate(45deg) translateY(-50%);
      transition: all .4s ease-in-out;
      transform-origin: 50% 0; }
  .component.job-list-spot .component-content .custom-options {
    position: absolute;
    display: block;
    top: 100%;
    left: 0;
    right: 0;
    min-width: 100%;
    margin: 0;
    background: #ffffff;
    transition: all .4s ease-in-out;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateY(-15px); }
  .component.job-list-spot .component-content .custom-option {
    position: relative;
    display: block;
    padding: 0 22px;
    border-bottom: 1px solid #dcdcd6;
    line-height: 47px;
    cursor: pointer;
    transition: all .4s ease-in-out;
    font-weight: 600; }
    .component.job-list-spot .component-content .custom-option::after {
      position: absolute;
      display: block;
      content: '';
      width: 10px;
      height: 10px;
      top: 50%;
      right: 25px;
      margin-top: -3px;
      border-bottom: 1px solid #24405b;
      border-right: 1px solid #24405b;
      transform: rotate(-45deg) translateY(-55%);
      transition: all .4s ease-in-out;
      transform-origin: 50% 0; }
    .component.job-list-spot .component-content .custom-option:hover, .component.job-list-spot .component-content .custom-option.selection {
      color: #74cecd; }
      .component.job-list-spot .component-content .custom-option:hover::after, .component.job-list-spot .component-content .custom-option.selection::after {
        border-bottom: 1px solid #74cecd;
        border-right: 1px solid #74cecd; }
  .component.job-list-spot .component-content .opened .custom-select-trigger::after {
    margin-top: 3px;
    transform: rotate(-135deg) translateY(-50%); }
  .component.job-list-spot .component-content .opened .custom-options {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    transform: translateY(0px); }
  .component.job-list-spot .component-content .select-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .component.job-list-spot .component-content .custom-select-wrapper {
    display: none;
    margin-bottom: 0.5rem;
    width: 100%; }
    @media only screen and (min-width: 992px) {
      .component.job-list-spot .component-content .custom-select-wrapper {
        width: 49%; } }
    .component.job-list-spot .component-content .custom-select-wrapper.country-selected {
      display: block; }
    .component.job-list-spot .component-content .custom-select-wrapper.departments {
      display: none; }
  .component.job-list-spot .component-content .custom-select-trigger,
  .component.job-list-spot .component-content .custom-options {
    background-color: #f4f4f4; }
  .component.job-list-spot .component-content .custom-select {
    width: 100%;
    display: block; }
    .component.job-list-spot .component-content .custom-select.opened {
      z-index: 9999; }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

@keyframes in {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

@keyframes out {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

.component.job-details .component-content {
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  flex-direction: column; }
  @media only screen and (min-width: 768px) {
    .component.job-details .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      .component.job-details .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .component.job-details .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .component.job-details .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }
  @media only screen and (min-width: 992px) {
    .component.job-details .component-content {
      flex-direction: row;
      justify-content: space-between; } }
  .component.job-details .component-content .job-details-content {
    order: 1;
    margin-bottom: 2rem; }
    @media only screen and (min-width: 992px) {
      .component.job-details .component-content .job-details-content {
        padding-right: 6rem; } }
    .component.job-details .component-content .job-details-content ul li {
      list-style: disc outside; }
  .component.job-details .component-content .right-content {
    order: 2;
    height: 13rem;
    position: relative;
    background-color: #dcdcd6;
    padding: 17px; }
    @media only screen and (min-width: 992px) {
      .component.job-details .component-content .right-content {
        min-width: 20rem;
        height: 15rem; } }
  .component.job-details .component-content .job-details-facts-inner {
    line-height: 2rem; }
  .component.job-details .component-content .fact-key {
    clear: both;
    float: left; }
  .component.job-details .component-content .fact-value {
    float: right; }
  .component.job-details .component-content .button {
    margin-bottom: 17px;
    margin-left: 17px;
    position: absolute;
    bottom: 0;
    left: 0;
    animation: out 0.7s -0.1s ease-in-out forwards;
    background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
    background-size: 420% 100%;
    background-position: right bottom;
    background-color: #3344f6;
    color: #ffffff;
    transition: all 0.4s ease-in-out;
    font-size: 12px;
    color: #ffffff;
    background-color: #3344f6;
    border: 0;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    border-radius: 19.5px;
    padding: 11px 26px 12px; }
    .component.job-details .component-content .button:hover {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
      .component.job-details .component-content .button:hover a {
        color: #3344f6;
        transition: all 0.8s ease-in-out; }
    @media only screen and (min-width: 768px) {
      .component.job-details .component-content .button {
        font-size: 15px; } }
    .component.job-details .component-content .button a {
      color: #ffffff;
      text-decoration: none; }
    @media only screen and (min-width: 768px) {
      .component.job-details .component-content .button {
        border-radius: 25px;
        padding: 14px 35px 15px; } }
  .component.job-details .component-content h2 {
    padding-bottom: 1rem; }
    @media only screen and (min-width: 992px) {
      .component.job-details .component-content h2 {
        padding-bottom: 1.5rem; } }

/*.job-details-facts-inner {
     display: grid;
   }

   .fact-key {
     grid-column: 1;
   }

   .fact-value {
     grid-column: 2;
   }
   */
.component.geo-ip-prompt {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: none;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0; }
  .component.geo-ip-prompt.show {
    display: flex !important; }
  .component.geo-ip-prompt .component-content {
    background-color: #f7f6f4;
    max-width: 45rem;
    padding: 2rem;
    position: relative;
    text-align: center;
    width: 70%; }
    .component.geo-ip-prompt .component-content .geo-ip-prompt-dismiss {
      background-color: white;
      border: 0.15rem solid #dad6d0;
      border-radius: 50%;
      cursor: pointer;
      font-size: 1rem;
      height: 1.2rem;
      position: absolute;
      right: 2rem;
      top: 2rem;
      padding: 0.1rem;
      width: 1.2rem; }
    .component.geo-ip-prompt .component-content .geo-ip-prompt-title {
      font-family: "Soho Gothic W01 Bold", "Open Sans Bold", "Arial", sans-serif;
      font-size: 2rem;
      margin: 1.5rem 0; }
    .component.geo-ip-prompt .component-content .geo-ip-prompt-intro {
      font-size: 1.25rem;
      margin: 0 auto 1.5rem auto;
      width: 80%; }
    .component.geo-ip-prompt .component-content .geo-ip-prompt-countryspecificsite a,
    .component.geo-ip-prompt .component-content .geo-ip-prompt-globalsite a,
    .component.geo-ip-prompt .component-content .geo-ip-prompt-currentsite a {
      border: inherit;
      color: white;
      cursor: pointer;
      display: block;
      font-family: inherit;
      height: 100%;
      margin: 1rem auto;
      max-width: 20rem;
      padding: 1rem;
      text-align: center;
      width: 90%; }

.component.geo-ip-prompt {
  margin: 0 !important; }
  .component.geo-ip-prompt .geo-ip-prompt-dismiss {
    line-height: 1rem; }
  .component.geo-ip-prompt .geo-ip-prompt-countryspecificsite > a,
  .component.geo-ip-prompt .geo-ip-prompt-currentsite > a,
  .component.geo-ip-prompt .geo-ip-prompt-globalsite > a {
    box-sizing: border-box;
    padding: 1rem 0 !important;
    width: 100% !important;
    animation: out 0.7s -0.1s ease-in-out forwards;
    background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
    background-size: 420% 100%;
    background-position: right bottom;
    background-color: #3344f6;
    color: #ffffff;
    transition: all 0.4s ease-in-out;
    font-size: 12px;
    color: #ffffff;
    background-color: #3344f6;
    border: 0;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    border-radius: 19.5px;
    padding: 11px 26px 12px; }
    .component.geo-ip-prompt .geo-ip-prompt-countryspecificsite > a:hover,
    .component.geo-ip-prompt .geo-ip-prompt-currentsite > a:hover,
    .component.geo-ip-prompt .geo-ip-prompt-globalsite > a:hover {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
      .component.geo-ip-prompt .geo-ip-prompt-countryspecificsite > a:hover a,
      .component.geo-ip-prompt .geo-ip-prompt-currentsite > a:hover a,
      .component.geo-ip-prompt .geo-ip-prompt-globalsite > a:hover a {
        color: #3344f6;
        transition: all 0.8s ease-in-out; }
    @media only screen and (min-width: 768px) {
      .component.geo-ip-prompt .geo-ip-prompt-countryspecificsite > a,
      .component.geo-ip-prompt .geo-ip-prompt-currentsite > a,
      .component.geo-ip-prompt .geo-ip-prompt-globalsite > a {
        font-size: 15px; } }
    .component.geo-ip-prompt .geo-ip-prompt-countryspecificsite > a a,
    .component.geo-ip-prompt .geo-ip-prompt-currentsite > a a,
    .component.geo-ip-prompt .geo-ip-prompt-globalsite > a a {
      color: #ffffff;
      text-decoration: none; }
    @media only screen and (min-width: 768px) {
      .component.geo-ip-prompt .geo-ip-prompt-countryspecificsite > a,
      .component.geo-ip-prompt .geo-ip-prompt-currentsite > a,
      .component.geo-ip-prompt .geo-ip-prompt-globalsite > a {
        border-radius: 25px;
        padding: 14px 35px 15px; } }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

@keyframes in {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

@keyframes out {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

::selection {
  background: #edb833; }

.component.blog-categories .component-content {
  margin-left: 30px;
  margin-right: 30px; }
  @media only screen and (min-width: 768px) {
    .component.blog-categories .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      .component.blog-categories .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .component.blog-categories .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .component.blog-categories .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }

.component.blog-categories .blog-categories-content-wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; }
  @media only screen and (min-width: 768px) {
    .component.blog-categories .blog-categories-content-wrapper {
      justify-content: center; } }

.component.blog-categories .blog-category a {
  margin: 5px 0;
  min-width: 20vw;
  text-align: center; }
  @media only screen and (min-width: 768px) {
    .component.blog-categories .blog-category a {
      margin: 10px;
      min-width: 60px; } }
  .component.blog-categories .blog-category a:not(.active) {
    animation: out 0.7s -0.1s ease-in-out forwards;
    background: radial-gradient(50% 300% at right, #3344f6 50%, transparent 50%), radial-gradient(circle at right, #ffffff 100%, transparent 100%);
    background-size: 420% 100%;
    background-position: right bottom;
    background-color: #ffffff;
    color: #3344f6;
    transition: all 0.4s ease-in-out;
    font-size: 11px;
    color: #ffffff;
    background-color: #3344f6;
    border: 0;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    border-radius: 16.5px;
    padding: 7px 21px 8px;
    color: #3344f6;
    background-color: #ffffff;
    border: 1px solid #3344f6;
    border-radius: 21px; }
    .component.blog-categories .blog-category a:not(.active):hover {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #3344f6 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(51, 68, 246, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #ffffff 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #ffffff;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #ffffff;
      transition: all 0.8s ease-in-out; }
      .component.blog-categories .blog-category a:not(.active):hover a {
        color: #ffffff;
        transition: all 0.8s ease-in-out; }
    @media only screen and (min-width: 768px) {
      .component.blog-categories .blog-category a:not(.active) {
        font-size: 13px; } }
    .component.blog-categories .blog-category a:not(.active) a {
      color: #ffffff;
      text-decoration: none; }
    @media only screen and (min-width: 768px) {
      .component.blog-categories .blog-category a:not(.active) {
        border-radius: 18.5px;
        padding: 9px 25px 10px; } }
    .component.blog-categories .blog-category a:not(.active) a {
      color: #3344f6; }
    @media only screen and (min-width: 768px) {
      .component.blog-categories .blog-category a:not(.active) {
        border-radius: 21px; } }
  .component.blog-categories .blog-category a.active {
    animation: out 0.7s -0.1s ease-in-out forwards;
    background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
    background-size: 420% 100%;
    background-position: right bottom;
    background-color: #3344f6;
    color: #ffffff;
    transition: all 0.4s ease-in-out;
    font-size: 11px;
    color: #ffffff;
    background-color: #3344f6;
    border: 0;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    border-radius: 16.5px;
    padding: 7px 21px 8px;
    border: 1px solid #ffffff;
    border-radius: 21px; }
    .component.blog-categories .blog-category a.active:hover {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
      .component.blog-categories .blog-category a.active:hover a {
        color: #3344f6;
        transition: all 0.8s ease-in-out; }
    @media only screen and (min-width: 768px) {
      .component.blog-categories .blog-category a.active {
        font-size: 13px; } }
    .component.blog-categories .blog-category a.active a {
      color: #ffffff;
      text-decoration: none; }
    @media only screen and (min-width: 768px) {
      .component.blog-categories .blog-category a.active {
        border-radius: 18.5px;
        padding: 9px 25px 10px; } }
    @media only screen and (min-width: 768px) {
      .component.blog-categories .blog-category a.active {
        border-radius: 21px; } }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

@keyframes in {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

@keyframes out {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
.component.blog-author .component-content {
  margin-left: 30px;
  margin-right: 30px; }
  @media only screen and (min-width: 768px) {
    .component.blog-author .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      .component.blog-author .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .component.blog-author .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .component.blog-author .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }
  @media only screen and (min-width: 992px) {
    .component.blog-author .component-content {
      margin-left: 30px;
      margin-right: 30px; } }
  @media only screen and (min-width: 992px) and (min-width: 768px) {
    .component.blog-author .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 47.2%;
      max-width: 660px; }
      .component.blog-author .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .component.blog-author .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .component.blog-author .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }

.component.blog-author .blog-author-about,
.component.blog-author .blog-author-image,
.component.blog-author .blog-author-name,
.component.blog-author .blog-author-title,
.component.blog-author .blog-author-intro {
  display: none; }

.component.blog-author .blog-author-link {
  text-align: center; }

.component.blog-author .link-author-value {
  animation: out 0.7s -0.1s ease-in-out forwards;
  background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
  background-size: 420% 100%;
  background-position: right bottom;
  background-color: #3344f6;
  color: #ffffff;
  transition: all 0.4s ease-in-out;
  font-size: 11px;
  color: #ffffff;
  background-color: #3344f6;
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: bold;
  border-radius: 16.5px;
  padding: 7px 21px 8px; }
  .component.blog-author .link-author-value:hover {
    animation: in 0.7s -0.1s ease-in-out forwards;
    background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
    background-size: 420% 100%;
    background-position: right bottom;
    background-color: #3344f6;
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
    color: #3344f6;
    transition: all 0.8s ease-in-out; }
    .component.blog-author .link-author-value:hover a {
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
  @media only screen and (min-width: 768px) {
    .component.blog-author .link-author-value {
      font-size: 13px; } }
  .component.blog-author .link-author-value a {
    color: #ffffff;
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .component.blog-author .link-author-value {
      border-radius: 18.5px;
      padding: 9px 25px 10px; } }

.component.author-details .component-content {
  margin-left: 30px;
  margin-right: 30px; }
  @media only screen and (min-width: 768px) {
    .component.author-details .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      .component.author-details .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .component.author-details .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .component.author-details .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }
  @media only screen and (min-width: 992px) {
    .component.author-details .component-content {
      display: flex;
      justify-content: space-between; } }

@media only screen and (min-width: 992px) {
  .component.author-details .author-image {
    max-width: 40%;
    width: 35%; } }

.component.author-details .author-image img {
  max-width: 100%;
  border-radius: 50%; }

@media only screen and (min-width: 992px) {
  .component.author-details .author-intro {
    width: 55%; } }

.component.author-details .author-intro h2 {
  font-size: 20px;
  text-align: center;
  margin: 20px 0 30px; }
  @media only screen and (min-width: 768px) {
    .component.author-details .author-intro h2 {
      font-size: 30px; } }
  @media only screen and (min-width: 992px) {
    .component.author-details .author-intro h2 {
      text-align: left; } }

.component.author-details .author-header {
  display: none; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
.component.blog .component-content {
  margin-left: 30px;
  margin-right: 30px; }
  @media only screen and (min-width: 768px) {
    .component.blog .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      .component.blog .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .component.blog .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .component.blog .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }
  @media only screen and (min-width: 992px) {
    .component.blog .component-content {
      margin-left: 30px;
      margin-right: 30px; } }
  @media only screen and (min-width: 992px) and (min-width: 768px) {
    .component.blog .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 47.2%;
      max-width: 660px; }
      .component.blog .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .component.blog .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .component.blog .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }

.component.blog .blog-title {
  font-size: 22px;
  font-weight: normal; }
  @media only screen and (min-width: 768px) {
    .component.blog .blog-title {
      font-size: 22px; } }

.component.blog .blog-reading-time {
  display: none; }

.component.blog .blog-author[id$="spanAuthor"] {
  display: block !important;
  font-size: 15px; }
  @media only screen and (min-width: 768px) {
    .component.blog .blog-author[id$="spanAuthor"] {
      font-size: 17px; } }

.component.blog .blog-author[id$="linkAuthor"] {
  display: none !important; }

.component.blog .blog-date {
  margin: 30px 0 10px; }

.component.blog-list .component-content {
  margin-left: 30px;
  margin-right: 30px;
  text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .component.blog-list .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      .component.blog-list .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .component.blog-list .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .component.blog-list .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }
  .component.blog-list .component-content > ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start; }
    .component.blog-list .component-content > ul > li {
      margin-bottom: 70px;
      box-sizing: border-box;
      display: none; }
      .component.blog-list .component-content > ul > li:nth-child(-n + 6) {
        display: block; }
      @media only screen and (min-width: 992px) {
        .component.blog-list .component-content > ul > li {
          width: 33.33333%;
          padding: 0 8px; } }
      .component.blog-list .component-content > ul > li > a {
        text-decoration: none;
        color: #24405b; }
    .component.blog-list .component-content > ul::after {
      content: "";
      flex: auto; }
  .component.blog-list .component-content .load-more {
    display: flex;
    justify-content: center; }
    .component.blog-list .component-content .load-more .button {
      animation: out 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      color: #ffffff;
      transition: all 0.4s ease-in-out;
      font-size: 15px;
      color: #ffffff;
      background-color: #3344f6;
      border: 0;
      cursor: pointer;
      display: inline-block;
      text-decoration: none;
      font-weight: bold;
      border-radius: 25px;
      padding: 11px 26px 12px; }
      .component.blog-list .component-content .load-more .button:hover {
        animation: in 0.7s -0.1s ease-in-out forwards;
        background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
        background-size: 420% 100%;
        background-position: right bottom;
        background-color: #3344f6;
        box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
        color: #3344f6;
        transition: all 0.8s ease-in-out; }
        .component.blog-list .component-content .load-more .button:hover a {
          color: #3344f6;
          transition: all 0.8s ease-in-out; }
      @media only screen and (min-width: 768px) {
        .component.blog-list .component-content .load-more .button {
          font-size: 17px; } }
      .component.blog-list .component-content .load-more .button a {
        color: #ffffff;
        text-decoration: none; }
      @media only screen and (min-width: 768px) {
        .component.blog-list .component-content .load-more .button {
          border-radius: 23px;
          padding: 12.5px 35px 14.5px; } }

.component.blog-list .news-image {
  width: 70%;
  margin: 0 auto; }
  @media only screen and (min-width: 992px) {
    .component.blog-list .news-image {
      width: 100%; } }
  .component.blog-list .news-image img {
    width: 100%; }

.component.blog-list .news-link,
.component.blog-list .author-blog-link {
  animation: out 0.7s -0.1s ease-in-out forwards;
  background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
  background-size: 420% 100%;
  background-position: right bottom;
  background-color: #3344f6;
  color: #ffffff;
  transition: all 0.4s ease-in-out;
  font-size: 11px;
  color: #ffffff;
  background-color: #3344f6;
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: bold;
  border-radius: 16.5px;
  padding: 7px 21px 8px;
  float: right; }
  .component.blog-list .news-link:hover,
  .component.blog-list .author-blog-link:hover {
    animation: in 0.7s -0.1s ease-in-out forwards;
    background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
    background-size: 420% 100%;
    background-position: right bottom;
    background-color: #3344f6;
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
    color: #3344f6;
    transition: all 0.8s ease-in-out; }
    .component.blog-list .news-link:hover a,
    .component.blog-list .author-blog-link:hover a {
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
  @media only screen and (min-width: 768px) {
    .component.blog-list .news-link,
    .component.blog-list .author-blog-link {
      font-size: 13px; } }
  .component.blog-list .news-link a,
  .component.blog-list .author-blog-link a {
    color: #ffffff;
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .component.blog-list .news-link,
    .component.blog-list .author-blog-link {
      border-radius: 18.5px;
      padding: 9px 25px 10px; } }

.component.blog-list .news-title,
.component.blog-list .author-blog-title {
  width: 70%;
  margin: 8px auto 8px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: -0.23px; }
  @media only screen and (min-width: 992px) {
    .component.blog-list .news-title,
    .component.blog-list .author-blog-title {
      width: 100%;
      margin: 16px auto 16px; } }
  @media only screen and (min-width: 768px) {
    .component.blog-list .news-title,
    .component.blog-list .author-blog-title {
      font-size: 17px; } }

.component.blog-list .news-text,
.component.blog-list .author-blog-text {
  font-weight: normal;
  margin-bottom: 8px; }
  @media only screen and (min-width: 992px) {
    .component.blog-list .news-text,
    .component.blog-list .author-blog-text {
      margin-bottom: 32px; } }

.component.blog-list .news-date,
.component.blog-list .author-blog-date {
  font-weight: normal;
  margin-bottom: 8px; }
  @media only screen and (min-width: 992px) {
    .component.blog-list .news-date,
    .component.blog-list .author-blog-date {
      margin-bottom: 16px; } }

.component.related-blogs .component-content,
.component.author-related-blogs .component-content {
  margin-left: 30px;
  margin-right: 30px;
  text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .component.related-blogs .component-content,
    .component.author-related-blogs .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      .component.related-blogs .component-content.full-width,
      .component.author-related-blogs .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .component.related-blogs .component-content.regular,
      .component.author-related-blogs .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .component.related-blogs .component-content.slim,
      .component.author-related-blogs .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }
  .component.related-blogs .component-content > ul,
  .component.author-related-blogs .component-content > ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start; }
    .component.related-blogs .component-content > ul > li,
    .component.author-related-blogs .component-content > ul > li {
      margin-bottom: 70px;
      box-sizing: border-box;
      display: none; }
      .component.related-blogs .component-content > ul > li:nth-child(-n + 6),
      .component.author-related-blogs .component-content > ul > li:nth-child(-n + 6) {
        display: block; }
      @media only screen and (min-width: 992px) {
        .component.related-blogs .component-content > ul > li,
        .component.author-related-blogs .component-content > ul > li {
          width: 33.33333%;
          padding: 0 8px; } }
      .component.related-blogs .component-content > ul > li > a,
      .component.author-related-blogs .component-content > ul > li > a {
        text-decoration: none;
        color: #24405b; }
    .component.related-blogs .component-content > ul::after,
    .component.author-related-blogs .component-content > ul::after {
      content: "";
      flex: auto; }
  .component.related-blogs .component-content .load-more,
  .component.author-related-blogs .component-content .load-more {
    display: flex;
    justify-content: center; }
    .component.related-blogs .component-content .load-more .button,
    .component.author-related-blogs .component-content .load-more .button {
      animation: out 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      color: #ffffff;
      transition: all 0.4s ease-in-out;
      font-size: 15px;
      color: #ffffff;
      background-color: #3344f6;
      border: 0;
      cursor: pointer;
      display: inline-block;
      text-decoration: none;
      font-weight: bold;
      border-radius: 25px;
      padding: 11px 26px 12px; }
      .component.related-blogs .component-content .load-more .button:hover,
      .component.author-related-blogs .component-content .load-more .button:hover {
        animation: in 0.7s -0.1s ease-in-out forwards;
        background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
        background-size: 420% 100%;
        background-position: right bottom;
        background-color: #3344f6;
        box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
        color: #3344f6;
        transition: all 0.8s ease-in-out; }
        .component.related-blogs .component-content .load-more .button:hover a,
        .component.author-related-blogs .component-content .load-more .button:hover a {
          color: #3344f6;
          transition: all 0.8s ease-in-out; }
      @media only screen and (min-width: 768px) {
        .component.related-blogs .component-content .load-more .button,
        .component.author-related-blogs .component-content .load-more .button {
          font-size: 17px; } }
      .component.related-blogs .component-content .load-more .button a,
      .component.author-related-blogs .component-content .load-more .button a {
        color: #ffffff;
        text-decoration: none; }
      @media only screen and (min-width: 768px) {
        .component.related-blogs .component-content .load-more .button,
        .component.author-related-blogs .component-content .load-more .button {
          border-radius: 23px;
          padding: 12.5px 35px 14.5px; } }

.component.related-blogs .news-image,
.component.author-related-blogs .news-image {
  width: 70%;
  margin: 0 auto; }
  @media only screen and (min-width: 992px) {
    .component.related-blogs .news-image,
    .component.author-related-blogs .news-image {
      width: 100%; } }
  .component.related-blogs .news-image img,
  .component.author-related-blogs .news-image img {
    width: 100%; }

.component.related-blogs .news-link,
.component.related-blogs .author-blog-link,
.component.author-related-blogs .news-link,
.component.author-related-blogs .author-blog-link {
  animation: out 0.7s -0.1s ease-in-out forwards;
  background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
  background-size: 420% 100%;
  background-position: right bottom;
  background-color: #3344f6;
  color: #ffffff;
  transition: all 0.4s ease-in-out;
  font-size: 11px;
  color: #ffffff;
  background-color: #3344f6;
  border: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-weight: bold;
  border-radius: 16.5px;
  padding: 7px 21px 8px;
  float: right; }
  .component.related-blogs .news-link:hover,
  .component.related-blogs .author-blog-link:hover,
  .component.author-related-blogs .news-link:hover,
  .component.author-related-blogs .author-blog-link:hover {
    animation: in 0.7s -0.1s ease-in-out forwards;
    background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
    background-size: 420% 100%;
    background-position: right bottom;
    background-color: #3344f6;
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
    color: #3344f6;
    transition: all 0.8s ease-in-out; }
    .component.related-blogs .news-link:hover a,
    .component.related-blogs .author-blog-link:hover a,
    .component.author-related-blogs .news-link:hover a,
    .component.author-related-blogs .author-blog-link:hover a {
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
  @media only screen and (min-width: 768px) {
    .component.related-blogs .news-link,
    .component.related-blogs .author-blog-link,
    .component.author-related-blogs .news-link,
    .component.author-related-blogs .author-blog-link {
      font-size: 13px; } }
  .component.related-blogs .news-link a,
  .component.related-blogs .author-blog-link a,
  .component.author-related-blogs .news-link a,
  .component.author-related-blogs .author-blog-link a {
    color: #ffffff;
    text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .component.related-blogs .news-link,
    .component.related-blogs .author-blog-link,
    .component.author-related-blogs .news-link,
    .component.author-related-blogs .author-blog-link {
      border-radius: 18.5px;
      padding: 9px 25px 10px; } }

.component.related-blogs .news-title,
.component.related-blogs .author-blog-title,
.component.author-related-blogs .news-title,
.component.author-related-blogs .author-blog-title {
  width: 70%;
  margin: 8px auto 8px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.29;
  letter-spacing: -0.23px; }
  @media only screen and (min-width: 992px) {
    .component.related-blogs .news-title,
    .component.related-blogs .author-blog-title,
    .component.author-related-blogs .news-title,
    .component.author-related-blogs .author-blog-title {
      width: 100%;
      margin: 16px auto 16px; } }
  @media only screen and (min-width: 768px) {
    .component.related-blogs .news-title,
    .component.related-blogs .author-blog-title,
    .component.author-related-blogs .news-title,
    .component.author-related-blogs .author-blog-title {
      font-size: 17px; } }

.component.related-blogs .news-text,
.component.related-blogs .author-blog-text,
.component.author-related-blogs .news-text,
.component.author-related-blogs .author-blog-text {
  font-weight: normal;
  margin-bottom: 8px; }
  @media only screen and (min-width: 992px) {
    .component.related-blogs .news-text,
    .component.related-blogs .author-blog-text,
    .component.author-related-blogs .news-text,
    .component.author-related-blogs .author-blog-text {
      margin-bottom: 32px; } }

.component.related-blogs .news-date,
.component.related-blogs .author-blog-date,
.component.author-related-blogs .news-date,
.component.author-related-blogs .author-blog-date {
  font-weight: normal;
  margin-bottom: 8px; }
  @media only screen and (min-width: 992px) {
    .component.related-blogs .news-date,
    .component.related-blogs .author-blog-date,
    .component.author-related-blogs .news-date,
    .component.author-related-blogs .author-blog-date {
      margin-bottom: 16px; } }

.component.related-blogs .news-text,
.component.related-blogs .news-date,
.component.related-blogs .author-blog-date,
.component.related-blogs .author-blog-text,
.component.author-related-blogs .news-text,
.component.author-related-blogs .news-date,
.component.author-related-blogs .author-blog-date,
.component.author-related-blogs .author-blog-text {
  color: #5b7085; }

.component.related-blogs .related-title,
.component.related-blogs .related-blogs-title,
.component.author-related-blogs .related-title,
.component.author-related-blogs .related-blogs-title {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.33;
  letter-spacing: -0.6px;
  font-weight: 300;
  letter-spacing: -1px;
  margin-bottom: 32px; }
  @media only screen and (min-width: 768px) {
    .component.related-blogs .related-title,
    .component.related-blogs .related-blogs-title,
    .component.author-related-blogs .related-title,
    .component.author-related-blogs .related-blogs-title {
      font-size: 30px; } }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

@keyframes in {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

@keyframes out {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

.component.comments {
  background-color: #f4f4f4; }
  .component.comments .component-content {
    margin-left: 30px;
    margin-right: 30px;
    padding: 20px 0; }
    @media only screen and (min-width: 768px) {
      .component.comments .component-content {
        margin-left: auto;
        margin-right: auto;
        width: 86%;
        max-width: 1200px; }
        .component.comments .component-content.full-width {
          width: 100%;
          max-width: 1600px; }
        .component.comments .component-content.regular {
          width: 86%;
          max-width: 1200px; }
        .component.comments .component-content.slim {
          width: 47.2%;
          max-width: 660px; } }
    @media only screen and (min-width: 992px) {
      .component.comments .component-content {
        margin-left: 30px;
        margin-right: 30px; } }
  @media only screen and (min-width: 992px) and (min-width: 768px) {
    .component.comments .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 47.2%;
      max-width: 660px; }
      .component.comments .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .component.comments .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .component.comments .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }
  .component.comments .comments-summary {
    margin-bottom: 10px; }
  .component.comments .anonymous-comment-form {
    margin-bottom: 20px; }
    .component.comments .anonymous-comment-form h3 {
      font-weight: normal; }
    .component.comments .anonymous-comment-form input.username-textbox,
    .component.comments .anonymous-comment-form input.email-textbox,
    .component.comments .anonymous-comment-form textarea.comment-textbox {
      font-size: 16px;
      border: 1px solid #24405b;
      background-color: transparent;
      padding: 10px;
      color: #24405b;
      width: 100% !important;
      box-sizing: border-box; }
      @media only screen and (min-width: 992px) {
        .component.comments .anonymous-comment-form input.username-textbox,
        .component.comments .anonymous-comment-form input.email-textbox,
        .component.comments .anonymous-comment-form textarea.comment-textbox {
          font-size: 16px; } }
      .component.comments .anonymous-comment-form input.username-textbox::placeholder,
      .component.comments .anonymous-comment-form input.email-textbox::placeholder,
      .component.comments .anonymous-comment-form textarea.comment-textbox::placeholder {
        color: #5b7085; }
    .component.comments .anonymous-comment-form h3,
    .component.comments .anonymous-comment-form .username,
    .component.comments .anonymous-comment-form .email,
    .component.comments .anonymous-comment-form .comment,
    .component.comments .anonymous-comment-form .g-recaptcha,
    .component.comments .anonymous-comment-form .disclaimer {
      margin-bottom: 10px; }
    .component.comments .anonymous-comment-form .captcha {
      margin-bottom: 5px; }
    .component.comments .anonymous-comment-form input[type="submit"] {
      animation: out 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      color: #ffffff;
      transition: all 0.4s ease-in-out;
      font-size: 11px;
      color: #ffffff;
      background-color: #3344f6;
      border: 0;
      cursor: pointer;
      display: inline-block;
      text-decoration: none;
      font-weight: bold;
      border-radius: 16.5px;
      padding: 7px 21px 8px; }
      .component.comments .anonymous-comment-form input[type="submit"]:hover {
        animation: in 0.7s -0.1s ease-in-out forwards;
        background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
        background-size: 420% 100%;
        background-position: right bottom;
        background-color: #3344f6;
        box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
        color: #3344f6;
        transition: all 0.8s ease-in-out; }
        .component.comments .anonymous-comment-form input[type="submit"]:hover a {
          color: #3344f6;
          transition: all 0.8s ease-in-out; }
      @media only screen and (min-width: 768px) {
        .component.comments .anonymous-comment-form input[type="submit"] {
          font-size: 13px; } }
      .component.comments .anonymous-comment-form input[type="submit"] a {
        color: #ffffff;
        text-decoration: none; }
      @media only screen and (min-width: 768px) {
        .component.comments .anonymous-comment-form input[type="submit"] {
          border-radius: 18.5px;
          padding: 9px 25px 10px; } }
  .component.comments .comments-list-content h3 {
    font-weight: normal;
    font-size: 15px; }
    @media only screen and (min-width: 768px) {
      .component.comments .comments-list-content h3 {
        font-size: 17px; } }
  .component.comments .userNameText {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 20px; }
    @media only screen and (min-width: 768px) {
      .component.comments .userNameText {
        font-size: 17px; } }
  .component.comments .topCommentsRow {
    margin-top: 20px;
    text-align: center; }
  .component.comments .totalComments {
    display: none; }
  .component.comments .joinconv-linkbutton {
    opacity: 0;
    animation: out 0.7s -0.1s ease-in-out forwards;
    background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
    background-size: 420% 100%;
    background-position: right bottom;
    background-color: #3344f6;
    color: #ffffff;
    transition: all 0.4s ease-in-out;
    font-size: 11px;
    color: #ffffff;
    background-color: #3344f6;
    border: 0;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    border-radius: 16.5px;
    padding: 7px 21px 8px; }
    .component.comments .joinconv-linkbutton:hover {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
      .component.comments .joinconv-linkbutton:hover a {
        color: #3344f6;
        transition: all 0.8s ease-in-out; }
    @media only screen and (min-width: 768px) {
      .component.comments .joinconv-linkbutton {
        font-size: 13px; } }
    .component.comments .joinconv-linkbutton a {
      color: #ffffff;
      text-decoration: none; }
    @media only screen and (min-width: 768px) {
      .component.comments .joinconv-linkbutton {
        border-radius: 18.5px;
        padding: 9px 25px 10px; } }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

@keyframes in {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

@keyframes out {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

::selection {
  background: #edb833; }

.component[class*="blog"] + .component.form .component-content {
  margin-left: 30px;
  margin-right: 30px; }
  @media only screen and (min-width: 768px) {
    .component[class*="blog"] + .component.form .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      .component[class*="blog"] + .component.form .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .component[class*="blog"] + .component.form .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .component[class*="blog"] + .component.form .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }
  @media only screen and (min-width: 768px) {
    .component[class*="blog"] + .component.form .component-content {
      max-width: 460px; } }
  @media only screen and (min-width: 1200px) {
    .component[class*="blog"] + .component.form .component-content {
      margin-left: 30px;
      margin-right: 30px; } }
  @media only screen and (min-width: 1200px) and (min-width: 768px) {
    .component[class*="blog"] + .component.form .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 47.2%;
      max-width: 660px; }
      .component[class*="blog"] + .component.form .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .component[class*="blog"] + .component.form .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .component[class*="blog"] + .component.form .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }
  .component[class*="blog"] + .component.form .component-content * {
    margin: 0;
    padding: 0;
    line-height: normal;
    font-size: 12px;
    font-family: "Muli", sans-serif;
    font-weight: normal;
    line-height: 1.4;
    color: #24405b; }
    @media only screen and (min-width: 768px) {
      .component[class*="blog"] + .component.form .component-content * {
        font-size: 15px; } }
  .component[class*="blog"] + .component.form .component-content .scfForm {
    position: relative; }
  .component[class*="blog"] + .component.form .component-content fieldset {
    border: 0; }
  .component[class*="blog"] + .component.form .component-content legend {
    font-size: 15px;
    font-weight: normal;
    margin: 0 40px 20px; }
    @media only screen and (min-width: 768px) {
      .component[class*="blog"] + .component.form .component-content legend {
        font-size: 17px; } }
    @media only screen and (min-width: 1200px) {
      .component[class*="blog"] + .component.form .component-content legend {
        text-align: center;
        margin: 0 100px 20px; } }
  .component[class*="blog"] + .component.form .component-content .scfSectionContent,
  .component[class*="blog"] + .component.form .component-content .scfEmailGeneralPanel {
    width: 100%; }
  .component[class*="blog"] + .component.form .component-content label {
    display: none; }
  .component[class*="blog"] + .component.form .component-content input[type="text"] {
    padding: 5px 100px 5px 15px;
    border-radius: 20px;
    border: 2px solid #74cecd;
    width: 100%;
    font-size: 16px; }
    @media only screen and (min-width: 992px) {
      .component[class*="blog"] + .component.form .component-content input[type="text"] {
        font-size: 16px; } }
    @media only screen and (min-width: 768px) {
      .component[class*="blog"] + .component.form .component-content input[type="text"] {
        padding: 8px 100px 9px 15px; } }
  .component[class*="blog"] + .component.form .component-content .scfValidator,
  .component[class*="blog"] + .component.form .component-content .scfValidationSummary {
    position: absolute;
    bottom: -20px;
    left: 17px;
    right: 0; }
  .component[class*="blog"] + .component.form .component-content input[type="submit"] {
    animation: out 0.7s -0.1s ease-in-out forwards;
    background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
    background-size: 420% 100%;
    background-position: right bottom;
    background-color: #3344f6;
    color: #ffffff;
    transition: all 0.4s ease-in-out;
    font-size: 11px;
    color: #ffffff;
    background-color: #3344f6;
    border: 0;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    border-radius: 16.5px;
    padding: 7px 21px 8px;
    position: absolute;
    bottom: 3px;
    right: 3px; }
    .component[class*="blog"] + .component.form .component-content input[type="submit"]:hover {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
      .component[class*="blog"] + .component.form .component-content input[type="submit"]:hover a {
        color: #3344f6;
        transition: all 0.8s ease-in-out; }
    @media only screen and (min-width: 768px) {
      .component[class*="blog"] + .component.form .component-content input[type="submit"] {
        font-size: 13px; } }
    .component[class*="blog"] + .component.form .component-content input[type="submit"] a {
      color: #ffffff;
      text-decoration: none; }
    @media only screen and (min-width: 768px) {
      .component[class*="blog"] + .component.form .component-content input[type="submit"] {
        border-radius: 18.5px;
        padding: 9px 25px 10px; } }
  .component[class*="blog"] + .component.form .component-content .scfRequired,
  .component[class*="blog"] + .component.form .component-content .scfValidatorRequired,
  .component[class*="blog"] + .component.form .component-content .scfValidationSummary {
    display: none; }

::selection {
  background: #edb833; }

.component.rich-content-spot .component-content {
  margin-left: 30px;
  margin-right: 30px; }
  @media only screen and (min-width: 768px) {
    .component.rich-content-spot .component-content {
      margin-left: auto;
      margin-right: auto;
      width: 47.2%;
      max-width: 660px; }
      .component.rich-content-spot .component-content.full-width {
        width: 100%;
        max-width: 1600px; }
      .component.rich-content-spot .component-content.regular {
        width: 86%;
        max-width: 1200px; }
      .component.rich-content-spot .component-content.slim {
        width: 47.2%;
        max-width: 660px; } }

#wrapper #content .flex-spot.arrow-links {
  padding: 2rem 0;
  background: #badcdf; }
  #wrapper #content .flex-spot.arrow-links * {
    box-sizing: border-box; }
  #wrapper #content .flex-spot.arrow-links .component-content {
    margin-left: 30px;
    margin-right: 30px; }
    @media only screen and (min-width: 768px) {
      #wrapper #content .flex-spot.arrow-links .component-content {
        margin-left: auto;
        margin-right: auto;
        width: 86%;
        max-width: 1200px; }
        #wrapper #content .flex-spot.arrow-links .component-content.full-width {
          width: 100%;
          max-width: 1600px; }
        #wrapper #content .flex-spot.arrow-links .component-content.regular {
          width: 86%;
          max-width: 1200px; }
        #wrapper #content .flex-spot.arrow-links .component-content.slim {
          width: 47.2%;
          max-width: 660px; } }
    @media only screen and (min-width: 992px) {
      #wrapper #content .flex-spot.arrow-links .component-content .element {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem; } }
    #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link {
      width: 100%;
      display: flex; }
      #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link[data-color="pine-green"] a:before {
        background: #407f7f; }
      @media only screen and (min-width: 992px) {
        #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link[data-color="pine-green"] a:hover {
          background: #407f7f;
          color: #ffffff; }
          #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link[data-color="pine-green"] a:hover span {
            background: #407f7f;
            color: #ffffff; } }
      #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link[data-color="electric-blue"] a:before {
        background: #3344f6; }
      @media only screen and (min-width: 992px) {
        #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link[data-color="electric-blue"] a:hover {
          background: #3344f6;
          color: #ffffff; }
          #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link[data-color="electric-blue"] a:hover span {
            background: #3344f6;
            color: #ffffff; } }
      #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link[data-color="walnut-brown"] a:before {
        background: #6c4b42; }
      @media only screen and (min-width: 992px) {
        #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link[data-color="walnut-brown"] a:hover {
          background: #6c4b42;
          color: #ffffff; }
          #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link[data-color="walnut-brown"] a:hover span {
            background: #6c4b42;
            color: #ffffff; } }
      #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link[data-color="charcoal-blue"] a:before {
        background: #24405b; }
      @media only screen and (min-width: 992px) {
        #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link[data-color="charcoal-blue"] a:hover {
          background: #24405b;
          color: #ffffff; }
          #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link[data-color="charcoal-blue"] a:hover span {
            background: #24405b;
            color: #ffffff; } }
      #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link[data-color="sky-blue"] a:before {
        background: #a4d6fc; }
      @media only screen and (min-width: 992px) {
        #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link[data-color="sky-blue"] a:hover {
          background: #a4d6fc;
          color: #ffffff; }
          #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link[data-color="sky-blue"] a:hover span {
            background: #a4d6fc;
            color: #ffffff; } }
      #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link[data-color="warm-gray"] a:before {
        background: #b9b9ad; }
      @media only screen and (min-width: 992px) {
        #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link[data-color="warm-gray"] a:hover {
          background: #b9b9ad;
          color: #ffffff; }
          #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link[data-color="warm-gray"] a:hover span {
            background: #b9b9ad;
            color: #ffffff; } }
      #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link[data-color="burst-yellow"] a:before {
        background: #edb833; }
      @media only screen and (min-width: 992px) {
        #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link[data-color="burst-yellow"] a:hover {
          background: #edb833;
          color: #ffffff; }
          #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link[data-color="burst-yellow"] a:hover span {
            background: #edb833;
            color: #ffffff; } }
      @media only screen and (min-width: 992px) {
        #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link {
          width: calc((100% - 4rem) / 3); } }
      #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link a {
        width: 100%;
        position: relative;
        display: block;
        text-decoration: none; }
        @media only screen and (min-width: 992px) {
          #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link a {
            min-height: 15rem;
            background: #ffffff; } }
        @media only screen and (min-width: 1400px) {
          #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link a {
            min-height: 19.5rem; } }
        #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link a:before {
          content: "";
          width: 2.5rem;
          height: 2.5rem;
          margin: 0;
          padding: 0;
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0; }
          @media only screen and (min-width: 992px) {
            #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link a:before {
              top: 1.5rem;
              left: 1.5rem; } }
          @media only screen and (min-width: 1400px) {
            #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link a:before {
              width: 5.5rem;
              height: 5.5rem; } }
        #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link a span {
          margin: 1.25rem;
          padding: 0.5rem;
          display: block;
          position: relative;
          background: #badcdf;
          color: #24405b;
          font-weight: bold;
          font-size: 20px;
          line-height: 1.33;
          letter-spacing: -0.6px;
          z-index: 2; }
          @media only screen and (min-width: 768px) {
            #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link a span {
              font-size: 30px; } }
          @media only screen and (min-width: 992px) {
            #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link a span {
              margin: 2.75rem;
              padding: 1rem;
              background: #ffffff; } }
          @media only screen and (min-width: 1400px) {
            #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link a span {
              margin: 4.25rem; } }
          #wrapper #content .flex-spot.arrow-links .component-content .element .element__item__link a span:after {
            content: "\00a0>"; }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

@keyframes in {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

@keyframes out {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
/********************* global slider animations ******************/
/********************* global slider animations ******************/
#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .nice-select.resource-center-filter .list {
  box-sizing: content-box; }

#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .filter-label {
  color: #74cecd;
  padding-right: 2rem; }

#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .filter-label,
#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .filter,
#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .clear-filters-label {
  vertical-align: middle;
  display: inline-block; }

#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .clear-filters-label {
  margin: 0 0.2rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.8s; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .clear-filters-label .clear-filters-button {
    padding-bottom: 0; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .clear-filters-label.show {
    opacity: 1;
    visibility: visible; }

#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .selected-filters {
  background-color: #24405b; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .selected-filters .inner-container {
    padding: 0.5rem 0; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .selected-filters .inner-container span {
      color: #ffffff;
      padding: 0.5rem;
      display: inline-block;
      background-color: #ffffff;
      margin: 0.4rem; }

#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .desktop-section {
  padding: 1rem 0;
  background-color: #24405b; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .desktop-section > .inner-container {
    margin-left: 30px;
    margin-right: 30px; }
    @media only screen and (min-width: 768px) {
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .desktop-section > .inner-container {
        margin-left: auto;
        margin-right: auto;
        width: 86%;
        max-width: 1200px; }
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .desktop-section > .inner-container.full-width {
          width: 100%;
          max-width: 1600px; }
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .desktop-section > .inner-container.regular {
          width: 86%;
          max-width: 1200px; }
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .desktop-section > .inner-container.slim {
          width: 47.2%;
          max-width: 660px; } }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .desktop-section .selected-filters {
    display: none;
    float: left;
    margin-top: 1.5rem; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .desktop-section .filters-selectors .inner-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .desktop-section .filters-selectors .inner-container .filter {
      margin: 0.5rem 1rem 0.5rem 0; }
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .desktop-section .filters-selectors .inner-container .filter:last-child {
        margin-right: 0; }
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .desktop-section .filters-selectors .inner-container .filter .select-label {
        margin: 0;
        font-weight: bold; }
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .desktop-section .filters-selectors .inner-container .filter .selected .current {
        font-weight: bold; }

#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .mobile-section {
  padding-top: 2rem;
  min-height: 3rem;
  background-color: #24405b; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .mobile-section .filter-label {
    font-size: 1.3rem;
    margin-left: 2rem; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .mobile-section .filter-label.collapsed-filters ~ .filters-selectors {
      max-height: 0; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .mobile-section .clear-filters-label {
    float: right;
    margin-right: 2.2rem;
    font-size: 1.3rem; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .mobile-section .clear-filters-label .clear-filters-button {
      padding: 0; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .mobile-section .filters-selectors {
    max-height: 500px;
    overflow: hidden;
    margin-top: 1.6rem;
    padding: 0 1.8rem;
    background-color: #24405b;
    transition: max-height 0.7s ease; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .mobile-section .filter {
    margin: 2rem 1% 0; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .mobile-section .filter .select-label {
      margin: 0;
      font-weight: bold;
      font-size: 1.1rem; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .mobile-section .show-filter-results {
    text-align: center;
    padding: 2rem 0; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .mobile-section .show-filter-results #show-filter-results-button {
      color: #74cecd;
      margin: 1rem 0;
      font-size: 1.5rem; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .mobile-section .selected-filters .inner-container {
    margin-left: 30px;
    margin-right: 30px; }
    @media only screen and (min-width: 768px) {
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .mobile-section .selected-filters .inner-container {
        margin-left: auto;
        margin-right: auto;
        width: 86%;
        max-width: 1200px; }
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .mobile-section .selected-filters .inner-container.full-width {
          width: 100%;
          max-width: 1600px; }
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .mobile-section .selected-filters .inner-container.regular {
          width: 86%;
          max-width: 1200px; }
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .mobile-section .selected-filters .inner-container.slim {
          width: 47.2%;
          max-width: 660px; } }

#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).list-layout .resources .content-type {
  display: inline-block;
  width: 50%;
  padding-bottom: 2rem; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).list-layout .resources .content-type .viewallitems-label,
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).list-layout .resources .content-type .rollbackup-label {
    margin-top: 1rem; }

#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).list-layout .resources .content-type-hide {
  display: none; }

#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).list-layout .resources .file-link {
  color: #000000;
  display: inline-block;
  box-sizing: border-box;
  width: 90%;
  padding: 0.5rem; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).list-layout .resources .file-link:nth-of-type(even) {
    background-color: #ffffff; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).list-layout .resources .file-link:nth-of-type(odd) {
    background-color: #f4f4f4; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).list-layout .resources .file-link span {
    margin-bottom: 0; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).list-layout .resources .file-link:hover {
    background-color: none; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).list-layout .resources .file-link.file-locked::after {
    font-family: "Material Icons";
    margin-left: 0.5rem;
    content: ""; }

#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).list-layout .resources .content-type-icon {
  display: inline-block;
  font-family: "Material Icons";
  margin-right: 0.5rem; }

#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).grid-layout {
  float: none;
  margin-left: 0; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).grid-layout .resources .content-type-files {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.66%; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).grid-layout .resources .content-type-hide {
    display: none; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).grid-layout .resources .file-link {
    display: block;
    width: 30%;
    margin: 0 1.66%;
    box-sizing: border-box;
    text-align: center; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).grid-layout .resources .file-link .image {
      display: inline-block;
      max-width: 100%; }
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).grid-layout .resources .file-link .image img {
        max-width: 100%;
        height: auto; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).grid-layout .resources .file-link p {
      max-width: 90%;
      display: inline-block; }

#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources {
  padding: 2rem 0;
  clear: both;
  /* "Generic" lightbox styling*/
  /* Desktop specific lightbox styling*/ }
  @media only screen and (min-width: 768px) {
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources > .inner-container {
      margin-left: 30px;
      margin-right: 30px; } }
  @media only screen and (min-width: 768px) and (min-width: 768px) {
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources > .inner-container {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources > .inner-container.full-width {
        width: 100%;
        max-width: 1600px; }
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources > .inner-container.regular {
        width: 86%;
        max-width: 1200px; }
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources > .inner-container.slim {
        width: 47.2%;
        max-width: 660px; } }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .content-type .content-type-count {
    padding-left: 1rem;
    color: #000000;
    line-height: initial; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .content-type .viewallitems-label,
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .content-type .rollbackup-label {
    user-select: none;
    font-size: 0.8rem;
    cursor: pointer;
    display: none; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .content-type .viewallitems-label.show,
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .content-type .rollbackup-label.show {
      display: inline-block; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .content-type .content-type-expander {
    display: none; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .file-link {
    color: #000000; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .file-link.file-link-hidden {
      display: none; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .detail-lightbox {
    display: none; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .detail-lightbox .inner-container {
      border: 1px solid none;
      padding: 2rem;
      margin-left: 30px;
      margin-right: 30px; }
      @media only screen and (min-width: 768px) {
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .detail-lightbox .inner-container {
          margin-left: auto;
          margin-right: auto;
          width: 86%;
          max-width: 1200px; }
          #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .detail-lightbox .inner-container.full-width {
            width: 100%;
            max-width: 1600px; }
          #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .detail-lightbox .inner-container.regular {
            width: 86%;
            max-width: 1200px; }
          #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .detail-lightbox .inner-container.slim {
            width: 47.2%;
            max-width: 660px; } }
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .detail-lightbox .inner-container .lighbox-close {
        display: inline-block;
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
        font-family: "Material Icons";
        user-select: none; }
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .detail-lightbox .inner-container .lighbox-close:hover {
          color: #000000; }
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .detail-lightbox .inner-container .lighbox-fileimg {
        display: none;
        margin-bottom: 2rem;
        user-select: none; }
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .detail-lightbox .inner-container .lighbox-download {
        user-select: none; }
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .detail-lightbox .inner-container .lighbox-filedescription {
        font-size: 12px;
        font-family: "Muli", sans-serif;
        font-weight: normal;
        line-height: 1.4; }
        @media only screen and (min-width: 768px) {
          #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .detail-lightbox .inner-container .lighbox-filedescription {
            font-size: 15px; } }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .detail-lightbox {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(36, 64, 91, 0.3);
    z-index: 9999; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .detail-lightbox .lighbox-fileimg {
      text-align: center; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .detail-lightbox .inner-container {
      background-color: #ffffff;
      max-height: 75%;
      top: 50%;
      transform: translatey(-50%);
      overflow-y: auto;
      width: 40rem; }

#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .cssload-container {
  width: 100%;
  height: 24px;
  text-align: center;
  opacity: 0; }

#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .cssload-inner-container {
  width: 24px;
  height: 24px;
  margin: 0 auto;
  border: 2px double;
  border-radius: 50%;
  border-color: rgba(0, 0, 0, 0.9) transparent;
  animation: cssload-spin 960ms infinite linear; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .cssload-inner-container::before {
    border-left: 5px solid rgba(0, 0, 0, 0.9);
    border-right: 5px solid transparent;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    width: 0;
    height: 0;
    position: relative;
    top: 13px;
    left: -6px;
    transform: rotate(225deg);
    content: "";
    display: block; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .cssload-inner-container::after {
    border-left: 5px solid rgba(0, 0, 0, 0.9);
    border-right: 5px solid transparent;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    width: 0;
    height: 0;
    position: relative;
    top: -5px;
    left: 20px;
    transform: rotate(45deg);
    content: "";
    display: block; }

@keyframes cssload-spin {
  100% {
    transform: rotate(360deg); } }

@media only screen and (min-width: 768px) {
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).grid-layout .resources .content-type-files {
    margin: 0 -1.5%; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).grid-layout .resources .content-type-files .file-link {
      width: 47%;
      margin: 0 1.5%; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).list-layout .resources .content-type {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).list-layout .resources .content-type {
    width: 100%;
    padding-bottom: 0.3rem; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources {
    padding-top: 0; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .content-type.expanded .content-type-files {
      display: block; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .content-type > h4 {
      position: relative;
      padding: 1rem 2rem;
      font-size: 1rem;
      background-color: #74cecd;
      margin: 0;
      color: #ffffff; }
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .content-type > h4 .content-type-count {
        color: #ffffff;
        font-weight: bold; }
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .content-type > h4 .content-type-count::before {
          content: "[ "; }
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .content-type > h4 .content-type-count::after {
          content: " ]"; }
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .content-type > h4 .content-type-expander {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: inline;
        margin: 0; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .content-type .content-type-files {
      display: none; }
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .content-type .content-type-files .file-link {
        width: 100%;
        padding: 0.6rem 2rem;
        font-size: 1rem; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .content-type .viewallitems-label, #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .content-type .viewallitems-label.show,
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .content-type .rollbackup-label,
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .content-type .rollbackup-label.show {
      display: none; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .content-type .detail-lightbox {
      position: relative;
      padding: 1rem 0;
      background-color: #ffffff; }
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .content-type .detail-lightbox .inner-container {
        background-color: #ffffff;
        width: 100%;
        max-height: none;
        transform: none;
        margin: 0 2rem;
        box-sizing: border-box; }
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .content-type .detail-lightbox .inner-container .lighbox-download {
          display: block;
          width: 16rem;
          margin: 1.5rem auto 0;
          padding: 1rem; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .resources .empty-error {
      margin: 2rem; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).grid-layout .resources .content-type > h4 {
    display: none; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).grid-layout .resources .content-type .content-type-files {
    display: block;
    margin: 0; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).grid-layout .resources .content-type .content-type-files .file-link {
      width: 100%;
      margin: 0; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).grid-layout .resources .content-type .viewallitems-label,
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).grid-layout .resources .content-type .rollbackup-label {
    margin-left: 2em; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).grid-layout .resources .content-type .viewallitems-label.show,
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).grid-layout .resources .content-type .rollbackup-label.show {
      display: inline-block; } }

@media only screen and (max-width: 568px) {
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .filters .mobile-section .filter {
    width: 100%;
    margin: 1.5rem 0 0; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).grid-layout .resources .content-type .content-type-files {
    margin: 0; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new).grid-layout .resources .content-type .content-type-files .file-link {
      width: 100%;
      margin: 0; } }

#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .desktop-section .filter-label {
  font-size: 1.2rem; }

#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .desktop-section .clear-filters-label {
  margin-top: 0.3rem; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .desktop-section .clear-filters-label .clear-filters-button {
    animation: out 0.7s -0.1s ease-in-out forwards;
    background: radial-gradient(50% 300% at right, #3344f6 50%, transparent 50%), radial-gradient(circle at right, #ffffff 100%, transparent 100%);
    background-size: 420% 100%;
    background-position: right bottom;
    background-color: #ffffff;
    color: #3344f6;
    transition: all 0.4s ease-in-out;
    font-size: 12px;
    color: #ffffff;
    background-color: #3344f6;
    border: 0;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    border-radius: 16.5px;
    padding: 7px 21px 8px;
    color: #3344f6;
    background-color: #ffffff; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .desktop-section .clear-filters-label .clear-filters-button:hover {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #3344f6 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(51, 68, 246, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #ffffff 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #ffffff;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #ffffff;
      transition: all 0.8s ease-in-out; }
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .desktop-section .clear-filters-label .clear-filters-button:hover a {
        color: #ffffff;
        transition: all 0.8s ease-in-out; }
    @media only screen and (min-width: 768px) {
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .desktop-section .clear-filters-label .clear-filters-button {
        font-size: 15px; } }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .desktop-section .clear-filters-label .clear-filters-button a {
      color: #ffffff;
      text-decoration: none; }
    @media only screen and (min-width: 768px) {
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .desktop-section .clear-filters-label .clear-filters-button {
        border-radius: 18.5px;
        padding: 9px 25px 10px; } }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .desktop-section .clear-filters-label .clear-filters-button a {
      color: #3344f6; }

#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .desktop-section .filters-selectors .inner-container .filter {
  margin: 1rem 1.5rem 0.5rem 0; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .desktop-section .filters-selectors .inner-container .filter .inner-container {
    flex-direction: column; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .desktop-section .filters-selectors .inner-container .filter .inner-container .select-label {
      margin-bottom: 0.5rem;
      color: #ffffff;
      font-weight: normal;
      font-size: 1rem; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .desktop-section .filters-selectors .inner-container .filter .inner-container .resource-center-filter {
      padding: 0.5rem;
      width: 100%;
      height: 3rem;
      min-width: 15rem;
      border: 0; }

#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section {
  padding: 0; }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container {
    margin-bottom: 0.3rem;
    padding: 1rem 0; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .filter-label {
      margin-left: 30px;
      line-height: 44px; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .clear-filters-label {
      margin-right: 30px; }
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .clear-filters-label .clear-filters-button {
        animation: out 0.7s -0.1s ease-in-out forwards;
        background: radial-gradient(50% 300% at right, #3344f6 50%, transparent 50%), radial-gradient(circle at right, #ffffff 100%, transparent 100%);
        background-size: 420% 100%;
        background-position: right bottom;
        background-color: #ffffff;
        color: #3344f6;
        transition: all 0.4s ease-in-out;
        font-size: 12px;
        color: #ffffff;
        background-color: #3344f6;
        border: 0;
        cursor: pointer;
        display: inline-block;
        text-decoration: none;
        font-weight: bold;
        border-radius: 25px;
        padding: 11px 26px 12px;
        color: #3344f6;
        background-color: #ffffff; }
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .clear-filters-label .clear-filters-button:hover {
          animation: in 0.7s -0.1s ease-in-out forwards;
          background: radial-gradient(50% 300% at left, #3344f6 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(51, 68, 246, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #ffffff 100%, transparent 100%);
          background-size: 420% 100%;
          background-position: right bottom;
          background-color: #ffffff;
          box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
          color: #ffffff;
          transition: all 0.8s ease-in-out; }
          #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .clear-filters-label .clear-filters-button:hover a {
            color: #ffffff;
            transition: all 0.8s ease-in-out; }
        @media only screen and (min-width: 768px) {
          #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .clear-filters-label .clear-filters-button {
            font-size: 15px; } }
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .clear-filters-label .clear-filters-button a {
          color: #ffffff;
          text-decoration: none; }
        @media only screen and (min-width: 768px) {
          #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .clear-filters-label .clear-filters-button {
            border-radius: 23px;
            padding: 12.5px 35px 14.5px; } }
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .clear-filters-label .clear-filters-button a {
          color: #3344f6; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .filters-selectors {
      margin-top: 0;
      padding: 0 30px; }
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .filters-selectors .inner-container .filter {
        width: 100%;
        margin: 1rem 0 0 0; }
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .filters-selectors .inner-container .filter > .inner-container {
          width: 100%;
          display: flex;
          flex-direction: column; }
          #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .filters-selectors .inner-container .filter > .inner-container .select-label {
            margin-bottom: 0.5rem;
            color: #ffffff;
            font-weight: normal; }
          #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .filters-selectors .inner-container .filter > .inner-container .resource-center-filter {
            padding: 0.5rem;
            height: 2.5rem;
            border: 0; }
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .filters-selectors .inner-container .show-filter-results {
        padding: 1.5rem 0 1rem; }
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .filters-selectors .inner-container .show-filter-results #show-filter-results-button {
          animation: out 0.7s -0.1s ease-in-out forwards;
          background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
          background-size: 420% 100%;
          background-position: right bottom;
          background-color: #3344f6;
          color: #ffffff;
          transition: all 0.4s ease-in-out;
          font-size: 15px;
          color: #ffffff;
          background-color: #3344f6;
          border: 0;
          cursor: pointer;
          display: inline-block;
          text-decoration: none;
          font-weight: bold;
          border-radius: 25px;
          padding: 11px 26px 12px;
          margin: 0; }
          #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .filters-selectors .inner-container .show-filter-results #show-filter-results-button:hover {
            animation: in 0.7s -0.1s ease-in-out forwards;
            background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
            background-size: 420% 100%;
            background-position: right bottom;
            background-color: #3344f6;
            box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
            color: #3344f6;
            transition: all 0.8s ease-in-out; }
            #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .filters-selectors .inner-container .show-filter-results #show-filter-results-button:hover a {
              color: #3344f6;
              transition: all 0.8s ease-in-out; }
          @media only screen and (min-width: 768px) {
            #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .filters-selectors .inner-container .show-filter-results #show-filter-results-button {
              font-size: 17px; } }
          #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .filters-selectors .inner-container .show-filter-results #show-filter-results-button a {
            color: #ffffff;
            text-decoration: none; }
          @media only screen and (min-width: 768px) {
            #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .filters-selectors .inner-container .show-filter-results #show-filter-results-button {
              border-radius: 23px;
              padding: 12.5px 35px 14.5px; } }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .selected-filters .inner-container {
      padding: 0; }
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .selected-filters .inner-container span {
        font-size: 12px;
        color: #ffffff;
        background-color: #3344f6;
        border: 0;
        cursor: pointer;
        display: inline-block;
        text-decoration: none;
        font-weight: bold;
        color: #3344f6;
        background-color: #ffffff;
        border-radius: 19.5px;
        padding: 11px 26px 12px;
        margin: 0.7rem 0.3rem 0 0; }
        @media only screen and (min-width: 768px) {
          #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .selected-filters .inner-container span {
            font-size: 15px; } }
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .selected-filters .inner-container span a {
          color: #ffffff;
          text-decoration: none; }
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .selected-filters .inner-container span a {
          color: #3344f6; }
        @media only screen and (min-width: 768px) {
          #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .filters .mobile-section > .inner-container .selected-filters .inner-container span {
            border-radius: 25px;
            padding: 14px 35px 15px; } }

#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .content-type > h4 {
  padding: 1rem 30px; }
  @media only screen and (min-width: 768px) {
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .content-type > h4 {
      padding-bottom: 0.5rem;
      border-bottom: 0.16em solid #74cecd; } }

#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .content-type .content-type-files .file-link {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.6rem 30px;
  text-decoration: none;
  font-weight: normal; }
  @media only screen and (min-width: 768px) {
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .content-type .content-type-files .file-link {
      padding: 0.6rem 2rem; } }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .content-type .content-type-files .file-link .content-type-icon {
    display: none; }
  @media only screen and (min-width: 768px) {
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .content-type .content-type-files .file-link::before {
      font-family: "Material Icons";
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      font-feature-settings: "liga";
      -webkit-font-feature-settings: "liga";
      -webkit-font-smoothing: antialiased;
      content: "description";
      margin-right: 0.5rem;
      color: #74cecd; } }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .content-type .content-type-files .file-link.file-link-hidden {
    display: none; }

#wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox {
  padding: 0;
  background: #dcdcd6; }
  @media only screen and (min-width: 768px) {
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox {
      background: rgba(0, 0, 0, 0.85); } }
  #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox > .inner-container {
    margin: 0 auto;
    border: 0;
    text-align: center;
    background: none; }
    @media only screen and (min-width: 768px) {
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox > .inner-container {
        position: relative;
        color: #ffffff; } }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox > .inner-container .lighbox-close {
      display: none; }
      @media only screen and (min-width: 768px) {
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox > .inner-container .lighbox-close {
          display: block;
          font-size: 0; }
          #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox > .inner-container .lighbox-close:after {
            font-family: "Material Icons";
            font-weight: normal;
            font-style: normal;
            font-size: 24px;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            font-feature-settings: "liga";
            -webkit-font-feature-settings: "liga";
            -webkit-font-smoothing: antialiased;
            content: "close";
            position: absolute;
            top: 0;
            right: 0; }
          #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox > .inner-container .lighbox-close:hover {
            color: #ffffff; } }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox > .inner-container .lighbox-fileimg {
      margin-bottom: 1rem; }
      @media only screen and (min-width: 768px) {
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox > .inner-container .lighbox-fileimg {
          margin-bottom: 2rem; } }
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox > .inner-container .lighbox-fileimg img {
        max-width: 100%;
        height: auto; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox > .inner-container .lighbox-filename {
      font-weight: normal; }
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox > .inner-container .lighbox-download {
      animation: out 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      color: #ffffff;
      transition: all 0.4s ease-in-out;
      font-size: 12px;
      color: #ffffff;
      background-color: #3344f6;
      border: 0;
      cursor: pointer;
      display: inline-block;
      text-decoration: none;
      font-weight: bold;
      border-radius: 19.5px;
      padding: 11px 26px 12px;
      margin-top: 1.3rem; }
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox > .inner-container .lighbox-download:hover {
        animation: in 0.7s -0.1s ease-in-out forwards;
        background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
        background-size: 420% 100%;
        background-position: right bottom;
        background-color: #3344f6;
        box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
        color: #3344f6;
        transition: all 0.8s ease-in-out; }
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox > .inner-container .lighbox-download:hover a {
          color: #3344f6;
          transition: all 0.8s ease-in-out; }
      @media only screen and (min-width: 768px) {
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox > .inner-container .lighbox-download {
          font-size: 15px; } }
      #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox > .inner-container .lighbox-download a {
        color: #ffffff;
        text-decoration: none; }
      @media only screen and (min-width: 768px) {
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox > .inner-container .lighbox-download {
          border-radius: 25px;
          padding: 14px 35px 15px; } }
      @media only screen and (min-width: 768px) {
        #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox > .inner-container .lighbox-download {
          animation: out 0.7s -0.1s ease-in-out forwards;
          background: radial-gradient(50% 300% at right, #3344f6 50%, transparent 50%), radial-gradient(circle at right, #ffffff 100%, transparent 100%);
          background-size: 420% 100%;
          background-position: right bottom;
          background-color: #ffffff;
          color: #3344f6;
          transition: all 0.4s ease-in-out;
          font-size: 12px;
          color: #ffffff;
          background-color: #3344f6;
          border: 0;
          cursor: pointer;
          display: inline-block;
          text-decoration: none;
          font-weight: bold;
          border-radius: 16.5px;
          padding: 7px 21px 8px;
          color: #3344f6;
          background-color: #ffffff; }
          #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox > .inner-container .lighbox-download:hover {
            animation: in 0.7s -0.1s ease-in-out forwards;
            background: radial-gradient(50% 300% at left, #3344f6 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(51, 68, 246, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #ffffff 100%, transparent 100%);
            background-size: 420% 100%;
            background-position: right bottom;
            background-color: #ffffff;
            box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
            color: #ffffff;
            transition: all 0.8s ease-in-out; }
            #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox > .inner-container .lighbox-download:hover a {
              color: #ffffff;
              transition: all 0.8s ease-in-out; } }
  @media only screen and (min-width: 768px) and (min-width: 768px) {
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox > .inner-container .lighbox-download {
      font-size: 15px; } }
      @media only screen and (min-width: 768px) {
          #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox > .inner-container .lighbox-download a {
            color: #ffffff;
            text-decoration: none; } }
  @media only screen and (min-width: 768px) and (min-width: 768px) {
    #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox > .inner-container .lighbox-download {
      border-radius: 18.5px;
      padding: 9px 25px 10px; } }
      @media only screen and (min-width: 768px) {
          #wrapper .component.data-hierarchy-spot.resource-center:not(.resource-center-new) .component-content .resources .detail-lightbox > .inner-container .lighbox-download a {
            color: #3344f6; } }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
::selection {
  background: #edb833; }

@keyframes in {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

@keyframes out {
  from {
    background-position: right bottom; }
  to {
    background-position: left bottom; } }

::selection {
  background: #edb833; }

/* intro banner and rich text - not verified */
/* intro banner and rich text - end */
/********************* global slider animations ******************/
.component.resource-center-new .dictionary {
  display: none; }

.component.resource-center-new .component-content .rc2-icon {
  font-family: "DGS-WebProgram"; }

.component.resource-center-new .spinner {
  margin: 2rem auto;
  width: 15rem;
  text-align: center; }
  .component.resource-center-new .spinner > div {
    width: 1rem;
    height: 1rem;
    background-color: #333;
    margin: 0 0.3rem;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both; }
  .component.resource-center-new .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s; }
  .component.resource-center-new .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s; }

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.component.resource-center-new .empty-error {
  margin-left: 30px;
  margin-right: 30px;
  padding: 2rem;
  text-align: center;
  font-weight: 600;
  background: #edb833; }
  @media only screen and (min-width: 768px) {
    .component.resource-center-new .empty-error {
      margin-left: auto;
      margin-right: auto;
      width: 47.2%;
      max-width: 660px; }
      .component.resource-center-new .empty-error.full-width {
        width: 100%;
        max-width: 1600px; }
      .component.resource-center-new .empty-error.regular {
        width: 86%;
        max-width: 1200px; }
      .component.resource-center-new .empty-error.slim {
        width: 47.2%;
        max-width: 660px; } }

.component.resource-center-new .filters {
  margin-bottom: 3rem;
  background-color: #badcdf; }
  .component.resource-center-new .filters__container {
    margin-left: 30px;
    margin-right: 30px;
    padding: 2rem 0 1rem; }
    @media only screen and (min-width: 768px) {
      .component.resource-center-new .filters__container {
        margin-left: auto;
        margin-right: auto;
        width: 86%;
        max-width: 1200px; }
        .component.resource-center-new .filters__container.full-width {
          width: 100%;
          max-width: 1600px; }
        .component.resource-center-new .filters__container.regular {
          width: 86%;
          max-width: 1200px; }
        .component.resource-center-new .filters__container.slim {
          width: 47.2%;
          max-width: 660px; } }
    @media (min-width: 768px) {
      .component.resource-center-new .filters__container {
        padding: 2rem 0;
        max-width: 1000px; } }
  .component.resource-center-new .filters__toggle-button {
    animation: out 0.7s -0.1s ease-in-out forwards;
    background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
    background-size: 420% 100%;
    background-position: right bottom;
    background-color: #3344f6;
    color: #ffffff;
    transition: all 0.4s ease-in-out;
    font-size: 15px;
    color: #ffffff;
    background-color: #3344f6;
    border: 0;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    border-radius: 25px;
    padding: 11px 26px 12px;
    margin-bottom: 1rem; }
    .component.resource-center-new .filters__toggle-button:hover {
      animation: in 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
      color: #3344f6;
      transition: all 0.8s ease-in-out; }
      .component.resource-center-new .filters__toggle-button:hover a {
        color: #3344f6;
        transition: all 0.8s ease-in-out; }
    @media only screen and (min-width: 768px) {
      .component.resource-center-new .filters__toggle-button {
        font-size: 17px; } }
    .component.resource-center-new .filters__toggle-button a {
      color: #ffffff;
      text-decoration: none; }
    @media only screen and (min-width: 768px) {
      .component.resource-center-new .filters__toggle-button {
        border-radius: 23px;
        padding: 12.5px 35px 14.5px; } }

.component.resource-center-new .not-selected .filters {
  margin-bottom: 0; }

.component.resource-center-new .filters__group {
  padding: 1.5rem 1rem 0 1rem;
  overflow: auto;
  background-color: #badcdf; }
  @media (min-width: 768px) {
    .component.resource-center-new .filters__group {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 1rem 3rem; } }

.component.resource-center-new .filters__group__item__wrapper {
  padding: 0 0 1.5rem calc(26px + 1rem);
  display: block;
  position: relative;
  line-height: 26px;
  font-weight: 600;
  cursor: pointer;
  user-select: none; }
  @media (min-width: 768px) {
    .component.resource-center-new .filters__group__item__wrapper {
      padding: 0 0 1rem calc(26px + 1rem); } }
  .component.resource-center-new .filters__group__item__wrapper:hover input ~ .filters__group__item__checkmark {
    background-color: #fcf7ea; }
  .component.resource-center-new .filters__group__item__wrapper input:checked ~ .filters__group__item__checkmark {
    background-color: #edb833; }
  .component.resource-center-new .filters__group__item__wrapper input:checked ~ .filters__group__item__checkmark:after {
    display: block; }
  .component.resource-center-new .filters__group__item__wrapper input {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
    cursor: pointer; }

.component.resource-center-new .filters__group__item__checkmark {
  width: 22px;
  height: 22px;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
  border: 2px solid #24405b; }
  .component.resource-center-new .filters__group__item__checkmark:after {
    content: "";
    display: none;
    width: 5px;
    height: 10px;
    position: absolute;
    top: 3px;
    left: 7px;
    border: 2px solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg); }

.filters__overlay {
  padding: 2.5rem 30px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  background: #ffffff;
  overflow: auto; }
  .filters__overlay__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 1.33;
    letter-spacing: -0.6px;
    margin-bottom: 1rem; }
    @media only screen and (min-width: 768px) {
      .filters__overlay__title {
        font-size: 30px; } }
  .filters__overlay__close-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    font-size: 0; }
    .filters__overlay__close-button:after {
      font-family: "Material Icons";
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      font-feature-settings: "liga";
      -webkit-font-feature-settings: "liga";
      -webkit-font-smoothing: antialiased;
      content: "close";
      padding: 1rem;
      font-size: 2rem; }
  .filters__overlay__footer {
    padding: 2rem 0 0 0;
    display: flex;
    justify-content: center; }
    .filters__overlay__footer button {
      animation: out 0.7s -0.1s ease-in-out forwards;
      background: radial-gradient(50% 300% at right, #ffffff 50%, transparent 50%), radial-gradient(circle at right, #3344f6 100%, transparent 100%);
      background-size: 420% 100%;
      background-position: right bottom;
      background-color: #3344f6;
      color: #ffffff;
      transition: all 0.4s ease-in-out;
      font-size: 12px;
      color: #ffffff;
      background-color: #3344f6;
      border: 0;
      cursor: pointer;
      display: inline-block;
      text-decoration: none;
      font-weight: bold;
      border-radius: 19.5px;
      padding: 11px 26px 12px; }
      .filters__overlay__footer button:hover {
        animation: in 0.7s -0.1s ease-in-out forwards;
        background: radial-gradient(50% 300% at left, #ffffff 50%, transparent 50%), radial-gradient(50% 300% at left, rgba(255, 255, 255, 0.25) 80%, transparent 80%), radial-gradient(50% 300% at left, #3344f6 100%, transparent 100%);
        background-size: 420% 100%;
        background-position: right bottom;
        background-color: #3344f6;
        box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.25);
        color: #3344f6;
        transition: all 0.8s ease-in-out; }
        .filters__overlay__footer button:hover a {
          color: #3344f6;
          transition: all 0.8s ease-in-out; }
      @media only screen and (min-width: 768px) {
        .filters__overlay__footer button {
          font-size: 15px; } }
      .filters__overlay__footer button a {
        color: #ffffff;
        text-decoration: none; }
      @media only screen and (min-width: 768px) {
        .filters__overlay__footer button {
          border-radius: 25px;
          padding: 14px 35px 15px; } }

.filters__selected-filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .filters__selected-filters__item {
    display: flex;
    align-items: center;
    margin: 0 0.5rem 1rem 0;
    padding: 0.3rem 1.2rem 0.3rem 0.3rem;
    color: #24405b;
    font-size: 0.8rem;
    background-color: #edb833;
    border-radius: 999px; }
    .filters__selected-filters__item__close-button {
      position: relative;
      display: inline-block;
      cursor: pointer;
      width: 20px;
      height: 20px;
      margin-right: 0.5rem;
      overflow: hidden;
      background-color: #ffffff;
      border-radius: 999px; }
      .filters__selected-filters__item__close-button::before, .filters__selected-filters__item__close-button::after {
        content: "";
        position: absolute;
        height: 2px;
        width: 50%;
        top: 50%;
        left: 25%;
        margin-top: -1px;
        background-color: #24405b; }
      .filters__selected-filters__item__close-button::before {
        transform: rotate(45deg); }
      .filters__selected-filters__item__close-button::after {
        transform: rotate(-45deg); }
  .filters__selected-filters__clear-all-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin: 0 0.5rem 1rem 0;
    padding: 0.3rem;
    color: #24405b;
    font-weight: 900;
    font-size: 0.8rem; }

.component.resource-center-new .resources .content-type-container > .inner-container {
  margin-left: 30px;
  margin-right: 30px; }
  @media only screen and (min-width: 768px) {
    .component.resource-center-new .resources .content-type-container > .inner-container {
      margin-left: auto;
      margin-right: auto;
      width: 86%;
      max-width: 1200px; }
      .component.resource-center-new .resources .content-type-container > .inner-container.full-width {
        width: 100%;
        max-width: 1600px; }
      .component.resource-center-new .resources .content-type-container > .inner-container.regular {
        width: 86%;
        max-width: 1200px; }
      .component.resource-center-new .resources .content-type-container > .inner-container.slim {
        width: 47.2%;
        max-width: 660px; } }
  @media (min-width: 768px) {
    .component.resource-center-new .resources .content-type-container > .inner-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; } }

.component.resource-center-new .resources .content-type {
  display: inline-block;
  width: 100%;
  padding: 0 0 2rem 0; }
  @media (min-width: 768px) {
    .component.resource-center-new .resources .content-type {
      width: 45%;
      padding: 0 0 2rem 0; } }
  .component.resource-center-new .resources .content-type__header {
    position: relative;
    margin: 0 0 0.5rem 0;
    pointer-events: none;
    cursor: auto;
    display: flex;
    align-items: center; }
    .component.resource-center-new .resources .content-type__header .content-type-count {
      width: 2.25rem;
      height: 2.25rem;
      margin-left: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: #ffffff;
      background-color: #3344f6;
      font-size: 11px; }
      @media only screen and (min-width: 768px) {
        .component.resource-center-new .resources .content-type__header .content-type-count {
          font-size: 13px; } }
  .component.resource-center-new .resources .content-type .expand-button {
    margin-top: 1rem;
    padding: 0;
    display: flex;
    align-items: center;
    color: #3344f6;
    background: none;
    border: 0;
    font-family: "Muli", sans-serif;
    font-weight: 800;
    font-size: 12px;
    cursor: pointer; }
    @media only screen and (min-width: 768px) {
      .component.resource-center-new .resources .content-type .expand-button {
        font-size: 15px; } }
    .component.resource-center-new .resources .content-type .expand-button:before {
      margin-right: 0.25rem;
      display: block;
      content: "arrow_forward_ios";
      font-family: "Material Icons";
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      font-feature-settings: "liga";
      -webkit-font-feature-settings: "liga";
      -webkit-font-smoothing: antialiased;
      font-size: 12px; }
      @media only screen and (min-width: 768px) {
        .component.resource-center-new .resources .content-type .expand-button:before {
          font-size: 15px; } }

.component.resource-center-new .resources .content-type-hide {
  display: none; }

@media (min-width: 768px) {
  .component.resource-center-new.image-grid-layout .resources .content-type-files {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem; } }

@media (min-width: 992px) {
  .component.resource-center-new.image-grid-layout .resources .content-type-files {
    grid-template-columns: 1fr 1fr 1fr; } }

.component.resource-center-new.list-layout .file-link {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  color: #24405b;
  text-decoration: none;
  font-weight: 600; }
  .component.resource-center-new.list-layout .file-link:nth-of-type(even) {
    background-color: #ffffff; }
  .component.resource-center-new.list-layout .file-link:nth-of-type(odd) {
    background-color: #f4f4f4; }
  .component.resource-center-new.list-layout .file-link span {
    margin-bottom: 0; }
  @media only screen and (min-width: 768px) {
    .component.resource-center-new.list-layout .file-link::before {
      font-family: "Material Icons";
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
      font-feature-settings: "liga";
      -webkit-font-feature-settings: "liga";
      -webkit-font-smoothing: antialiased;
      content: "description";
      margin-right: 0.5rem;
      color: #74cecd; } }
  .component.resource-center-new.list-layout .file-link:not(.file-selected):hover {
    background-color: #24405b;
    color: #ffffff; }
  .component.resource-center-new.list-layout .file-link.file-selected {
    background-color: #edb833;
    color: #ffffff; }
  .component.resource-center-new.list-layout .file-link.file-locked::after {
    font-family: "DGS-WebProgram";
    margin-left: 0.5rem;
    content: ""; }

.component.resource-center-new.image-grid-layout .file-link {
  margin-top: 1.5rem;
  display: block;
  flex-grow: 0;
  box-sizing: border-box;
  text-align: center;
  color: #24405b;
  cursor: pointer; }
  .component.resource-center-new.image-grid-layout .file-link .image {
    display: inline-block;
    width: 100%;
    padding-top: 56.25%;
    background-size: cover;
    background-repeat: none;
    background-position: center; }
  .component.resource-center-new.image-grid-layout .file-link img {
    width: 100%;
    height: auto; }
  .component.resource-center-new.image-grid-layout .file-link .title {
    max-width: 80%;
    margin-top: 1rem;
    display: inline-block; }

/*# sourceMappingURL=main.[hash].css.map */